<template>
    <v-alert
      v-if="getUploadCounter > 0"
      transition="scroll-x-reverse-transition"
      class="floating_top pulse"
      type="info"
      icon="mdi-moon-full"
      color="#292d39"
    >
      Sauvegarde en cours
    </v-alert>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Uploader',

  data: function() {
    return {
        
    }
  },
  
  computed: {
    ...mapGetters({
      getUploadCounter: 'upload/getUploadCounter'
    })
  },

  methods: {
    
  }
}
</script>
<style>
  .floating_top {
    z-index: 999;
    position: absolute !important;
    top: 10px;
    right: 10px;
  }

  .pulse .v-icon {
    color: rgba(255, 82, 82, 1);
    background: rgba(255, 82, 82, 1);
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    height: 30px;
    width: 30px;
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
</style>