const env = {};

env.config = {
  api_url: "https://preprod.jpm.desk.utopik.online/webservice.asmx/",
  //api_url: "https://test.jpm.desk.utopik.online/webservice.asmx/",
  cdn_url: "https://xxx"
};

env.install = function (Vue) {
  Vue.prototype.$env = env.config;
}

module.exports = env;