<template>
  <div style="display: inline-flex; width: inherit;">
    <v-card-subtitle :class="padding">{{ title == " " ? '&nbsp;' : title }}</v-card-subtitle>
    <v-card-subtitle :class="padding + ' ' + dataStyle" style="flex: 1;" :style="(title == '' ? '' : 'text-align: end;')"><b>{{ data ? data : "-" }}</b></v-card-subtitle>
  </div>
</template>

<script>
export default {
  name: 'Field',

  props: {
    title: {
      type: String,
      default: ""
    },
    data: {
      default: "-"
    },
    padding: {
      type: String,
      default: "pa-0"
    },
    dataStyle: {
      type: String,
      default: ""
    }
  }
}
</script>