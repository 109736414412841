<template>
  <div class="h-100">
    <v-row class="h-100 ma-0">
      <v-col class="h-100" cols="3" xl="3" lg="3" md="3" sm="2" xs="2" style="  overflow:auto">
        <v-card class="d-flex flex-column h-100">
          <v-card-title class="ml-4 text-subtitle-2" style="align-items: end;">
            <v-icon class="mr-4">mdi-file-document-edit-outline</v-icon><span>Dossier de montage</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-iterator
            style="flex:20; max-height: 73vh; overflow: auto;"
            :items="getRecords"
            item-key="kIDFolder"
            hide-default-footer
          >
            <template v-slot:default="{items}">
              <v-list subheader>
                <v-list-item-group mandatory v-model="selectedFolder">
                  <v-list-item
                    v-for="item in items"
                    :key="item.kIDFolder"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="d-flex text-subtitle-2">{{ item.sProjectOrderLine }} <v-spacer>&nbsp;</v-spacer> {{ item.sBrandTitle }} {{ item.sModelTitle }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.sContractorTitle }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ item.sChassisSerialNumber }}</v-list-item-subtitle>
                      <v-list-item-subtitle><b>Montage pour le {{new Date(item.dProjectExpectedAssemblyDate).toLocaleDateString()}}</b></v-list-item-subtitle>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
      <v-col v-if="selectedFolder != null"  class="h-100 d-flex flex-column" cols="9" xl="9" lg="9" md="9" sm="10" xs="10">
        <CarrossagePopUp :dialog="carrossagepopup" :folder="selectedFolderData" @close="closeCarrossagePopup"/>
        <PhotoPopUp 
          :dialog="photopopup"
          :folder="selectedFolderData"
          :lastUpdate="photoLastUpdate"
          :headerTitle="selectedFolderData ? (selectedFolderData.sContractorTitle + ' - ' + selectedFolderData.sProjectOrderLine) : ''" @close="closePhotoPopup"/>
        <EventPopUpCmp :selectedFolderData="selectedFolderData" :dialog="eventPopup" @close="closeEventPopup" />
        <div style="min-height: 180px;">
          <v-row class="py-2">
            <v-col cols="4" xl="4" lg="4" md="4" sm="4" xs="4">
              <Field title="Châssis" :data="selectedFolderData ? getChassisTitle(selectedFolderData.kIDChassis) : '-'" />
              <Field title="Commande" :data="`${selectedFolderData ? selectedFolderData.sProjectOrderLine : '-'}`" />
              <Field title="Référence interne centre" :data="selectedFolderData ? selectedFolderData.sFolderCustomerReference : '-'" />
            <Field title="Montage pour le" :data="new Date(selectedFolderData.dProjectExpectedAssemblyDate).toLocaleDateString()" dataStyle="text-h6" />
            </v-col>
            <v-col cols="4" xl="4" lg="4" md="4" sm="4" xs="4">
              <Field title="Empattement" :data="selectedFolderData ? getChassisData(selectedFolderData.kIDChassis, 'sChassisWheelBase') : '-'" />
              <Field title="PTAC" :data="selectedFolderData ? selectedFolderData.sPTAC : '-'" />
              <Field title="Type de cabine" :data="selectedFolderData ? getChassisData(selectedFolderData.kIDChassis, 'sChassisCabin') : '-'" />
              <Field title="Couleur" :data="selectedFolderData ? getChassisData(selectedFolderData.kIDChassis, 'sChassisColor') : '-'" />
            </v-col>
            <v-col cols="4" xl="4" lg="4" md="4" sm="4" xs="4">
              <Field title="N° Série Châssis" :data="selectedFolderData ? getChassisData(selectedFolderData.kIDChassis, 'sChassisNumber') : '-'" />
              <Field title="N° Série Benne" :data="selectedFolderData ? getTipperData(selectedFolderData.kIDTipper, 'sSupplySerialNumber') : '-'" />
              <Field title="N° Série Coffre" :data="selectedFolderData ? getLockerData(selectedFolderData.kIDLocker, 'sSupplySerialNumber') : '-'" />
            </v-col>
          </v-row>
          <v-row class="mt-0 mb-0">
            <v-col cols="4">
              <v-row>
                <v-col cols="12" class="center-x">
                  <v-chip
                    color="indigo"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                    {{ selectedFolderData ? selectedFolderData.sContractorTitle : '-' }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col cols="12" class="center-x">
                  <v-chip
                    color="green"
                    text-color="white"
                  >
                    <v-avatar
                      left
                      class="green darken-4"
                    >
                      {{ selectedFolderData ? getComplementsLength(selectedFolderData) : '-' }}
                    </v-avatar>
                    Compléments
                  </v-chip>
                  <v-chip
                    @click="detailspopup = true"
                    class="ml-2"
                    color="green"
                    text-color="white"
                  >
                    <v-avatar left>
                    <v-icon>mdi-information</v-icon>
                    </v-avatar>
                    Détails
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="6">
              <v-chip
                color="teal"
                text-color="white"
              >
                <v-avatar left>
                  <v-icon>mdi-chat-alert</v-icon>
                </v-avatar>
                Commentaire du dossier
              </v-chip>
              <v-card-subtitle class="pa-0 pt-2"><b>{{ selectedFolderData ? selectedFolderData.sFolderComment : '-' }}</b></v-card-subtitle>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
          <v-row class="pt-4 pb-2" style="display: grid; overflow: hidden; grid-template-columns: repeat(3, 1fr); grid-auto-rows: 1fr; grid-column-gap: 5px;">
            <v-col class="px-4 py-2 pt-2">
              <v-btn class="cmp_btn elevation-10" style="width: 100%; height: 100%;" dark color="#627078" @click="openCarrossagePopup">
                <span class="pb-2" style="font-size: large">Formulaire<br/>d'autocontrôle</span>
                <v-progress-circular
                  :rotate="-90"
                  :size="80"
                  :width="10"
                  :value="selectedFolderData ? selectedFolderData.iGlobalFormContentStep == 0 ? 100 : ((selectedFolderData.iGlobalFormContentStep)*100)/selectedFolderData.iGlobalFormSteps : 0"
                  :color="getColor((selectedFolderData ? selectedFolderData.iGlobalFormContentStep : 0 ), (selectedFolderData ? selectedFolderData.iGlobalFormSteps : 0))"
                >
                  <span style="font-size: smaller; color: white">{{ selectedFolderData ? selectedFolderData.iGlobalFormContentStep : '?' }} / {{ selectedFolderData ? selectedFolderData.iGlobalFormSteps : '?' }}</span>
                </v-progress-circular>
              </v-btn>
            </v-col>
            <v-col class="px-4 py-2 pt-2">
              <v-btn class="cmp_btn elevation-10" style="width: 100%; height: 100%;" dark color="#627078" @click="openEventPopup">
                <span class="pb-2" style="font-size: large;">évènements</span>
                <v-progress-circular
                  class="shine"
                  :rotate="-90"
                  :size="80"
                  :width="10"
                  :color="getColor((finishedEventCount ? finishedEventCount : 0 ), (eventCount ? eventCount : 0))"
                  :value="eventCount == 0 && finishedEventCount == 0 ? 100 : eventCount == 0 ? 100 : eventCount ? (finishedEventCount*100)/eventCount : 0"
                >
                  <span style="font-size: smaller; color: white;">{{ (finishedEventCount) }} / {{ (eventCount) }}</span>
                </v-progress-circular>
              </v-btn>
            </v-col>
            <v-col class="px-4 py-2 pt-2">
              <v-btn class="cmp_btn elevation-10" style="width: 100%; height: 100%;" dark color="#627078" @click="openPhotoPopup">
                <span class="pb-2" style="font-size:large;">Photos ciblées</span>
                <v-progress-circular
                  class="shine"
                  :rotate="-90"
                  :size="80"
                  :width="10"
                  :color="getColor((selectedFolderData ? selectedFolderData.iMediasContentStep : 0 ), (selectedFolderData ? 3 : 0))"
                  :value="selectedFolderData ? selectedFolderData.iMediasContentStep == 0 ? 100 : ((selectedFolderData.iMediasContentStep)*100)/3 : 0"
                >
                  <span style="font-size: smaller; color: white;">{{ selectedFolderData ? selectedFolderData.iMediasContentStep && selectedFolderData.iMediasContentStep > 3 ? 3 : selectedFolderData.iMediasContentStep : '?' }} / {{ selectedFolderData ? 3 : '?' }}</span>
                </v-progress-circular></v-btn>
            </v-col>
            <v-col class="px-4 py-2 pt-2">
              <v-btn dark class="cmp_btn elevation-10" style="width: 100%; height: 100%;" color="#627078" @click="documents_categories = selectedFolderData ? [selectedFolderData.sBrandTitle, selectedFolderData.sModelTitle, `${selectedFolderData.sBrandTitle} ${selectedFolderData.sModelTitle}`, 'commun'] : ['commun']; documentation = true;">
                <span style="font-size: large">Documentation <br/>contextualisée</span>
                <v-icon style="background-color:white; border-radius: 10px;" color="red" x-large class="mt-4">mdi-file-pdf-box</v-icon>
              </v-btn>
            </v-col>
            <v-col class="px-4 py-2 pt-2">
              <v-btn class="cmp_btn elevation-10" style="width: 100%; height: 100%;" :dark="selectedFolderData && selectedFolderData.kIDWFStatus < '1150910' ? false : true" :color="selectedFolderData && selectedFolderData.kIDWFStatus < '1150910' ? 'success' : '#627078'" @click="confirmMounted">

                <span v-if="selectedFolderData && selectedFolderData.kIDWFStatus >= '1150910'" style="font-size: large">Fin du montage<br/> confirmée</span>
                <v-icon v-if="selectedFolderData && selectedFolderData.kIDWFStatus >= '1150910'" class="mt-4" color="success">mdi-check</v-icon>
                <span v-else style="font-size: large">Confirmer la <br/>fin du montage</span>
              </v-btn>
            </v-col>
            <v-col class="px-4 py-2 pt-2">
              <v-btn class="cmp_btn elevation-10" :disabled="canValidate" style="width: 100%; height: 100%;" color="info" @click="validate">
                <span style="font-size: large">Confirmer la <br/>finalisation <br/>du dossier</span>
              </v-btn>
            </v-col>
          </v-row>
      </v-col>
      <v-col v-else style="display: flex; flex-direction: column;" class="h-100 center-xy" cols="9" xl="9" lg="9" md="9" sm="10" xs="10">
        <img :src="folderImg" style="max-width: 80vh;" />
        <div class="text-h4 mt-4">Veuillez choisir un dossier pour continuer</div>
      </v-col>
    </v-row>
    <EditAFDetailsPopUp :selectedFolder="selectedFolderData" :dialog="detailspopup" @close="detailspopup = false;" />
    <v-dialog
      v-model="documentation"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card class="d-flex flex-column" >
        <v-toolbar
          dark
          color="red"
          style="flex: 0;"
        >
          <v-btn
            icon
            dark
            @click="documentation = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Documentation contextualisée {{ getFilterPopUpTitle() }}</v-toolbar-title>
        </v-toolbar>
        <Documentation filtered :categories="documents_categories" :types="document_type"></Documentation>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="mountpopup"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <template>
        <v-card>
          <div>
            <p class="text-h5 text-center pt-6">Êtes-vous sûr de valider la fin du montage ?</p>
            <p class="text-body-1 text-center">Si vous confirmez, la date de fin de montage sera inscrite dans le dossier.</p>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded @click="mountpopup = false" large><v-icon class="pr-2 pl-2">mdi-cancel</v-icon><span class="pr-2">Annuler</span></v-btn>
            <v-btn rounded color="success" class="pr-2" large @click="mountFolder()"><v-icon class="pl-2 pr-2">mdi-check</v-icon><span class="pr-2">Je confirme</span></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

import FloatMixin from '@/mixins/FloatMixin';

import Field from './components/Field.vue'
import CarrossagePopUp from './components/CarrossagePopUp.vue';
import PhotoPopUp from './components/PhotoPopUp.vue';
import EditAFDetailsPopUp from './components/EditAFDetailsPopUp.vue';
import EventPopUpCmp from './components/EventPopUp.vue';
import Documentation from '../Documentation/Layout.vue';

import folderImg from '@/assets/images/folder.jpg';

export default {
  name: 'EditAssemblyFolders',

  mixins: [FloatMixin],

  components: { Field, CarrossagePopUp, PhotoPopUp, EditAFDetailsPopUp, EventPopUpCmp, Documentation },

  data: function() {
    return {
      detailspopup: false,
      carrossagepopup: false,
      photopopup: false,
      search: null,
      selectedFolder: null,
      photoLastUpdate: new Date(),
      documentation: false,
      document_type: ["Notices de montage"],
      documents_categories: ["commun"],
      eventPopup: false,
      refreshEventData: 0,
      folderImg: folderImg,
      mountpopup: false
    }
  },

  watch: {
    getRecords: function() {
      this.selectFirst();
    }
  },
  
  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
      assemblyFoldersRecords: 'assemblyFolders/getRecords',
      complementRecords: 'complement/getRecords',
      avarieRecords: 'avaries/getRecords',
      consigneRecords: 'consignes/getRecords'
    }),
    getRecords() {
      let result = this.assemblyFoldersRecords.filter(el => el.kIDWFStatus > 0 && el.kIDWFStatus <= 1150910)
      
      // Gestion de la recherche texte first
      if (this.search) {
        const me = this;
        
        result = result.filter((el) => {
          if (el.search) {
            return el.search.toUpperCase().includes(me.search.toUpperCase());
          }
          else {
            return false;
          }
        });
      }

      return result;
    },
    selectedFolderData() {
      return this.getRecords[this.selectedFolder];
    },
    avaries() {
      this.refreshEventData;
      if (this.selectedFolderData) {
        let avaries = this.avarieRecords.filter(e => e.sFolderOrder + '-' + e.sFolderOrderLine == this.selectedFolderData.sProjectOrderLine);
        avaries = avaries ? Array.isArray(avaries) ? avaries : [avaries] : [];

        return avaries;
      }

      return [];
    },
    consignes() {
      this.refreshEventData;
      if (this.selectedFolderData) {
        let consignes = this.consigneRecords.filter(e => e.sFolderOrder + '-' + e.sFolderOrderLine == this.selectedFolderData.sProjectOrderLine);
        consignes = consignes ? Array.isArray(consignes) ? consignes : [consignes] : [];

        return consignes;
      }

      return [];
    },
    finishedAvaries() {
      this.refreshEventData;
      let finishedAvaries = this.avaries ? this.avaries.filter(e => e.kIDWFStatus.toString().endsWith('99')) : [];
      finishedAvaries = finishedAvaries ? Array.isArray(finishedAvaries) ? finishedAvaries : [finishedAvaries] : [];

      return finishedAvaries;
    },
    finishedConsignes() {
      this.refreshEventData;
      let finishedConsignes = this.consignes ? this.consignes.filter(e => e.kIDWFStatus.toString().endsWith('99')) : [];
      finishedConsignes = finishedConsignes ? Array.isArray(finishedConsignes) ? finishedConsignes : [finishedConsignes] : [];

      return finishedConsignes;
    },
    finishedEventCount() {
      this.refreshEventData;
      return (this.finishedAvaries.length + this.finishedConsignes.length);
    },
    eventCount() {
      this.refreshEventData;
      return (this.avaries.length + this.consignes.length);
    },
    canValidate() {
      let notValid = false;
      
      if (this.selectedFolderData && this.selectedFolderData.iGlobalFormContentStep != this.selectedFolderData.iGlobalFormSteps) {
        notValid = true;
      }
      
      if (this.eventCount != this.finishedEventCount) {
          notValid = true;
      }

      if (this.selectedFolderData && parseInt(this.selectedFolderData.iMediasContentStep) < parseInt(3)) {
        notValid = true;
      }

      if (this.selectedFolderData.kIDWFStatus != '1150910') {
        notValid = true;
      }

      return notValid;
    }
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
    this.selectFirst();
  },

  methods: {
    getColor: function(actual, max) {
      const red = "#d73e32";
      const orange = "#fb8c00";
      const blue = "#51b0ff";
      const green = "#4caf50";
      // 0    -> red
      // 1%   -> 50% orange
      // 50   -> 99% bleu
      // 100% -> vert
      const percent = (actual*100)/max;
      
      if (percent < 1) {
        return red;
      }
      else if (percent > 1 && percent <= 50) {
        return orange
      }
      else if (percent > 50 && percent < 100) {
        return blue;
      }
      else {
        return green;
      }
    },
    openCarrossagePopup: function() {
      this.$store.commit('app/mask');
      this.$store.dispatch('globalFormContent/loadFromWS', { kIDUser: this.getUser.kIDUser, kIDFolder: this.selectedFolderData.kIDFolder }).then(() => {
        this.$store.dispatch('globalFormFieldContent/loadFromWS', { kIDUser: this.getUser.kIDUser, kIDFolder: this.selectedFolderData.kIDFolder }).then(() => {
          this.carrossagepopup = true;
          this.$store.commit('app/unmask');
        }).catch(() => { this.$store.commit('app/unmask'); });
      }).catch(() => { this.$store.commit('app/unmask'); });
    },
    closeCarrossagePopup: function() {
      this.carrossagepopup = false;
    },

    openPhotoPopup: function() {
      this.$store.commit('app/mask');
      this.$store.dispatch('folderMedia/loadFromWS', { kIDUser: this.getUser.kIDUser, kIDFolder: this.selectedFolderData.kIDFolder }).then(() => {
        this.photoLastUpdate = new Date();
        this.photopopup = true;
        this.$store.commit('app/unmask');
      }).catch(() => { this.$store.commit('app/unmask'); });
    },
    closePhotoPopup: function() {
      this.photopopup = false;
    },

    openEventPopup: function() {
      this.eventPopup = true;
    },
    closeEventPopup: function() {
      this.refreshEventData++;
      this.eventPopup = false;
    },

    onSearchValueChanged: function(value) {
      this.search = value;
    },

    selectFirst: function() {
      if (this.getRecords.length > 0 && this.selectedFolder == null) {
        this.selectedFolder = 0;
      }
    },

    getChassisTitle: function(chassis) {
      if (chassis) {
        return `${typeof chassis.kIDBrand == "object" ? chassis.kIDBrand.sBrandTitle : chassis.kIDBrand} ${typeof chassis.kIDModel == "object" ? chassis.kIDModel.sModelTitle : chassis.kIDModel}`;
      }

      return "";
    },

    getChassisData: function(data, key) {
      if (typeof data == 'object') {
        return data[key];
      }

      return '-'
    },

    getTipperData: function(data, key) {
      if (typeof data == 'object') {
        return data[key];
      }

      return '-'
    },

    getLockerData: function(data, key) {
      if (typeof data == 'object') {
        return data[key];
      }

      return '-'
    },
    
    getComplementsLength: function(ao) {
      if (ao && ao.sProjectOrder && ao.sProjectLine) {
        const record = this.complementRecords.filter(el => el.sOptionProjectOrder == ao.sProjectOrder && el.sOptionProjectOrderLine == ao.sProjectLine);
        return record.length;
      }
      else {
        return '?'
      }
    },

    getFilterPopUpTitle: function() {
      return this.selectedFolderData ? ` - ${this.selectedFolderData.sBrandTitle} - ${this.selectedFolderData.sModelTitle} - Commun` : ``;
    },

    confirmMounted: function() {
      if(this.selectedFolderData && this.selectedFolderData.kIDWFStatus < '1150910') {
        this.mountpopup = true;
      }
    },

    mountFolder: function() {
      this.$store.commit('app/mask');
      
      this.$store.dispatch("assemblyFolders/mount", {
        kIDUser: this.getUser.kIDUser, 
        kIDFolder: this.selectedFolderData.kIDFolder,
      }).then(() => {
        this.$store.commit('app/setMessage', {
          title: "Fin du montage du dossier de montage validé",
          message: "La fin du montage du dossier de montage est validé"
        });

        this.mountpopup = false;

        this.$store.commit('app/unmask');
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.$store.commit('app/unmask');
      });
    },

    validate: function() {
      this.$store.commit('app/mask');
      
      this.$store.dispatch("assemblyFolders/validate", {
        kIDUser: this.getUser.kIDUser, 
        kIDFolder: this.selectedFolderData.kIDFolder,
      }).then(() => {
        this.$store.commit('app/setMessage', {
          title: "Dossier de montage validé",
          message: "Le dossier de montage est validé."
        });

        this.selectedFolder = null;

        this.$store.commit('app/unmask');
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.$store.commit('app/unmask');
      });
    }
  }
}
</script>
<style>
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.nth-child-2n) {
    background-color: transparent !important;
  }

  .cmp_btn > .v-btn__content {
    flex-direction: column;
  }
</style>