var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"height":_vm.getTableHeight,"fixed-header":"","headers":_vm.headers,"items":_vm.getRecords,"item-class":_vm.getOddBackground,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"page":_vm.page,"item-key":"kIDProject","no-data-text":"Aucun complément disponible","show-expand":"","disable-filtering":"","disable-sort":""},on:{"update:page":function($event){_vm.page=$event},"click:row":_vm.onRowClicked,"page-count":_vm.onPageCountChange},scopedSlots:_vm._u([{key:"item.sProjectOrder",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sProjectOrder)+" / "+_vm._s(item.sProjectLine)+" ")]}},{key:"item.dProjectDate",fn:function(ref){
var item = ref.item;
return [_c('CustomDate',{attrs:{"date":item.dProjectDate}})]}},{key:"item.kIDContractor",fn:function(ref){
var item = ref.item;
return [_c('CustomChip',{attrs:{"color":"#1D2A31","text":typeof item.kIDContractor == 'object' ? item.kIDContractor.sAnybodyTitle : item.kIDContractor}})]}},{key:"item.kIDInvoicedClient",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(typeof item.kIDInvoicedClient == "object" ? item.kIDInvoicedClient.sAnybodyTitle : item.kIDInvoicedClient)+" ")]}},{key:"item.kIDBrand",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(typeof item.kIDBrand == "object" ? item.kIDBrand.sBrandTitle : item.kIDBrand)+" ")]}},{key:"item.kIDWFStatus",fn:function(ref){
var item = ref.item;
return [_c('Fa',{attrs:{"icon":item.sWFGlyphSymbol,"color":item.sWFGlyphColor,"size":"fa-2x"}})]}},{key:"item.sOptionCountExpected",fn:function(ref){
var item = ref.item;
return [_c('CustomChip',{attrs:{"color":item.options.length >= item.options[0].sOptionCountExpected ? 'green' : 'orange',"icon":item.options.length >= item.options[0].sOptionCountExpected ? 'mdi-check-circle-outline' : 'mdi-timer-sand-complete',"text":item.options.length + '/' + item.options[0].sOptionCountExpected}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-2",attrs:{"colspan":headers.length}},[_c('v-container',{staticClass:"mx-0",staticStyle:{"max-width":"unset"}},[_c('v-row',_vm._l((item.options),function(option){return _c('v-col',{key:option.kIDOption,attrs:{"xl":"3","lg":"3","md":"3","sm":"3","xs":"3","cols":"3"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(option.sOptionQuestionCode))]),_c('v-card-subtitle',[_vm._v(_vm._s(option.sOptionQuestionLabel)+" : "+_vm._s(option.sOptionAnswerLabel))])],1)],1)}),1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }