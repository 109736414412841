/**
 * Vue fonctionne mieux avec un principe de routage d'URL
 * Pour faire simple, chaque chemin va être lié a une vue.
 * Il est possible de faire de la transmission d'info par args comme un vrai router a la Laravel/ExpressJS bref comme chaque framework
 * Il est aussi possible d'intercepter le changement de route pour pouvoir executer un payload avant/après chaque routage
 * Par exemple dans ce code, la vérification de l'auth avant chaque changement avec le metatag "guest"
 * Si jamais, c'est possible de faire un drawer pour gérer les routes dans plusieurs fichiers mais je trouve ça contreproductif
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login/Login.vue'
import AssemblyOrders from '../views/AssemblyOrders/AssemblyOrders.vue'
import Chassis from '../views/Chassis/Chassis.vue'
import Layout from '../views/Layout/Layout.vue'
import Tippers from '../views/Tippers/Tippers.vue'
import TippersIn from '../views/Tippers/TippersIn.vue'
import Lockers from '../views/Lockers/Lockers.vue'
import LockersIn from '../views/Lockers/LockersIn.vue'
import Complements from '../views/Complements/Complements.vue'
import AssemblyFolders from '../views/AssemblyFolders/AssemblyFolders.vue'
import InitAssemblyFolders from '../views/AssemblyFolders/InitAssemblyFolders.vue'
import EditAssemblyFolders from '../views/AssemblyFolders/EditAssemblyFolders.vue'
import Expedition from '../views/Expedition/Expedition.vue'
import Home from '../views/Home/Home.vue'

import Documentation from '../views/Documentation/Layout.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/home',
    name: 'Accueil',
    component: Home,
    meta: {
      guest: false,
      pagination: false,
      searchable: false
    }
  },
  {
    path: '/data/assembly_orders',
    name: 'Ordres de montage',
    component: AssemblyOrders,
    meta: {
      guest: false,
      pagination: true,
      searchable: true
    }
  },
  {
    path: '/data/assembly_folders',
    name: 'Dossiers de montage',
    component: AssemblyFolders,
    meta: {
      guest: false,
      pagination: true,
      searchable: true
    }
  },
  {
    path: '/reception/chassis',
    name: 'Réception des châssis',
    component: Chassis,
    meta: {
      guest: false,
      pagination: true,
      searchable: true
    }
  },
  {
    path: '/reception/tippers',
    name: 'Réception des bennes',
    component: TippersIn,
    meta: {
      guest: false,
      pagination: true,
      searchable: true
    }
  },
  {
    path: '/reception/lockers',
    name: 'Réception des coffres',
    component: LockersIn,
    meta: {
      guest: false,
      pagination: true,
      searchable: true
    }
  },
  {
    path: '/data/tippers',
    name: 'Bennes',
    component: Tippers,
    meta: {
      guest: false,
      pagination: true,
      searchable: true
    }
  },
  {
    path: '/data/lockers',
    name: 'Coffres',
    component: Lockers,
    meta: {
      guest: false,
      pagination: true,
      searchable: true
    }
  },
  {
    path: '/data/complements',
    name: 'Compléments',
    component: Complements,
    meta: {
      guest: false,
      pagination: true,
      searchable: true
    }
  },
  {
    path: '/layout',
    name: 'Emplacements',
    component: Layout,
    meta: {
      guest: false,
      pagination: false,
      searchable: true
    }
  },
  {
    path: '/assembly_folder/init',
    name: 'Initialisation des dossiers de montage',
    component: InitAssemblyFolders,
    meta: {
      guest: false,
      pagination: false,
      searchable: true
    }
  },
  {
    path: '/assembly_folder/edit',
    name: 'Dossiers de montage en cours',
    component: EditAssemblyFolders,
    meta: {
      guest: false,
      pagination: false,
      searchable: true
    }
  },
  {
    path: '/assembly_vehicle',
    name: 'assembly_vehicle',
    component: AssemblyOrders,
    meta: {
      guest: false
    }
  },
  {
    path: '/shipping',
    name: 'Expédition',
    component: Expedition,
    meta: {
      guest: false
    }
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation,
    meta: {
      guest: false
    }
  },
  {
    path: '/assembly_center',
    name: 'assembly_center',
    component: AssemblyOrders,
    meta: {
      guest: false
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: AssemblyOrders,
    meta: {
      guest: false
    }
  }
]

const router = new VueRouter({
  routes
})

// Route par défaut
const defaultRoute = "Accueil";

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('access_token');
  
  // Si la route n'existe pas
  if (to.matched.length <= 0) {
    next({ name: 'login'})
  }
  // Si la route a besoin d'authentification
  else if (to.matched.some(record => !record.meta.guest)) {
    // Si l'user n'est pas auth
    if (user == null || token == null) {
      next({ name: 'login'})
    }
    else {
      next()
    }
  } else {
    // Si l'utilisateur n'est pas auth c'est parfait
    if(user == null || token == null) {
      next()
    }
    // Sinon il faut afficher la route par défaut
    else{
      next({ name: defaultRoute})
    }
  }
})

export default router