<template>
<div v-if="show" style="display: inline-flex; align-items: center; flex-grow: 1;">
  <v-pagination
    v-model="page"
    :length="pageCount"
    :total-visible="10"
    circle
  ></v-pagination>
</div>

</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: 'PaginationHeader',

  data: function() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 20
    }
  },
  
  watch : {
    page: function() {
      this.emitChange();
    }
  },

  computed: {
    show() {
      return this.$route?.meta?.pagination;
    }
  },
  
  mounted: function() {
    EventBus.$on('gridPageCountChanged', this.onGridPageCountChanged);
    EventBus.$on('resetPaginationHeader', this.reset);
  },

  methods: {
    emitChange: function() {
      EventBus.$emit('headerPaginationChanged', {page: this.page, pageCount: this.pageCount, itemsPerPage: this.itemsPerPage});
    },

    onGridPageCountChanged: function(event) {
      this.pageCount = event;
    },

    reset: function() {
      this.page = 1;
      this.pageCount = 0;
      this.itemsPerPage = 20;
    }
  }
}
</script>