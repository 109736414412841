var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-navigation',{attrs:{"background-color":"red","dark":"","app":"","value":_vm.$route.name,"grow":""}},[_c('v-btn',{attrs:{"value":"home","to":"/home"}},[_c('span',{staticClass:"ml-14"},[_vm._v("Accueil")]),_c('v-icon',{staticClass:"ml-14"},[_vm._v("mdi-home")])],1),_c('v-menu',{attrs:{"transition":"slide-y-transition","open-on-hover":"","rounded":"t-xl","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"assembly_folder","link":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Montage")]),_c('v-icon',[_vm._v("mdi-file-document-edit-outline")]),_c('v-icon',{staticStyle:{"position":"absolute","top":"5px","right":"5px"}},[_vm._v("mdi-upload-multiple")])],1)]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"red"}},[_c('v-list-item',{attrs:{"to":"/assembly_folder/init"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Initialisation")])],1)],1),_c('v-list-item',{attrs:{"to":"/assembly_folder/edit"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-sign")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dossiers")])],1)],1)],1)],1)],1),_c('v-btn',{attrs:{"value":"shipping","to":"/shipping"}},[_c('span',[_vm._v("Expédition véhicule")]),_c('v-icon',[_vm._v("mdi-truck-delivery-outline")])],1),_c('v-btn',{attrs:{"value":"documentation","to":"/documentation"}},[_c('span',[_vm._v("Documentation")]),_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"value":"layout","to":"/layout"}},[_c('span',[_vm._v("Emplacements")]),_c('v-icon',[_vm._v("mdi-map-marker-check")])],1),_c('v-menu',{attrs:{"transition":"slide-y-transition","open-on-hover":"","rounded":"t-xl","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"reception","link":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Réception")]),_c('v-icon',[_vm._v("mdi-package-down")]),_c('v-icon',{staticStyle:{"position":"absolute","top":"5px","right":"5px"}},[_vm._v("mdi-upload-multiple")])],1)]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"red"}},[_c('v-list-item',{attrs:{"to":"/reception/tippers"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-dump-truck")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Bennes")])],1)],1),_c('v-list-item',{attrs:{"to":"/reception/lockers"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-toolbox")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Coffres")])],1)],1),_c('v-list-item',{attrs:{"to":"/reception/chassis"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-car-convertible")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Châssis")])],1)],1)],1)],1)],1),_c('v-menu',{attrs:{"transition":"slide-y-transition","open-on-hover":"","rounded":"t-xl","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"data","link":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Flux")]),_c('v-icon',[_vm._v("mdi-database-eye-outline")]),_c('v-icon',{staticStyle:{"position":"absolute","top":"5px","right":"5px"}},[_vm._v("mdi-upload-multiple")])],1)]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"red"}},[_c('v-list-item',{attrs:{"to":"/data/tippers"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-dump-truck")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Bennes")])],1)],1),_c('v-list-item',{attrs:{"to":"/data/lockers"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-toolbox")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Coffres")])],1)],1),_c('v-list-item',{attrs:{"to":"/data/complements"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-folder-question")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Compléments")])],1)],1),_c('v-list-item',{attrs:{"to":"/data/assembly_orders"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-car-wrench")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ordres de montage")])],1)],1),_c('v-list-item',{attrs:{"to":"/data/assembly_folders"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document-edit-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dossiers de montage")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }