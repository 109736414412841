<template>
  <v-chip
    v-if="text != ''"
    :color="color"
    :dark="dark"
  >
    <v-icon v-if="icon != ''" left>
      {{ icon }}
    </v-icon>
    {{ text }}
  </v-chip>
</template>

<script>
export default {
  name: 'CustomChip',

  props: {
    color: {
      type: String,
      default: "grey"
    },
    text: {
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    dark: {
      type: Boolean,
      default: true
    }
  },

  data: function() {
    return {
        
    }
  },

  methods: {
    
  }
}
</script>