<template>
    <div>
        <v-list-item-subtitle :class="cssClass" style="margin-bottom: 2px;">{{ title }}</v-list-item-subtitle>
        <p class="mb-0">{{ data || "-" }}</p>
    </div>
</template>

<script>
export default {
  name: 'CustomChip',

  props: {
    title: {
      type: String,
      default: ""
    },
    data: {
      default: ""
    },
    cssClass: {
      type: String,
      default: ""
    }
  },

  data: function() {
    return {
        
    }
  },

  methods: {
    
  }
}
</script>