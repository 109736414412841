<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="d-flex flex-column" >
      <v-toolbar
        dark
        color="red"
        style="flex: 0;"
      >
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Photos ciblées</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ headerTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>Requis : {{ photoLenght > 3 ? 3 : photoLenght }}/3</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-4 mb-0 mt-0">
        <v-col cols="4">
          <Photo description="3/4 arrière gauche" required :folder="folder" :index="0" :lastUpdate="lastUpdate" @onChange="onPhotoChange" @onSaved="onPhotoSaved(0)"></Photo>
        </v-col>
        <v-col cols="4">
          <Photo description="3/4 arrière droit" required :folder="folder" :index="1" :lastUpdate="lastUpdate" @onChange="onPhotoChange" @onSaved="onPhotoSaved(1)"></Photo>
        </v-col>
        <v-col cols="4">
          <Photo description="Ticket de pesée" required :folder="folder" :index="2" :lastUpdate="lastUpdate" @onChange="onPhotoChange" @onSaved="onPhotoSaved(2)"></Photo>
        </v-col>
        <v-col cols="4">
          <Photo description="Zone d'intérêt N°1" :folder="folder" :index="3" :lastUpdate="lastUpdate" @onChange="onPhotoChange" @onSaved="onPhotoSaved(3)"></Photo>
        </v-col>
        <v-col cols="4">
          <Photo description="Zone d'intérêt N°2" :folder="folder" :index="4" :lastUpdate="lastUpdate" @onChange="onPhotoChange" @onSaved="onPhotoSaved(4)"></Photo>
        </v-col>
        <v-col cols="4">
          <Photo description="Zone d'intérêt N°3" :folder="folder" :index="5" :lastUpdate="lastUpdate" @onChange="onPhotoChange" @onSaved="onPhotoSaved(5)"></Photo>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import Photo from "./Photo.vue";

export default {
  name: "PhotoPopUp",
  components: { Photo },
  props: {
    dialog: {
      default: false
    },
    folder: {
      type: Object,
      default: () => ({
        kIDFolder: null
      })
    },
    lastUpdate: {
      type: Date,
      default: null
    },
    headerTitle: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      reset: null,
      photos: [{index: 0, ok: false},{index: 1, ok: false},{index: 2, ok: false},{index: 3, ok: false},{index: 4, ok: false},{index: 5, ok: false}]
    };
  },
  computed: {
    photoLenght: function(){
      if (this.photos) {
        return this.photos.filter(el => el.index < 3 && el.ok).length
      }

      return 0;
    }
  },
  methods: {
    onPhotoChange: function(payload) {
      const { index, ok } = payload;
      const photo = this.photos.find((el) => el.index == index);
      photo.ok = ok;
    },
    onPhotoSaved: function(index) {
      if (index < 3) {
        this.folder.iMediasContentStep = this.photos.filter(el => el.index < 3 && el.ok).length;
      }
    },
    close: function () {
      this.reset = new Date().getTime();
      this.$emit("close");
    }
  },
}
</script>