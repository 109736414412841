<template>
  <div class="my-2 mx-2" style="display: inline-block">
    <div class="custom-chip left v-chip v-size--default py-2" :style="`background-color:${color}; border-color:${color}`" >{{ title }}</div>
    <div class="custom-chip right v-chip v-size--default py-2">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'Chip',

  props: {
    cssClass: {
        type: String,
        default: ""
    },
    color: {
        type: String,
        default: "black"
    },
    dark: {
        type: Boolean,
        default: true
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      default: ""
    }
  },

  data: function() {
    return {
        
    }
  },

  methods: {
    
  }
}
</script>
<style>
  .custom-chip {
    display: inline;
    border: 1px solid;
  }

  .custom-chip.left {
    color: white;
    border-radius: 16px 0 0 16px;
    border-right-width: 0px
  }
  .custom-chip.right {
    color: black;
    border-radius: 0 16px 16px 0;
    border-left-width: 0px
  }
</style>