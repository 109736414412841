<template>
  <span>{{ new Date(date).toLocaleDateString() }}</span>
</template>

<script>
export default {
  name: 'CustomDate',

  props: {
    date: null
  },

  data: function() {
    return {
        
    }
  },

  methods: {
    
  }
}
</script>