import GlobalFormFieldContentServices from '@/services/globalFormFieldContentServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }
}

// actions
const actions = {
  loadFromWS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDFolder, page, start, limit } = payload;

        commit('download/increaseCounter', null, { root: true });
        GlobalFormFieldContentServices.getList(
          //kIDUser,
          kIDUser,
          // sParameter
          [1, kIDFolder],
          //xmlFiltersSorts,
          "",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          commit('setRecords', response.Item);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  setRecords(state, records) {
    const rs = Array.isArray(records) ? records : typeof records == 'object' ? [records] : [];

    rs.forEach(record => {
      const exist = state.records.find(el => el.kIDGlobalFormFieldContent == record.kIDGlobalFormFieldContent);

      if (exist) {
        exist.dFieldResponseDate = record.dFieldResponseDate;
        exist.kIDGlobalForm = record.kIDGlobalForm;
        exist.kIDGlobalFormContent = record.kIDGlobalFormContent;
        exist.kIDGlobalFormField = record.kIDGlobalFormField;
        exist.kIDGlobalFormFieldContent = record.kIDGlobalFormFieldContent;
        exist.sFieldName = record.sFieldName;
        exist.vFieldPhoto1FilePath = record.vFieldPhoto1FilePath;
        exist.vFieldPhoto2FilePath = record.vFieldPhoto2FilePath;
        exist.sFieldQuestion = record.sFieldQuestion;
        exist.sFieldResponseContent = record.sFieldResponseContent;
        exist.vFieldSignatureFilePath = record.vFieldSignatureFilePath;
        exist.sFieldType = record.sFieldType;
      }
      else {
        state.records.push(record);
      }
    });
  },

  reset(state) {
    state.records = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}