<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="d-flex flex-column" >
      <v-toolbar
        dark
        color="red"
        style="flex: 0;"
      >
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Détails du dossier de montage</v-toolbar-title>
      </v-toolbar>
      <div class="px-4 py-2">
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <v-expansion-panel v-if="selectedFolder.kIDChassis">
            <v-expansion-panel-header>Châssis</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container class="mx-0" style="max-width: unset;">
                <v-row>
                  <v-col cols="6">
                    <Field title="Marque" :data="getChassisData(selectedFolder.kIDChassis, 'kIDBrand').sBrandTitle" />
                    <Field title="Modèle" :data="getChassisData(selectedFolder.kIDChassis, 'kIDModel').sModelTitle" />
                    <Field title="Couleur" :data="getChassisData(selectedFolder.kIDChassis, 'sChassisColor')" />
                  </v-col>
                  <v-col cols="6">
                    <Field title="Numéro de châssis" :data="getChassisData(selectedFolder.kIDChassis, 'sChassisNumber')" />
                    <Field title="Numéro de commande client" :data="getChassisData(selectedFolder.kIDChassis, 'sChassisNumberOrder')" />
                    <Field title="Date de réception" :data="new Date(getChassisData(selectedFolder.kIDChassis, 'dChassisReceptionDate')).toLocaleString()" />
                  </v-col>
                  <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                    <CollapsedCard style="min-height: 160px;">
                      <template v-slot:content>
                        <v-list-item>
                          <v-list-item-content>
                            <div class="text-overline mb-2">
                              Informations générales
                            </div>

                            <CustomSubtitle 
                              title="Empattement"
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisWheelBase')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Type de roues" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisWheelType')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:collapsed>
                        <v-list-item>
                          <v-list-item-content>                        
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Cabine" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisCabin')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Largeur châssis" 
                              :data="getReadabledFloat(getChassisData(selectedFolder.kIDChassis, 'fChassisWidth'))"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Porte à faux" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisPaf')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </CollapsedCard>
                  </v-col>
                  
                  <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                    <CollapsedCard style="min-height: 160px;">
                      <template v-slot:content>
                        <v-list-item>
                          <v-list-item-content>
                            <div class="text-overline mb-2">
                              Détails 1
                            </div>
                          
                            <CustomSubtitle 
                              title="Coffre déplacement roue de secours" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisBootWheel')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Coffre coupe châssis" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisBootChassis')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:collapsed>
                        <v-list-item>
                          <v-list-item-content>                        
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Benne déplacement roue de secours" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisDumpWheel')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </CollapsedCard>
                  </v-col>
                  <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                    <CollapsedCard style="min-height: 160px;">
                      <template v-slot:content>
                        <v-list-item>
                          <v-list-item-content>
                            <div class="text-overline mb-2">
                              Détails 2
                            </div>
                    
                            <CustomSubtitle
                              title="Poids maxi essieu avant" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisMaxWeightFrontAxle')"
                            />
                    
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Poids maxi essieu arrière" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisMaxWeightBackAxle')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:collapsed>
                        <v-list-item>
                          <v-list-item-content>
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Nombre de places avant" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisNumberFrontSeat')"
                            />
                    
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Nombre de places arrière" 
                              :data="getChassisData(selectedFolder.kIDChassis, 'sChassisNumberBackSeat')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </CollapsedCard>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="selectedFolder.kIDTipper">
            <v-expansion-panel-header>Benne</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container class="mx-0" style="max-width: unset;">
                <v-row>
                  <v-col cols="6">
                    <Field title="N° commande/ligne" :data="getTipperData(selectedFolder.kIDTipper, 'sProjectLine')" />
                    <Field title="N° de série" :data="getTipperData(selectedFolder.kIDTipper, 'sSupplySerialNumber')" />
                    <Field title="N° de pile" :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyStackNumber')" />
                  </v-col>
                  <v-col cols="6">
                    <Field title="Type de benne" :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyDumpType')" />
                    <Field title="Couleur" :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyProductionColor')" />
                  </v-col>

                  <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                    <CollapsedCard style="min-height: 160px;" :collapse="false">
                      <template v-slot:content>
                        <v-list-item>
                          <v-list-item-content>
                            <div class="text-overline mb-2">
                              Benne
                            </div>

                            <CustomSubtitle 
                              title="Longueur" 
                              :data="getReadabledFloat(getTipperData(selectedFolder.kIDTipper, 'fSupplyDumpLength'))"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Largeur" 
                              :data="getReadabledFloat(getTipperData(selectedFolder.kIDTipper, 'fSupplyDumpWidth'))"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:collapsed>
                        <v-list-item>
                          <v-list-item-content>                        
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Manquants bloquants" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyMandatoryMissing')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Manquants non bloquants" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyUnMandatoryMissing')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Largeur Faux Châssis" 
                              :data="getReadabledFloat(getTipperData(selectedFolder.kIDTipper, 'fSupplyFalseChassisWidth'))"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Plateau Faux Châssis" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyPlateauFalseChassis')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Finition" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyFinishing')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Couleur commerciale" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyCommercialColor')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </CollapsedCard>
                  </v-col>
                  
                  <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                    <CollapsedCard style="min-height: 160px;">
                      <template v-slot:content>
                        <v-list-item>
                          <v-list-item-content>
                            <div class="text-overline mb-2">
                              Châssis correspondant
                            </div>
                          
                            <CustomSubtitle 
                              title="Largeur Châssis" 
                              :data="getReadabledFloat(getTipperData(selectedFolder.kIDTipper, 'fSupplyChassisWidth'))"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Cabine" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyCabin')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:collapsed>
                        <v-list-item>
                          <v-list-item-content>                        
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Empattement" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyWheelBase')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Type de roue" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyWheelType')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Tonnage" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyTonnage')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </CollapsedCard>
                  </v-col>

                  <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                    <CollapsedCard style="min-height: 160px;">
                      <template v-slot:content>
                        <v-list-item>
                          <v-list-item-content>
                            <div class="text-overline mb-2">
                              Porte, ridelle et attelage
                            </div>
                    
                            <CustomSubtitle
                              title="Porte Arrière" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyBackDoor')"
                            />
                    
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Hauteur Porte" 
                              :data="getReadabledFloat(getTipperData(selectedFolder.kIDTipper, 'fSupplyDoorHeight'))"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:collapsed>
                        <v-list-item>
                          <v-list-item-content>                
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Hauteur Ridelle" 
                              :data="getReadabledFloat(getTipperData(selectedFolder.kIDTipper, 'fSupplyWallHeight'))"
                            />
                    
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Matière Ridelle" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyWallMaterial')"
                            />
                    
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Matière Porte" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyDoorMaterial')"
                            />
                    
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Type PE" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyPeType')"
                            />
                    
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Matière PE" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyPeMaterial')"
                            />
                    
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Largeur Berce" 
                              :data="getReadabledFloat(getTipperData(selectedFolder.kIDTipper, 'fSupplyCradleWidth'))"
                            />
                    
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Attelage" 
                              :data="getTipperData(selectedFolder.kIDTipper, 'sSupplyHitch')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </CollapsedCard>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="selectedFolder.kIDLocker">
            <v-expansion-panel-header>Coffre</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container class="mx-0" style="max-width: unset;">
                <v-row>
                  <v-col cols="6">
                    <Field title="N° commande/ligne" :data="getLockerData(selectedFolder.kIDLocker, 'sProjectLine')" />
                    <Field title="N° de série" :data="getLockerData(selectedFolder.kIDLocker, 'sSupplySerialNumber')" />
                    <Field title="N° de pile" :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyStackNumber')" />
                  </v-col>
                  <v-col cols="6">
                    <Field title="Matière Coffre" :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyBootMaterial')" />
                    <Field title="Dimensions" :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyBootDimensions')" />
                    <Field title="Couleur" :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyProductionColor')" />
                  </v-col>

                  <v-col xl="6" lg="6" md="6" sm="6" xs="6" cols="6">
                    <CollapsedCard style="min-height: 160px;" :collapse="false">
                      <template v-slot:content>
                        <v-list-item>
                          <v-list-item-content>
                            <div class="text-overline mb-2">
                              Coffre
                            </div>

                            <CustomSubtitle
                              title="Manquants bloquants" 
                              :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyMandatoryMissing')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Manquants non bloquants" 
                              :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyUnMandatoryMissing')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:collapsed>
                        <v-list-item>
                          <v-list-item-content>                        
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Finition" 
                              :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyFinishing')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Couleur commerciale" 
                              :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyCommercialColor')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </CollapsedCard>
                  </v-col>
                  
                  <v-col xl="6" lg="6" md="6" sm="6" xs="6" cols="6">
                    <CollapsedCard style="min-height: 160px;">
                      <template v-slot:content>
                        <v-list-item>
                          <v-list-item-content>
                            <div class="text-overline mb-2">
                              Châssis correspondant
                            </div>
                          
                            <CustomSubtitle 
                              title="Largeur Châssis" 
                              :data="getReadabledFloat(getLockerData(selectedFolder.kIDLocker, 'fSupplyChassisWidth'))"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Cabine" 
                              :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyCabin')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:collapsed>
                        <v-list-item>
                          <v-list-item-content>                        
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Empattement" 
                              :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyWheelBase')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Type de roue" 
                              :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyWheelType')"
                            />
                            
                            <CustomSubtitle 
                              cssClass="mt-2"
                              title="Tonnage" 
                              :data="getLockerData(selectedFolder.kIDLocker, 'sSupplyTonnage')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </CollapsedCard>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="getComplements && getComplements.length > 0">
            <v-expansion-panel-header>Compléments</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container class="mx-0" style="max-width: unset;">
                <v-row>
                  <v-col v-for="option in getComplements" :key="option.kIDOption" cols="4">
                    <v-card class="h-100">
                      <v-card-title>{{ option.sOptionQuestionCode  }}</v-card-title>
                      <v-card-subtitle>{{ option.sOptionQuestionLabel }} : {{ option.sOptionAnswerLabel }}</v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import Field from './Field'
import CollapsedCard from '@/components/DataGrid/ExpandedItem/CollapsedCard';
import CustomSubtitle from '@/components/DataGrid/ExpandedItem/CustomSubtitle';
import DataGridMixin from '@/components/DataGrid/DataGridMixin';

export default {
  name: "EditAFDetailsPopup",
  mixins: [DataGridMixin],
  components: { Field, CollapsedCard, CustomSubtitle },
  props: {
    dialog: {
      default: false
    },
    selectedFolder: {
      type: Object,
      default: () => ({
        kIDFolder: null
      })
    }
  },
  computed: {
    ...mapGetters({
      complementRecords: 'complement/getRecords'
    }),
    getComplements: function() {
      const record = this.complementRecords.filter(el => el.sOptionProjectOrder == this.selectedFolder.sProjectOrder && el.sOptionProjectOrderLine == this.selectedFolder.sProjectLine);
      return record.length > 0 ? record : null;
    },
  },
  data: function () {
    return {
      panel: [0]
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    getChassisTitle: function(chassis) {
      if (chassis) {
        return `${typeof chassis.kIDBrand == "object" ? chassis.kIDBrand.sBrandTitle : chassis.kIDBrand} ${typeof chassis.kIDModel == "object" ? chassis.kIDModel.sModelTitle : chassis.kIDModel}`;
      }

      return "";
    },

    getChassisData: function(data, key) {
      if (typeof data == 'object') {
        return data[key];
      }

      return '-'
    },

    getTipperData: function(data, key) {
      if (typeof data == 'object') {
        return data[key];
      }

      return '-'
    },

    getLockerData: function(data, key) {
      if (typeof data == 'object') {
        return data[key];
      }

      return '-'
    },

    isManquantNonBloquant: function(locker, tipper) {
      if (locker) {
        if (locker.sSupplyUnMandatoryMissing && locker.sSupplyUnMandatoryMissing == "OUI") {
          return true;
        }
      }

      if (tipper) {
        if (tipper.sSupplyUnMandatoryMissing && tipper.sSupplyUnMandatoryMissing == "OUI") {
          return true;
        }
      }

      return false;
    },

    isManquantBloquant: function(locker, tipper) {
      if (locker) {
        if (locker.sSupplyMandatoryMissing && locker.sSupplyMandatoryMissing == "OUI") {
          return true;
        }
      }

      if (tipper) {
        if (tipper.sSupplyMandatoryMissing && tipper.sSupplyMandatoryMissing == "OUI") {
          return true;
        }
      }

      return false;
    },
  },
}
</script>