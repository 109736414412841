import BrandsServices from '@/services/brandsServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }
}

// actions
const actions = {
  loadFromWS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, page, start, limit } = payload;

        commit('download/increaseCounter', null, { root: true });
        BrandsServices.getList(
          //kIDUser,
          kIDUser,
          //xmlFiltersSorts,
          "",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          commit('setRecords', response.Item);
          
          // Il faut MAJ les stores qui utilise ce store comme data secondaire
          const list = ['assemblyOrders', 'chassis'];

          list.forEach(store => {
            dispatch(store + "/setRelationship", "kIDBrand", {root:true});
          });

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  setRecords(state, records) {
    state.records = Object.freeze(Array.isArray(records) ? records : typeof records == 'object' ? [records] : []);
  },

  reset(state) {
    state.records = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}