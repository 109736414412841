<template>
  <v-card class="mx-auto h-100">
    <div @click="onClickBigPicture" class="center-x" style="background-color: grey; ">
      <img ref="imgRef" :src="src != null ? src : loading ? img_loading : camera" :class="src != null ? '' : 'pa-8'" style="max-height: 25vh; max-width: 600px;" />
    </div>
    <div class="ml-2 mt-2"  v-if="required" >
      <v-icon size="20px" class="mr-1" color="red">mdi-alert</v-icon><span style="color: red; top: 2px; position: relative; font-size: small;">Cette photo est obligatoire</span>
    </div>
    <div class="ml-2 mt-2" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ description }}</div>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn icon @click="infoPopup = true">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>

      <v-btn v-if="src != null" icon @click="commentaryPopup = true">
        <v-icon>mdi-comment-edit-outline</v-icon>
      </v-btn>

      <v-btn v-if="src != null" icon @click="edit">
        <v-icon>mdi-image-edit</v-icon>
      </v-btn>

      <v-btn v-if="src != null" icon @click="fullscreenPopup = true">
        <v-icon>mdi-arrow-expand</v-icon>
      </v-btn>

      <v-btn :disabled="loading" icon @click="onClickPicture">
        <v-icon>mdi-camera</v-icon>
      </v-btn>

      <v-btn v-if="src != null" rounded dark color="green" @click="save">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>
    <!-- FULL SCREEN -->
    <v-dialog
      v-model="fullscreenPopup"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card class="center-xy" style="background-color: grey;" >
        <div style="position: fixed; top: 15px; left: 15px;">
          <v-btn
            light
            @click="fullscreenPopup = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <img :src="src != null ? src : camera" style="max-width: 100vw; max-height: 100vh;" />
      </v-card>
    </v-dialog>
    <!-- COMMENTARY -->
    <v-dialog
      v-model="commentaryPopup"
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-toolbar
          dark
          color="red"
          style="flex: 0;"
        >
          <v-btn
            icon
            dark
            @click="commentaryPopup = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Saisir un commentaire</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-textarea
              v-model="sFolderMediaCommentary"
              hide-details
              outlined
              label="Commentaire"
            ></v-textarea>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            @click="commentaryPopup = false"
          >
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- INFO -->
    <v-dialog
      v-model="infoPopup"
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-toolbar
          dark
          color="red"
          style="flex: 0;"
        >
          <v-btn
            icon
            dark
            @click="infoPopup = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Informations</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
            {{ description }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            @click="infoPopup = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDIT -->
    <PhotoEdit :editPopup="editPopup" :src="src" @srcChange="(newSrc) => { src = newSrc; save(); }" @close="editPopup = false"></PhotoEdit>
    <!-- SOURCE -->
    <v-dialog
      v-model="sourcePopup"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <template>
        <v-card>
          <div>
            <p class="text-h5 text-center pt-6">Veuillez préciser la source de votre photo</p>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="info" class="pr-2" large @click="takePicture('CAMERA')"><v-icon class="pl-2 pr-2">mdi-camera</v-icon><span class="pr-2">Caméra</span></v-btn>
            <v-btn rounded color="info" class="pr-2" large @click="takePicture('PHOTOS')"><v-icon class="pl-2 pr-2">mdi-image</v-icon><span class="pr-2">Galerie</span></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded @click="sourcePopup = false" large><v-icon class="pr-2 pl-2">mdi-cancel</v-icon><span class="pr-2">Annuler</span></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import camera from '../../../assets/images/camera.png';
import img_loading from '../../../assets/images/img_loading.gif';

import { mapGetters } from 'vuex'

import ImageServices from '@/services/imageServices.js';

import { Camera, CameraResultType } from '@capacitor/camera';

import PhotoEdit from './PhotoEdit.vue';


export default {
  name: "Photo",
  components: { PhotoEdit },
  props: {
    description: {
      type: String,
      default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent convallis ut leo ac placerat. Fusce sodales vitae quam id tempus. Donec dictum tortor in urna fringilla fermentum."
    },
    folder: {
      type: Object,
      default: () => ({
        kIDFolder: null,
        kIDAssemblyCenter: null
      })
    },
    index: {
      type: Number,
      default: -1
    },
    lastUpdate: {
      type: Date,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
      mediaRecords: 'folderMedia/getRecords'
    })
  },
  watch: {
    lastUpdate: function() {
      this.loadServer();
    },
    src: function(newP) {
      this.$emit('onChange', { index: this.index, ok: newP == null ? false : true  })
    }
  },
  data: function () {
    return {
      infoPopup: false,
      fullscreenPopup: false,
      editPopup: false,
      commentaryPopup: false,
      camera: camera,
      img_loading: img_loading,
      src: null,
      editor_instance: null,
      kIDFolderMedia: null,
      sFolderMediaCommentary: "",
      loading: false,
      sourcePopup: false
    };
  },
  methods: {
    onClickPicture: function() {
      this.sourcePopup = true;
    },
    onClickBigPicture: function() {
      if (this.src == null) {
        this.onClickPicture();
      }
      else {
        this.fullscreenPopup = true;
      }
    },
    takePicture: async function(source) {
      const me = this;
      
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: source
      });

      this.sourcePopup = false;
      
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      var imageUrl = `data:image/${image.format};base64,${image.base64String}`;

      // Can be set to the src of an image now
      me.src = imageUrl;

      this.save();
    },
    save: function() {
      const payload = {
        kIDFolderMedia: this.kIDFolderMedia,
        kIDUser: this.getUser.kIDUser,
        kIDFolder: this.folder.kIDFolder,
        kIDAssemblyCenter: this.folder.kIDAssemblyCenter,
        iFolderMediaType: 0,
        vFolderMediaFilePath: this.src,
        iFolderMediaPosition: this.index,
        sFolderMediaCommentary: this.sFolderMediaCommentary == null ? "" : this.sFolderMediaCommentary
      }

      this.$store.commit('app/mask');
      this.$store.dispatch('folderMedia/save', payload).then((result) => {
        this.kIDFolderMedia = result.response.T0115FolderMedia.Item.kIDFolderMedia;
        this.$emit('onSaved');
        this.$store.commit('app/unmask');
      }).catch(() => {
        this.$store.commit('app/unmask');
      })
    },
    loadServer: function() {
      this.src = null;
      this.kIDFolderMedia = null;
      this.sFolderMediaCommentary = "";

      if (this.mediaRecords) {
        const media = this.mediaRecords.find(el => el.iFolderMediaPosition == this.index);

        if(media) {
          const src = media.vFolderMediaFilePath;

          this.sFolderMediaCommentary = media.sFolderMediaCommentary;
          this.kIDFolderMedia = media.kIDFolderMedia;
          
          this.loading = true;
          
          this.$emit('onChange', { index: this.index, ok: src == null ? false : true  })
          ImageServices.getImage(src).then((response) => {
            // Nous avons la b64 de l'image, il faut rajouter le header puis creer l'obj blob et ajouter l'image dans le cmp
            this.src = "data:image/png;base64," + response;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        }
      }
    },
    edit: function() {
        this.editPopup = true;
    }
  },
  mounted: function() {
    this.loadServer();
  }
}
</script>