<template>
  <v-card class="mx-auto">
    <div @click="onClickBigPicture" class="center-x" style="background-color: grey; ">
      <img ref="imgRef" :src="src != null ? src : loading ? img_loading : camera" :class="src != null ? '' : 'pa-8'" style="max-height: 25vh; max-width: 600px;" />
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn v-if="src != null && !disabled" icon @click="edit">
        <v-icon>mdi-image-edit</v-icon>
      </v-btn>

      <v-btn v-if="src != null" icon @click="fullscreenPopup = true">
        <v-icon>mdi-arrow-expand</v-icon>
      </v-btn>

      <v-btn v-if="!disabled" :disabled="loading" icon @click="onClickPicture">
        <v-icon>mdi-camera</v-icon>
      </v-btn>
    </v-card-actions>
    <!-- FULL SCREEN -->
    <v-dialog
      v-model="fullscreenPopup"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card class="center-xy" style="background-color: grey;" >
        <div style="position: fixed; top: 15px; left: 15px;">
          <v-btn
            light
            @click="fullscreenPopup = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <img :src="src != null ? src : camera" style="max-width: 100vw; max-height: 100vh;" />
      </v-card>
    </v-dialog>
    <!-- EDIT -->
    <PhotoEdit :editPopup="editPopup" :src="src" @srcChange="(newSrc) => { src = newSrc }" @close="editPopup = false"></PhotoEdit><!-- SOURCE -->
    <v-dialog
      v-model="sourcePopup"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <template>
        <v-card>
          <div>
            <p class="text-h5 text-center pt-6">Veuillez préciser la source de votre photo</p>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="info" class="pr-2" large @click="takePicture('CAMERA')"><v-icon class="pl-2 pr-2">mdi-camera</v-icon><span class="pr-2">Caméra</span></v-btn>
            <v-btn rounded color="info" class="pr-2" large @click="takePicture('PHOTOS')"><v-icon class="pl-2 pr-2">mdi-image</v-icon><span class="pr-2">Galerie</span></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded @click="sourcePopup = false" large><v-icon class="pr-2 pl-2">mdi-cancel</v-icon><span class="pr-2">Annuler</span></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import camera from '../../../assets/images/camera.png';
import img_loading from '../../../assets/images/img_loading.gif';

import { Camera, CameraResultType } from '@capacitor/camera';

import PhotoEdit from './PhotoEdit.vue';

import ImageServices from '@/services/imageServices.js';

export default {
  name: "EventPhoto",
  components: { PhotoEdit },
  watch: {
    src: function(newP) {
      this.$emit('onChange', { src: newP })
    },
    update: function(n) {
      console.log(n);
      this.loadServer();
    }
  },
  props: {
    update: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectedEventData: {
      type: Object,
      default: () => ({
        kIDEventID: null
      })
    }
  },
  data: function () {
    return {
      fullscreenPopup: false,
      editPopup: false,
      camera: camera,
      img_loading: img_loading,
      src: null,
      editor_instance: null,
      loading: false,
      sourcePopup: false
    };
  },
  methods: {
    onClickPicture: function() {
      this.sourcePopup = true;
    },
    onClickBigPicture: function() {
      if (this.src == null) {
        this.onClickPicture();
      }
      else {
        this.fullscreenPopup = true;
      }
    },
    takePicture: async function(source) {
      const me = this;
      
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: source
      });

      this.sourcePopup = false;
      
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      var imageUrl = `data:image/${image.format};base64,${image.base64String}`;

      // Can be set to the src of an image now
      me.src = imageUrl;
    },
    loadServer: function() {
      if (this.selectedEventData && this.selectedEventData.vEventFixPicturePath && this.selectedEventData.vEventFixPicturePath != null) {
        ImageServices.getImage(this.selectedEventData.vEventFixPicturePath).then((response) => {
          // Nous avons la b64 de l'image, il faut rajouter le header puis creer l'obj blob et ajouter l'image dans le cmp
          this.src = "data:image/png;base64," + response;
        })
        .catch((error) => {
          console.log(error);
        });
      }
      else {
        this.src = null;
      }
    },
    edit: function() {
      this.editPopup = true;
    }
  },
  mounted: function() {
    this.loadServer();
  }
}
</script>