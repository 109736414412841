<template>
    <i :class="`fa ${size} ${icon}`" :style="`color: ${color};`" aria-hidden="true"></i>
</template>

<script>
export default {
  name: 'Fa',

  props: {
    icon: {
        type: String,
        default: ""
    },
    color: {
        type: String,
        default: "black"
    },
    size: {
      type: String,
      default: ""
    }
  },

  data: function() {
    return {
        
    }
  },

  methods: {
    
  }
}
</script>
<style>

</style>