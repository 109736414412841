import Vue from 'vue'
import VueI18n from 'vue-i18n';
import messages from '@/lang';

Vue.use(VueI18n);

// Définition des langues disponibles
const _availableLocales = ['en_US', 'fr_FR'];

// Détection de la langue
let locale = localStorage.getItem('lang');
locale = locale != null ? locale : navigator.language.replace('-', '_');

localStorage.setItem('lang', locale);

// Avec un simple navigator.language, un belge ou un canadien sera redirigé vers le fallback car fr_BE ou fr_CA
// De même pour un non-castillan, il sera redirigé vers le fallback au lieu de l'es_ES car es_MX ou es_AR ou es_CO
// Il faut une nouvelle notion de fallback pour redirigé vers le plus proche parent
if (!_availableLocales.includes(locale)) {
  let prefix = locale.slice(0, 2);
  switch (prefix) {
    case "fr":
      locale = "fr_FR";
      break;

    case "en":
      locale = "en_US";
      break;
  
    default:
      locale = "en_US";
      break;
  }
}

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'fr_FR',
  messages
});

i18n._availableLocales = _availableLocales;

export default i18n
