import LockerServices from '@/services/lockersServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }
}

// actions
const actions = {
  loadFromWS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, page, start, limit } = payload;

        commit('download/increaseCounter', null, { root: true });
        LockerServices.getList(
          //kIDUser,
          kIDUser,
          //xmlFiltersSorts,
          "",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          commit('setRecords', response.Item);
          dispatch("setRelationship");

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  setProject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDSupply, sProjectOrder, sProjectLine, sProjectPreviousOrder, sProjectPreviousLine } = payload;
        
        const now = new Date();

        commit('download/increaseCounter', null, { root: true });
        LockerServices.setProject(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDSupply",
          //isBckff 
          0,
          //kIDComponent
          "011525",
          //viewName
          `wft_T0115Locker(${kIDUser}, '')`,
          //xmlData
          `<xmlst>`
          + `<T0115Locker>`
            + `<Item>`
              + `<kIDSupply>${kIDSupply}</kIDSupply>`
              + `<kIDWFStatus>01150610</kIDWFStatus>`
              + `<kIDWFStatusFrom>0</kIDWFStatusFrom>`
              + `<kIDComponent>011525</kIDComponent>`
              + `<dLastSubstitutionDate>${now.getDate()}/${now.getMonth()+1}/${now.getYear()+1900} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}</dLastSubstitutionDate>`
              + `<sProjectOrder>${sProjectOrder}</sProjectOrder>`
              + `<sProjectLine>${sProjectLine}</sProjectLine>`
              + `<sProjectPreviousOrder>${sProjectPreviousOrder}</sProjectPreviousOrder>`
              + `<sProjectPreviousLine>${sProjectPreviousLine}</sProjectPreviousLine>`
            + `</Item>`
          + `</T0115Locker>`
        + `</xmlst>`
        ).then((response) => {
          // La data est upload, il faut faire la modification en local
          commit('setRecord', response.Item);
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  removeProject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDSupply, sProjectOrder, sProjectLine, sProjectPreviousOrder, sProjectPreviousLine } = payload;
        
        const now = new Date();

        commit('download/increaseCounter', null, { root: true });
        LockerServices.removeProject(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDSupply",
          //isBckff 
          0,
          //kIDComponent
          "011526",
          //viewName
          `wft_T0115Locker(${kIDUser}, '')`,
          //xmlData
          `<xmlst>`
          + `<T0115Locker>`
            + `<Item>`
              + `<kIDSupply>${kIDSupply}</kIDSupply>`
              + `<kIDWFStatus>01150620</kIDWFStatus>`
              + `<kIDWFStatusFrom>01150610</kIDWFStatusFrom>`
              + `<kIDComponent>011526</kIDComponent>`
              + `<dLastSubstitutionDate>${now.getDate()}/${now.getMonth()+1}/${now.getYear()+1900} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}</dLastSubstitutionDate>`
              + `<sProjectOrder>${sProjectOrder}</sProjectOrder>`
              + `<sProjectLine>${sProjectLine}</sProjectLine>`
              + `<sProjectPreviousOrder>${sProjectPreviousOrder}</sProjectPreviousOrder>`
              + `<sProjectPreviousLine>${sProjectPreviousLine}</sProjectPreviousLine>`
            + `</Item>`
          + `</T0115Locker>`
        + `</xmlst>`
        ).then((response) => {
          // La data est upload, il faut faire la modification en local
          commit('setRecord', response.Item);
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  setLockerLayout({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDSupply, kIDLayout } = payload;
        
        commit('download/increaseCounter', null, { root: true });
        LockerServices.setLayout(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDSupply",
          //isBckff 
          0,
          //xmlData,
          `<xmlst><T0115Locker><Item><kIDSupply>${kIDSupply}</kIDSupply><kIDLayout>${kIDLayout}</kIDLayout></Item></T0115Locker></xmlst>`,
        ).then((response) => {
          // La data est upload, il faut faire la modification en local
          commit('setRecord', response.Item);
          dispatch("setRelationship");
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  setRelationship({ state, rootGetters }, from) {          
    state.records?.forEach(data => {
      /**
       * Nous devons enrichir les data suivantes :
       * kIDBrand
       * kIDModel
       */
      const enrichData = (primaryKey, storePath, searchFn) => {
        // Si la data existe
        if (data[primaryKey]) {
          // Recherche dans son store
          const richData = rootGetters[storePath].find(
            searchFn ? searchFn :
            el => el[primaryKey] == data[primaryKey]
          );

          // Si elle existe, il faut enrichir la data
          data[primaryKey] = richData ? richData : data[primaryKey];
        }
      }
      
      
      if (from == null) {
        enrichData("kIDBrand", "brands/getRecords");
        enrichData("kIDModel", "model/getRecords");
        enrichData("kIDContractor", "thirdParties/getRecords", el => el.kIDAnybody == data.kIDContractor);
      }
      else {
        switch (from) {
          case "kIDBrand":
            enrichData("kIDBrand", "brands/getRecords");
            break;
          case "kIDModel":
            enrichData("kIDModel", "model/getRecords");
            break;
        
          default:
            break;
        }
      }
    });
  },
}

// mutations
const mutations = {
  setRecords(state, records) {
    state.records = Array.isArray(records) ? records : typeof records == 'object' ? [records] : [];
  },

  setRecord(state, record) {
    const exist = state.records.find(el => el.kIDSupply == record.kIDSupply);

    if (exist) {
      exist.sProjectOrder = record.sProjectOrder;
      exist.sProjectLine = record.sProjectLine;
      exist.sProjectPreviousOrder = record.sProjectPreviousOrder;
      exist.sProjectPreviousLine = record.sProjectPreviousLine;
      exist.dLastSubstitutionDate = record.dLastSubstitutionDate;
      exist.kIDLayout = record.kIDLayout;
      exist.kIDWFStatus = record.kIDWFStatus;
      exist.kIDSupplyStatus = record.kIDSupplyStatus;

      if (record.kIDEventResolutioner) {
        exist.kIDEventResolutioner = record.kIDEventResolutioner;
      }

      if (record.dEventResolutionDate) {
        exist.dEventResolutionDate = record.dEventResolutionDate;
      }

      if (record.sEventResolutionerTitle) {
        exist.sEventResolutionerTitle = record.sEventResolutionerTitle;
      }
    }
  },

  reset(state) {
    state.records = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}