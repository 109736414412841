<template>
  <v-dialog
    v-model="show"
    max-width="600px"
    scrollable
    persistent
  >
    <v-card>
      <v-toolbar
        dark
        color="red"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div style="overflow: auto;">
        <v-card-text>
          {{ getTitle() }}
          <v-autocomplete
            v-model="selectedLayoutParent"
            :disabled="loading"
            :items="getLayoutParent"
            item-value="kIDLayoutParent"
            item-text="sLayoutParentTitle"
            label="Zone"
            clearable
          ></v-autocomplete>
          <v-autocomplete
            v-model="selectedLayout"
            :disabled="selectedLayoutParent == null || loading"
            :items="getLayoutRecords"
            item-value="kIDLayout"
            label="Emplacement"
            clearable
          >
            <template v-slot:selection="data">
              {{ `${data.item.sLayoutCode} - ${data.item.sLayoutTitle}` }}
            </template>
            <template v-slot:item="data">
              {{ `${data.item.sLayoutCode} - ${data.item.sLayoutTitle}` }}
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="error"
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
            Annuler
          </v-btn>
          <v-btn
            :loading="loading2"
            :disabled="getItemLayout == '-1'"
            color="orange"
            :dark="getItemLayout != '-1'"
            @click="remove"
          >
            <v-icon>mdi-delete</v-icon>
            Retirer de l'empacement
          </v-btn>
          <v-btn
            :disabled="selectedLayout == null"
            :loading="loading"
            color="success"
            @click="assign"
          >
            <v-icon>mdi-check</v-icon>
            Valider
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LayoutPopup',

  props: {
    title: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default: () => ({
        kIDChassis: null,
        kIDLayout: '-1'
      })
    }
  },

  watch: {
    item: {
      handler(newValue) {
        if (newValue && newValue.kIDLayout != null) {
          const layout = this.layout.filter(el => el.kIDLayout == newValue.kIDLayout);
          
          if (layout.length > 0) {
            this.selectedLayout = layout[0].kIDLayout;

            const layoutParent = this.layoutParent.filter(el => el.kIDLayoutParent == layout[0].kIDLayoutParent);
            if (layoutParent.length > 0) {
              this.selectedLayoutParent = layoutParent[0].kIDLayoutParent;
            }
          }
        }
      },
      deep: true
    }
  },
  
  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
      layoutParent: 'layoutParent/getRecords',
      layout: 'layout/getRecords'
    }),
    getLayoutParent() {
      if (this.layoutParent) {
        const nosideeffects = [...this.layoutParent];
        return nosideeffects.sort((a, b) => a.sLayoutParentTitle.localeCompare(b.sLayoutParentTitle));
      }

      return [];
    },
    getLayoutRecords() {
      if (this.selectedLayoutParent != null) {
        return this.layout
          .filter(el => el.kIDLayoutParent == this.selectedLayoutParent && (this.item.kIDLayout == '-1' ? el.iIsAvailable == "1" : true))
          .sort((a, b) => a.sLayoutCode.localeCompare(b.sLayoutCode));
      }

      if (this.layout) {
        return this.layout
        .filter(el => el.iIsAvailable == "1")
        .sort((a, b) => a.sLayoutCode.localeCompare(b.sLayoutCode));
      }

      return [];
    },
    getItemLayout() {
      if (this.item) {
        return this.item.kIDLayout;
      }

      return '-1';
    }
  },

  data: function() {
    return {
      loading: false,
      loading2: false,
      selectedLayoutParent: null,
      selectedLayout: null
    }
  },

  methods: {
    getTitle() {
      if (this.item) {
        if (this.item.kIDBrand && this.item.kIDModel) {
          return `${this.item.kIDBrand.sBrandTitle} ${this.item.kIDModel.sModelTitle} ${this.item.sChassisNumber}`;
        }
      }

      return '';
    },
    assign() {
      this.loading = true;
      
      this.$store.dispatch("chassis/setChassisLayout", {
        kIDUser: this.getUser.kIDUser, kIDChassis: this.item.kIDChassis, kIDLayout: this.selectedLayout
      }).then(() => {
        this.$store.commit("layout/setLayoutAvailable", { kIDLayout: this.selectedLayout, iIsAvailable: "0"});
        this.$store.commit('app/setMessage', {
          title: "Emplacement du châssis",
          message: "La modification de l'empacement du châssis est prise en compte."
        });

        this.close();
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.loading = false;
      });
    },
    remove() {
      this.loading2 = true;
      
      const kIDLayout = this.item.kIDLayout;
      this.$store.dispatch("chassis/setChassisLayout", {
        kIDUser: this.getUser.kIDUser, kIDChassis: this.item.kIDChassis, kIDLayout: ''
      }).then(() => {
        this.$store.commit("layout/setLayoutAvailable", { kIDLayout: kIDLayout, iIsAvailable: "1"});
        this.$store.commit('app/setMessage', {
          title: "Emplacement du châssis",
          message: "La suppression de l'empacement du châssis est prise en compte."
        });

        this.close();
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.loading2 = false;
      });
    },
    close() {
      this.loading = false;
      this.loading2 = false;
      this.selectedLayoutParent = null;
      this.selectedLayout = null;
      this.$emit('close')
    }
  }
}
</script>
<style>

</style>