<template>
  <div class="h-100">
    <v-row class="h-100 ma-0">
      <v-col v-if="!filtered" class="h-100" cols="3" xl="3" lg="3" md="3" sm="3" xs="3">
        <v-card class="h-100 pa-4" >
          <v-autocomplete
                v-model="selected_document_type"
                class="mt-2"
                :items="document_type"
                prepend-inner-icon="mdi-file-certificate"
                label="Type"
                clearable
                hide-details
                dense
                outlined
          ></v-autocomplete>
          <v-autocomplete
                v-model="selected_documents_categories"
                multiple
                class="mt-2"
                :items="documents_categories"
                prepend-inner-icon="mdi-file-sign"
                label="Categorie"
                clearable
                hide-details
                dense
                outlined
          ></v-autocomplete>
        </v-card>
      </v-col>
      <v-col :cols="filtered ? '12' : '9'">
          <v-row style="overflow: scroll;" :style="`height: ${filtered ? '93vh' : '83vh'};`">
            <v-col v-for="item in filteredData" :key="item.index" cols="4">
              <v-card
                class="d-flex center-xy h-100"
                style="overflow: hidden; text-align: center; min-height: 150px;"
                @click="openDoc(item)"
              >
                <div style="width: 100%;">
                  <div><v-icon color="red" size="40px">mdi-file-pdf-box</v-icon></div>
                  <span class="px-1" style="line-height: normal;">{{ item.document_nom }}</span>
                  <br/><span class="text-caption"><i>{{ item.document_type }}</i></span>
                  <div class="mt-1">
                    <v-chip v-for="cat in item.documents_categories" :key="cat" :class="item.documents_categories.length > 1 ? 'ma-1' : ''" small color="primary">{{ cat }}</v-chip>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="open" fullscreen>
      <div style="background-color: white; border-radius: 0; margin: 0; height: 100%; position: fixed; overflow-y: auto; top: 0; left: 0;">
        <div style="position: fixed; top: 15px; left: 15px; z-index: 1;">
          <v-btn
            rounded
            @click="pdfdata = undefined; open = false; src = null;"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <Truck v-if="loading"></Truck>
        <div :key="lastChange" id="pdfvuer">
          <pdf :src="pdfdata" v-for="i in numPages" :key="i" :id="i" :page="i"
            :scale.sync="scale" :annotation="false" :resize="false" @link-clicked="handle_pdf_link" @loading="pdfLoading" @error="pdfOnError">
          </pdf>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import http from "@/http-common-doc";
import pdfvuer from 'pdfvuer'
import Truck from '../../components/App/Truck.vue';

import env from '@/config/env';

export default {
  name: 'Documentation',

  components: { pdf: pdfvuer, Truck },

  props: {
    filtered: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    }
  },

  data: function() {
    return {
      document_type: [],
      selected_document_type: null,
      documents_categories: [],
      selected_documents_categories: null,
      data: [],
      open: false,
      src: null,
      name: null,
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 'page-width',
      loading: false,
      lastChange: null,
      error: false
    }
  },

  computed: {
    formattedZoom () {
        return Number.parseInt(this.scale * 100);
    },
    filteredData () {
      if ((this.selected_document_type == null || this.selected_document_type.length == 0) && (this.selected_documents_categories == null || this.selected_documents_categories.length == 0)) {
        return this.data;
      }
      
      let filtered = [];

      if (this.selected_documents_categories) {
        this.selected_documents_categories.forEach(categorie => {
          const temp = this.data.filter(el => { return el.documents_categories.includes(categorie) })
          temp.forEach(element => {
            if (typeof filtered.find(el => el.index == element.index) == 'undefined') {
              filtered.push(element);
            }
          });
        })
      }
      
      if (this.selected_document_type) {
        if ((this.selected_documents_categories == null || this.selected_documents_categories.length == 0)) {
          filtered = this.data;
        }
        
        filtered = filtered.filter(el => { return el.document_type == this.selected_document_type })
      }

      return filtered;
    }
  },

  watch: {
    error: function(n) {
      if (n) {
        this.$store.commit("app/setError")
      }
    },
    show: function (s) {
      if(s) {
        this.getPdf();
      }
    },
    page: function (p) {
      if( window.pageYOffset <= this.findPos(document.getElementById(p)) || ( document.getElementById(p+1) && window.pageYOffset >= this.findPos(document.getElementById(p+1)) )) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    },
    categories: function() {
      this.getData()
    }
  },
  
  mounted: function() {
    this.getData();
  },

  methods: {
    getFilter: function() {
      let cat = '';
      this.categories.forEach(el => {
        cat += (cat == '' ? '' : ',') + '"' + el + '"';
      })

      let types = '';
      this.types.forEach(el => {
        types += (types == '' ? '' : ',') + '"' + el + '"';
      })
      return `{"type":[${types}],"categories":[${cat}]}`;
    },
    getData: function() {
      this.$store.commit('app/mask', "Chargement des données..."); 
      http.get("getDediDocList?sJsonTypesCategories="+this.getFilter()).then(response => {
        if (response.data && response.data.documents) {
          this.data = response.data.documents;

          let i = 0;
          this.data = this.data.map(item => {
            i = i + 1;
            
            if (!this.document_type.includes(item.document_type)) {
              this.document_type.push(item.document_type)
            }

            item.documents_categories.forEach(categorie => {
              if (!this.documents_categories.includes(categorie)) {
                this.documents_categories.push(categorie)
              }
            })

            return ({
              ...item,
              index: i
            })
          });
        }
      }).finally(() => {
          this.$store.commit('app/unmask'); 
      })
    },
    openDoc: function(doc) {
      this.loading = true;
      this.error = false;
      this.open = true;
      this.src = env.config.api_url + "getDediDoc?pdfURL=" + doc.document_link;
      this.name = doc.document_nom;
      this.lastChange = new Date().getTime();
      this.getPdf(this.src);
    },
    pdfLoading: function(state) {
      this.loading = state;
    },
    pdfOnError: function() {
      this.loading = false;
      this.open = false;
      this.error = true;
    },
    handle_pdf_link: function (params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    getPdf (url) {
      var self = this;
      self.pdfdata = pdfvuer.createLoadingTask({
        mode: 'cors',
        url
      });
      self.pdfdata.then(pdf => {
        self.numPages = pdf.numPages;
        window.onscroll = function() { 
          changePage() 
        }

        function changePage () {
          var i = 1, count = Number(pdf.numPages);
          do {
            if(window.pageYOffset >= self.findPos(document.getElementById(i)) && 
                window.pageYOffset <= self.findPos(document.getElementById(i+1))) {
              self.page = i
            }
            i++
          } while ( i < count)
          if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
            self.page = i
          }
        }
      });
    },
    findPos(obj) {
      return obj.offsetTop;
    }
  }
}
</script>
<style>
  .page {
    margin: auto;
  }
</style>