<template>
  <v-dialog
    v-model="show"
    scrollable
    persistent
  >
    <v-card>
      <v-toolbar
        dark
        color="red"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div style="overflow: auto;">
        <slot name="content"></slot>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CustomFieldPopup',

  props: {
    title: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      
    }
  },

  methods: {

  }
}
</script>
<style>

</style>