import { EventBus } from '@/event-bus'

export default {
  data: () => ({
    itemsPerPage: 20,
    page: 1,
    filter: {
      search: null
    },
    rowBtnClicked: false
  }),
  methods: {
    getReadabledFloat: function(float) {
      return parseFloat(float);
    },
    
    onRowClicked: function(data, row) {
      if (this.rowBtnClicked) {
        this.rowBtnClicked = false;
        return;
      }
      else {
        if (data) {
          row.expand(!row.isExpanded);
        }
      }
    },

    onSearchValueChanged: function(value) {
      this.filter.search = value;
    },

    onHeaderValueChanged: function(value) {
      const { page } = value;
      this.page = page;
    },

    onPageCountChange: function(event) {
      EventBus.$emit('gridPageCountChanged', event);
    },
  },
  computed: {
    getRecords() {
      if (this.filter.search != null  && this.filter.search != "" ) {
        return this.records.filter((item) => {
          return item.search.toUpperCase().includes(this.filter.search.toUpperCase()) ? true : false;
        })
      }

      return this.records;
    },
    getTableHeight() {
      return window ? (window.innerHeight - 56 - 64) + 'px' : 800 + 'px';
    },
    scdRowMaxHeight() {
      return 160;
    }
  }
}