<template>
  <div>
    <div v-if="field.sFieldPhase || field.sFieldGroup"  class="mb-2 mt-4 text-h5">
      <b v-if="field.sFieldPhase">{{ field.sFieldPhase }} : </b>
      <span v-if="field.sFieldGroup">{{ field.sFieldGroup  }}</span>
    </div>
    <div v-else class="mt-1 mb-1"></div>
    <div class="d-flex">
      <v-col cols="10" class="pa-0">
        <div class="w-100 d-inline-flex" v-if="field.sFieldType == 'T'">
          <v-text-field
            v-model="value"
            class="mb-0 mt-1" 
            dense outlined hide-details 
            :label="field.sFieldQuestion + (field.iFieldDisplayStatus == '1' ? ' *' : '')"
            :rules="field.iFieldDisplayStatus == '1' ? [rules.required] : []"
          >
          </v-text-field>
          <v-switch
            v-if="itIsDefaultValue()"
            v-model="defaultValueValidate"
            hide-details
            class="ml-4 mt-0 center-x"
            color="green"
          ></v-switch>
        </div>
        <div v-if="field.sFieldType == 'C'" class="pl-2 d-flex">
          <div class="pr-2 center-x">{{ field.sFieldQuestion + (field.iFieldDisplayStatus == '1' ? ' *' : '') }}</div>
          <v-spacer></v-spacer>
           <v-btn-toggle
            v-model="value"
            rounded
          >
            <v-btn v-for="response in field.responses" :key="response.name" :value="response.value">
              {{ response.value }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <div v-if="field.sFieldType == 'M'" class="pl-2 d-flex">
          <div class="pr-2 center-x">{{ field.sFieldQuestion + (field.iFieldDisplayStatus == '1' ? ' *' : '') }}</div>
          <v-spacer></v-spacer>
           <v-btn-toggle
            v-model="value"
            multiple
            rounded
          >
            <v-btn v-for="response in field.responses" :key="response.name" :value="response.value">
              <v-checkbox :value="value.includes(response.value)"></v-checkbox>{{ response.value }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <div class="w-100 d-inline-flex" v-if="field.sFieldType == 'F'">
          <v-text-field 
            v-model="value"
            class="mb-0 mt-1"
            dense outlined hide-details
            :label="field.sFieldQuestion + (field.iFieldDisplayStatus == '1' ? ' *' : '')"
            :rules="field.iFieldDisplayStatus == '1' ? [rules.required] : []"
            type="number" step="0.1" :min="getReadabledFloat(field.fFieldMinValue)" :max="getReadabledFloat(field.fFieldMaxValue)"
          >
          </v-text-field>
          <v-switch
            v-if="itIsDefaultValue()"
            v-model="defaultValueValidate"
            hide-details
            class="ml-4 mt-0 center-x"
            color="green"
          ></v-switch>
        </div>
        <div v-if="field.sFieldType == 'S'">
          <div v-if="resetSignature == false && (getGlobalFormFieldContentRecord && getGlobalFormFieldContentRecord[0] && getGlobalFormFieldContentRecord[0].vFieldSignatureFilePath && getGlobalFormFieldContentRecord[0].vFieldSignatureFilePath != 'null')">
            <div class="text-h5" style="font-family: 'Indie Flower' !important;"><i><b>{{ user.sUserFirstName }} {{ user.sUserLastName }}</b></i></div>
            <div style="border: black 3px solid">
              <LstImage height="200px" minHeight="200px" :imgKey="getGlobalFormFieldContentRecord[0].vFieldSignatureFilePath"></LstImage>
            </div>
          </div>
          <div v-else>
            <VueSignaturePad v-if="active" width="100%" height="200px" :customStyle="{ border: 'black 3px solid' }" :ref="`signaturePad_${field.sFieldName}`" />
            <div style="float: left" class="mt-2">
              <v-btn fab small color="red" @click="clearSignature"><v-icon color="white">mdi-pencil-off-outline</v-icon></v-btn>
              <v-btn fab small color="red" class="ml-2" @click="lock"><v-icon color="white">{{ isLocked ? 'mdi-pencil-outline' : 'mdi-pencil-lock-outline'  }}</v-icon></v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="2" class="d-flex flex-row pa-0 center-x">
        <div v-if="field.sFieldType == 'S'" >
          <v-btn fab small color="red" @click="_resetSignature"><v-icon color="white">mdi-trash-can</v-icon></v-btn>
        </div>
        <v-dialog
          transition="dialog-top-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" fab small :color="commentary ? '#ffcd00' : 'white'" dark v-bind="attrs" v-on="on"><v-icon :color="commentary ? 'white' : 'black'">mdi-comment-edit-outline</v-icon></v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar
                dark
                color="red"
              >
                <v-btn
                  icon
                  dark
                  @click="dialog.value = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Commentaire</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pt-2">
                <v-textarea v-model="commentary" outlined hide-details></v-textarea>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog
          v-if="field.sFieldHelp" 
          transition="dialog-top-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" fab small :color="field.sFieldHelp ? 'red' : 'white'"  v-bind="attrs" v-on="on"><v-icon :color="field.sFieldHelp ? 'white' : 'black'">mdi-help-circle-outline</v-icon></v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar
                dark
                color="red"
              >
                <v-btn
                  icon
                  dark
                  @click="dialog.value = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Aide</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pt-2" v-html="field.sFieldHelp"></v-card-text>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { FieldDTO } from '../dto/field';

import FloatMixin from '../../../mixins/FloatMixin';

import LstImage from '@/components/LstImage.vue';

export default {
  name: 'Fields',

  mixins: [FloatMixin],

  components: { LstImage },

  props: {
    field: {
      type: Object,
      default: () => (new FieldDTO())
    },
    active: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({
        
      })
    },
    kIDGlobalFormContent: {
      type: String,
      default: null
    },
    reset: {
      default: null
    }
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
      getGlobalFormFieldContentRecords: 'globalFormFieldContent/getRecords'
    }),
    getGlobalFormFieldContentRecord() {
      return this.getGlobalFormFieldContentRecords.filter(el => (el.kIDGlobalFormContent == this.kIDGlobalFormContent && el.kIDGlobalFormField == this.field.kIDGlobalFormField))
    }
  },

  watch: {
    data: function() {
      this.value = typeof this.data[this.field.sFieldDefaultVariable] != 'undefined' ? this.data[this.field.sFieldDefaultVariable] : this.field.sFieldDefaultValue ? this.field.sFieldType == "F" ? parseFloat(this.field.sFieldDefaultValue) : this.field.sFieldDefaultValue : null
    },
    getGlobalFormFieldContentRecord: function() {
      this.setData();
    },
    reset: function() {
      this.resetSignature = false;
      this.isDefaultValue = true;
    }
  },

  data: function() {
    return {
      value: this.getValue(),
      isDefaultValue: true,
      defaultValueValidate: false,
      commentary: null,
      isLocked: false,
      rules: {
        required: value => !!value || false,
      },
      resetSignature: false
    }
  },
  
  methods: {
    itIsDefaultValue() {
      if (this.isDefaultValue) {
        if (typeof this.data[this.field.sFieldDefaultVariable] != 'undefined') {
          return true;
        }
        else if (this.field.sFieldDefaultValue) {
          if (this.field.sFieldType == "F") {
            return true;
          }
          else {
            return true;
          }
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    },
    getValue() {
      if (typeof this.data[this.field.sFieldDefaultVariable] != 'undefined') {
        if (this.field.sFieldType == "M") {
          this.data[this.field.sFieldDefaultVariable].split('|');
        }
        return this.data[this.field.sFieldDefaultVariable];
      }
      else {
        if (this.field.sFieldDefaultValue) {
          if (this.field.sFieldType == "F") {
            return parseFloat(this.field.sFieldDefaultValue);
          }
          else if (this.field.sFieldType == "M") {
            return this.field.sFieldDefaultValue.split('|');
          }
          else {
            return this.field.sFieldDefaultValue
          }
        }
        else {
          if (this.field.sFieldType == "M") {
            return []
          }
          else {
            return null
          }
        }
      }
    },
    clearSignature() {
      this.$refs[`signaturePad_${this.field.sFieldName}`].clearSignature();
    },
    _resetSignature() {
      this.resetSignature = true;
    },
    lock() {
      this.isLocked = !this.isLocked;

      this.isLocked ? this.$refs[`signaturePad_${this.field.sFieldName}`].lockSignaturePad() : this.$refs[`signaturePad_${this.field.sFieldName}`].openSignaturePad();
    },
    validateC(value) {
      if (value == null) {
        return false;
      }

      return true;
    },
    validateM(value) {
      if (value == null) {
        return false;
      }

      if (value != null && value.length <= 0) {
        return false;
      }

      return true;
    },
    setData() {
      if(this.getGlobalFormFieldContentRecord.length > 0) {
        if (this.field.sFieldType == "M") {
          this.value = this.getGlobalFormFieldContentRecord[this.getGlobalFormFieldContentRecord.length-1].sFieldResponseContent.split('|');
        }
        else {
          this.value = this.getGlobalFormFieldContentRecord[this.getGlobalFormFieldContentRecord.length-1].sFieldResponseContent;
        }

        this.commentary = this.getGlobalFormFieldContentRecord[this.getGlobalFormFieldContentRecord.length-1].sFieldCommentary
        this.isDefaultValue = false;
      }
    }
  },

  mounted() {
    this.setData();
  }
}
</script>