<template>
  <div class="h-100">
    <v-row class="h-100 ma-0">
      <v-col class="h-100" cols="3" style="overflow:auto">
        <v-card class="d-flex flex-column h-100" >
        <v-data-iterator
          style="flex:20"
          :items="getRecords"
          :items-per-page="itemsPerPage"
          item-key="kIDLayoutParent"
          hide-default-footer
        >
          <template v-slot:default="{items}">
            <v-list subheader>
              <v-list-item-group v-model="selectedLayoutParent">
                <v-list-item
                  v-for="item in items"
                  :key="item.kIDLayoutParent"
                  @click="onClickLayoutParent(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-map-marker
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.sLayoutParentTitle }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>
        </v-data-iterator>
      </v-card>
      </v-col>
      <v-col class="h-100" cols="6">
        <v-item-group>
          <v-container>
            <v-row style="max-height: 85vh; overflow: auto;">
                <v-col
                  v-for="item in getLayoutRecords" :key="item.kIDLayout"
                  cols="4"
                >
                  <v-item class="h-100">
                    <v-card
                      :color="item.iIsAvailable == '1' ? 'green' : 'red'"
                      :class="isActive(item) ? 'elevation-10' : ''"
                      class="d-flex flex-column"
                      dark
                      @click="onClickLayout(item)"
                    >
                      <v-card-title class="center-x">{{ item.sLayoutTitle }}</v-card-title>
                      <v-card-subtitle v-if="(item.kIDLayoutTipper == '-1' && item.kIDLayoutLocker == '-1')" class="center-x pb-0" v-html="item.iIsAvailable == '1' ? 'Disponible' : getTitleData(item)"></v-card-subtitle>
                      <v-card-subtitle v-if="(item.kIDLayoutTipper != '-1')" class="center-x pb-0" v-html="`<div class='text-center'>Benne<br> ${item.sElementDimensions}`"></v-card-subtitle>
                      <v-card-subtitle v-if="(item.kIDLayoutLocker != '-1')" class="center-x pb-0" v-html="`<div class='text-center'>Coffre<br> ${item.sElementDimensions}`"></v-card-subtitle>
                      <v-spacer></v-spacer>
                      <Fa class="center-x py-2" :icon="item.sElementGlyph" color="white" size="fa-2x" />
                      <v-spacer></v-spacer>
                      <v-card-title class="center-x pt-0">{{ item.sLayoutCode }}</v-card-title>
                    </v-card>
                  </v-item>
                </v-col>
            </v-row>
          </v-container>
          </v-item-group>
      </v-col>
      <v-col class="h-100" cols="3">
        <v-card class="d-flex flex-column h-100">
          <v-card-title v-if="selectedLayoutData">{{ `${selectedLayoutData.sLayoutTitle} - ${selectedLayoutData.sLayoutCode}` }}</v-card-title>
          <v-card-subtitle v-else>Selectionnez un emplacement</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text v-if="getChassisRecord" style="overflow: auto;">
            <v-text-field
              :value="getChassisRecord.kIDBrand.sBrandTitle"
              label="Marque"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-text-field
              :value="getChassisRecord.kIDModel.sModelTitle"
              label="Modèle"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-text-field
              :value="getChassisRecord.sChassisColor"
              label="Couleur"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-text-field
              :value="getChassisRecord.sChassisNumber"
              label="Numéro de châssis"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-text-field
              :value="getChassisRecord.sChassisNumberOrder"
              label="Numéro de commande client"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-text-field
              :value="new Date(getChassisRecord.dChassisReceptionDate).toLocaleDateString()"
              label="Date de réception"
              outlined
              readonly
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-text v-else-if="selectedLayoutData" style="overflox: auto;">
            <v-text-field
              :value="selectedLayoutData.sElementTitle"
              label="Titre"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-text-field
              :value="selectedLayoutData.sElementType"
              label="Type"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-text-field
              :value="selectedLayoutData.sElementDimensions"
              label="Dimensions"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-text-field
              :value="selectedLayoutData.sSerialNumber"
              label="Numéro de série"
              outlined
              readonly
              dense
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

import Fa from "@/components/Fa";

export default {
  name: 'Layout',

  components: { Fa },

  data: function() {
    return {
      itemsPerPage: 999,
      search: null,
      selectedLayoutParent: null,
      selectedLayoutParentData: null,
      selectedLayout: null,
      selectedLayoutData: null
    }
  },

  watch: {
    records: function() {
      this.selectFirst();
    }
  },
  
  computed: {
    ...mapGetters({
      records: 'layoutParent/getRecords',
      layoutsRecords: 'layout/getRecords',
      chassisRecords: 'chassis/getRecords'
    }),
    getRecords() {
      if (this.search != null) {
        // La recherche se base sur le champs search d'un chassis
        const chassis = this.chassisRecords.filter(el => el.search.toUpperCase().includes(this.search.toUpperCase()));
        
        if (chassis) {
          // Récupération des layouts des chassis
          const layouts = [];
          chassis.forEach(chassisEl => {
            if (chassisEl.kIDLayout != "-1") {
              layouts.push(chassisEl.kIDLayout);
            }
          });

          const layoutsParent = []
          this.layoutsRecords.forEach(layout => {
            if (layouts.includes(layout.kIDLayout)) {
              layoutsParent.push(layout.kIDLayoutParent);
            }
          });

          // Récupération des layoutParent des layout des chassis
          return this.records.filter(el => layoutsParent.includes(el.kIDLayoutParent)).sort((a, b) => a.sLayoutParentTitle.localeCompare(b.sLayoutParentTitle));
        }

        return [];
      }
      else {
        const buffer = [...this.records];
        return buffer.sort((a, b) => a.sLayoutParentTitle.localeCompare(b.sLayoutParentTitle));
      }
    },
    getLayoutRecords() {
      if (this.selectedLayoutParent != null) {
        if (this.search != null) {
          // La recherche se base sur le champs search d'un chassis
          const chassis = this.chassisRecords.filter(el => el.search.toUpperCase().includes(this.search.toUpperCase()));
          
          if (chassis) {
            // Récupération des layouts des chassis
            const layouts = [];
            chassis.forEach(chassisEl => {
              if (chassisEl.kIDLayout != "-1") {
                layouts.push(chassisEl.kIDLayout);
              }
            });

            // Récupération des layoutParent des layout des chassis
            return this.layoutsRecords.filter(el => el.kIDLayoutParent == this.selectedLayoutParentData.kIDLayoutParent && layouts.includes(el.kIDLayout))
              .sort((a, b) => this.getLayoutTitle(a).localeCompare(this.getLayoutTitle(b)));
          }

          return [];
        }
        else {
          return this.layoutsRecords.filter(el => el.kIDLayoutParent == this.selectedLayoutParentData.kIDLayoutParent)
            .sort((a, b) => this.getLayoutTitle(a).localeCompare(this.getLayoutTitle(b)));
        }
      }
      
      return [];
    },
    getChassisRecord() {
      if (this.selectedLayoutData != null) {
        return this.chassisRecords.find(el => el.kIDLayout == this.selectedLayoutData.kIDLayout);
      }

      return null;
    }
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
    this.selectFirst();
  },

  methods: {
    onSearchValueChanged: function(value) {
      this.search = value;
    },

    onClickLayoutParent: function(layoutParent) {
      this.selectedLayoutParentData = layoutParent;
      this.selectedLayoutData = null;
    },

    onClickLayout: function(layout) {
      this.selectedLayoutData = layout;
    },

    isActive: function(layout) {
      if (this.selectedLayoutData && layout) {
        return this.selectedLayoutData.kIDLayout == layout.kIDLayout;
      }

      return false;
    },

    getTitleData: function(data) {
      // Recherche d'un chassis
      const chassis = this.chassisRecords.find(el => el.kIDLayout == data.kIDLayout);

      if (chassis) {
        return `<div class="text-center">Châssis<br>${chassis.kIDBrand.sBrandTitle} - ${chassis.kIDModel.sModelTitle}</div>`
      }

      return '';
    },

    selectFirst: function() {
      if (this.getRecords.length > 0 && this.selectedLayoutParent == null) {
        this.selectedLayoutParent = 0;
        this.selectedLayoutParentData = this.getRecords[0];
      }
    },

    getLayoutTitle: function(layout) {
      return layout.sLayoutTitle;
    }
  }
}
</script>
<style>

</style>