<template>
  <v-container class="center-y-child">
    <v-row>
      <v-col xl="6" lg="6" md="6" sm="12" xs="12">
      </v-col>
      <v-col xl="6" lg="6" md="6" sm="12" xs="12">
        <v-card
          class="mx-auto mt-4 pt-2"
          max-width="400"
        >
          <v-img
            class="mx-4 my-4"
            src="@/assets/logo.png"
            max-width="400"
          >
          </v-img>
          <v-card-title>{{ $t('_Views._Login.Login.title') }}</v-card-title>
          <v-card-subtitle>{{ $t('_Views._Login.Login.subtitle') }}</v-card-subtitle>

          <v-card-text class="text--primary">
            <v-text-field
              v-model="email"
              name="email"
              type="email"
              outlined
              :label="$t('_Views._Login.Login.*label.email')"
              prepend-inner-icon="mdi-account"
              hide-details=true
              class="mb-4"
              v-on:keyup.native.enter="login"
            ></v-text-field>
            <v-text-field
              v-model="password"
              type="password"
              outlined
              :label="$t('_Views._Login.Login.*label.password')"
              prepend-inner-icon="mdi-lock"
              hide-details=true
              class="mb-4"
              v-on:keyup.native.enter="login"
            ></v-text-field>
            <v-alert
              v-if="errorMessage"
              dense
              type="error"
            >
              {{ errorMessage }}
            </v-alert>
            <v-btn color="primary" block @click="login">{{ $t('_Views._Login.Login.*button.login') }}</v-btn>
            <LanguageSwitcher />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';

import LanguageSwitcher from '@/components/App/LanguageSwitcher';

export default {
  name: 'Login',
  components: { LanguageSwitcher },
  data: function() {
    return {
      email: null,
      password: null,
      errorMessage: null
    }
  },
  methods: {
    // Demande de token d'authentification pour pouvoir utiliser l'application
    login: function() {
      this.errorMessage = null;

      this.$store.commit('app/mask');
      this.$store.dispatch('user/login', {
        email: this.email,
        password: this.password 
      }).then((response) => {
        if (response.success) {
          window.location.reload();
        }
      }).catch(() => {        
        // Affichage d'un message d'erreur dans la popup
        this.errorMessage = i18n.t("_Views._Login.Login.*error.*login.text");

        // Lève le mask
        this.$store.commit('app/unmask');
      })
    }
  }
}
</script>