<template>
  <!-- EDIT -->
  <v-dialog
    v-model="editPopup"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="center-xy" style="background-color: #000000e0;" >
      <div style="position: fixed; top: 15px; left: 15px;">
        <v-btn
          v-if="!edit"
          rounded
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          v-if="!edit"
          class="ml-2"
          rounded
          @click="setEditArea"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <!--<v-btn
          v-if="!edit"
          class="ml-2"
          rounded
          @click="setEditArea"
        >
          <v-icon>mdi-crop</v-icon>
        </v-btn>-->
      </div>
      <img style="max-width: 80vw; max-height: 80vh;" ref="imgRef" :src="src" />
    </v-card>
  </v-dialog>
</template>

<script>
import * as markerjs2 from 'markerjs2';

export default {
  name: "PhotoEdit",
  components: { },
  props: {
    editPopup: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      edit: false
    };
  },
  methods: {
    setEditArea: function() {
      if (this.$refs.imgRef) {
        const markerArea = new markerjs2.MarkerArea(this.$refs.imgRef);

        markerArea.addEventListener('render', event => {
          this.$emit('srcChange', event.dataUrl);
          this.edit = false;
          markerArea.close();
        });

        markerArea.addEventListener('close', () => {
          this.edit = false;
        });

        markerArea.renderAtNaturalSize = true;
        markerArea.show();

        this.edit = true;
      }
    }
  }
}
</script>
<style>
  .__markerjs2_ {
    z-index: 999 !important;
  }
</style>