<template>
  <div>
    <v-data-table
      :height="getTableHeight"
      fixed-header
      :headers="headers"
      :items="getRecords"
      :item-class="getOddBackground"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :page.sync="page"
      item-key="kIDChassis"
      no-data-text="Aucun châssis disponible"
      show-expand
      class="elevation-1"
      disable-filtering
      disable-sort
      @click:row="onRowClicked"
      @page-count="onPageCountChange"
    >
      <template v-slot:item.iChassisPriority="{ item }">
        <v-icon v-if="item.iChassisPriority == '1'" color="red">mdi-exclamation-thick</v-icon>
      </template>

      <template v-slot:item.kIDBrand="{ item }">
        {{ typeof item.kIDBrand == "object" ? item.kIDBrand.sBrandTitle : item.kIDBrand }}
      </template>
      <template v-slot:item.kIDModel="{ item }">
        {{ typeof item.kIDModel == "object" ? item.kIDModel.sModelTitle : item.kIDModel }}
      </template>
      <template v-slot:item.sChassisNumber="{ item }">
        <CustomChip color="#373737"
          :text="item.sChassisNumber" 
        />
      </template>
      <template v-slot:item.dChassisReceptionDate="{ item }">
        <CustomDate :date="item.dChassisReceptionDate" />
      </template>
      <template v-slot:item.kIDWFStatus="{ item }" class="px-0">
        <Fa :icon="item.sWFGlyphSymbol" :color="item.sWFGlyphColor" size="fa-2x" />
      </template>
      <template v-slot:item.kIDChassisStatus="{ item }">
        <div v-if="item.kIDChassisStatus == 0"><v-btn fab icon @click="onkIDChassisStatusBtnClick(item)"><v-icon large color="green">mdi-check-circle</v-icon></v-btn></div>
        <div v-else-if="item.kIDChassisStatus == 2"><v-btn fab icon @click="onkIDChassisStatusBtnClick(item)"><v-icon large color="orange">mdi-alert</v-icon></v-btn></div>
        <div v-else-if="item.kIDChassisStatus == 1"><v-btn fab icon @click="onkIDChassisStatusBtnClick(item)"><v-icon large color="red">mdi-alert-decagram</v-icon></v-btn></div>
      </template>
      <template v-slot:item.kIDLayout="{ item }">
        <div v-if="item.kIDLayout == null || item.kIDLayout == '-1'"><v-btn fab icon @click="onkIDLayoutBtnClick(item)"><v-icon large color="info">mdi-map-marker-question</v-icon></v-btn></div>
        <div v-else><v-btn fab icon @click="onkIDLayoutBtnClick(item)"><v-icon large color="green">mdi-map-marker-check</v-icon></v-btn></div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          <v-container class="mx-0" style="max-width: unset;">
            <v-row>
              <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                <CollapsedCard style="min-height: 160px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Informations générales
                        </div>

                        <CustomSubtitle 
                          title="Couleur véhicule"
                          :data="item.sChassisColor"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type de roues" 
                          :data="item.sChassisWheelType"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Cabine" 
                          :data="item.sChassisCabin"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur châssis" 
                          :data="getReadabledFloat(item.fChassisWidth)"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Porte à faux" 
                          :data="item.sChassisPaf"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCard>
              </v-col>
              
              <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                <CollapsedCard style="min-height: 160px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Détails 1
                        </div>
                      
                        <CustomSubtitle 
                          title="Coffre déplacement roue de secours" 
                          :data="item.sChassisBootWheel"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Coffre coupe châssis" 
                          :data="item.sChassisBootChassis"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Benne déplacement roue de secours" 
                          :data="item.sChassisDumpWheel"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCard>
              </v-col>
              <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                <CollapsedCard style="min-height: 160px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Détails 2
                        </div>
                
                        <CustomSubtitle
                          title="Poids maxi essieu avant" 
                          :data="item.sChassisMaxWeightFrontAxle"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Poids maxi essieu arrière" 
                          :data="item.sChassisMaxWeightBackAxle"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Nombre de places avant" 
                          :data="item.sChassisNumberFrontSeat"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Nombre de places arrière" 
                          :data="item.sChassisNumberBackSeat"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCard>
              </v-col>
            </v-row>
          </v-container>
        </td>
    </template>
    </v-data-table>
    <CustomFieldPopup :title="getCustomFieldPopupTitle()" :show="customFieldPopup" @close="closeCustomFieldPopup">
      <template v-slot:content>
        <v-row class="ma-0">
          <v-col xl="6" lg="6" md="6" sm="12" xs="12" cols="6">
            <div class="mx-4 my-4">
              <p>{{ customFieldPopupData.description }}</p>
            </div>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="12" xs="12" cols="6">
            <PinchZoom>
              <LstImage height="500px" minHeight="500px" :imgKey="popUpSelectedImage"></LstImage>
            </PinchZoom>
            <v-row class="mx-2 my-2 center-xy">
              <v-col v-for="i in customFieldPopupData.photos" :key="i.keys" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                <LstImage width="100%" height="100%" :imgKey="i.keys" @click="popUpSelectedImage = i.keys"></LstImage>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </CustomFieldPopup>
    <LayoutPopup :show="layoutPopupShowed" title="Choisir un emplacement" :item="selectedChassis" @close="closeLayoutPopup" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'
import PinchZoom from 'vue-pinch-zoom';

import CustomDate from "@/components/DataGrid/CustomDate";
import CustomChip from "@/components/DataGrid/CustomChip";
import Fa from "@/components/Fa";
import CollapsedCard from '@/components/DataGrid/ExpandedItem/CollapsedCard';
import CustomSubtitle from '@/components/DataGrid/ExpandedItem/CustomSubtitle';
import CustomFieldPopup from '@/components/DataGrid/CustomFieldPopup.vue';
import LstImage from '@/components/LstImage.vue';

import LayoutPopup from './components/LayoutPopup.vue';

import DataGridMixin from '@/components/DataGrid/DataGridMixin';

export default {
  name: 'Chassis',

  mixins: [DataGridMixin],

  components: { CustomDate, CustomChip, Fa, CollapsedCard, CustomSubtitle, CustomFieldPopup, LstImage, PinchZoom, LayoutPopup },

  data: function() {
    return {
      headers: [
        { text: '', value: 'iChassisPriority', },
        { text: 'Marque', value: 'kIDBrand', },
        { text: 'Modèle', value: 'kIDModel' },
        { text: "Empattement", value: 'sChassisWheelBase' },
        { text: "Numéro de châssis", value: 'sChassisNumber' },
        { text: 'Numéro de commande client', value: 'sChassisNumberOrder' },
        { text: 'Date de réception', value: 'dChassisReceptionDate' },
        { text: 'Statut', value: 'kIDWFStatus' },
        { text: 'Réception conforme', value: 'kIDChassisStatus' },
        { text: 'Emplacement', value: 'kIDLayout' }
      ],
      customFieldPopup: false,
      customFieldPopupData: { photos: [] },
      popUpSelectedImage: null,
      selectedChassis: null,
      layoutPopupShowed: false,
    }
  },
  
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      records: 'chassis/getRecords'
    })
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
    EventBus.$on('headerPaginationChanged', this.onHeaderValueChanged);
  },

  methods: {
    getOddBackground: function(item) {
      return this.getRecords.findIndex(el => el.kIDChassis == item.kIDChassis) % 2 ? "nth-child-2n" : "";
    },

    getCustomFieldPopupTitle: function() {
      if (this.customFieldPopupData && this.customFieldPopupData.sChassisNumber) {
        return 'Avarie(s) sur ' 
          + (typeof this.customFieldPopupData.kIDBrand == "object" ? this.customFieldPopupData.kIDBrand.sBrandTitle : this.customFieldPopupData.kIDBrand) 
          + " - " + (typeof this.customFieldPopupData.kIDModel == "object" ? this.customFieldPopupData.kIDModel.sModelTitle : this.customFieldPopupData.kIDModel)
          + " - " + this.customFieldPopupData.sChassisNumber;
      }
      else {
        return "";
      }
    },

    onkIDChassisStatusBtnClick: function(item) {
      this.rowBtnClicked = true;

      if(item.kIDChassisStatus != 0) {
        this.customFieldPopup = true;

        const data = item;
        data.photos = [];

        if (item.vChassisPhoto1) {
          data.photos.push({
            keys: item.vChassisPhoto1
          })
        }

        if (item.vChassisPhoto2) {
          data.photos.push({
            keys: item.vChassisPhoto2
          })
        }

        if (item.vChassisPhoto3) {
          data.photos.push({
            keys: item.vChassisPhoto3
          })
        }

        if (item.vChassisPhoto4) {
          data.photos.push({
            keys: item.vChassisPhoto4
          })
        }

        data.description = item.sDamageComment;

        this.customFieldPopupData = data;

        this.popUpSelectedImage = this.customFieldPopupData?.photos[0]?.keys;
      }
    },

    closeCustomFieldPopup: function() {
      this.customFieldPopup = false;
      this.customFieldPopupData = { photos: [] };
      this.popUpSelectedImage = null;
    },

    onkIDLayoutBtnClick: function(item) {
      this.rowBtnClicked = true;
      this.selectedChassis = item;
      this.layoutPopupShowed = true;
    },

    closeLayoutPopup: function() {
      this.selectedChassis = { kIDChassis: null, kIDLayout: '-1' };
      this.layoutPopupShowed = false;
    }
  }
}
</script>
<style>
  .v-application .text-overline {
    line-height: 1rem !important;
  }

  .v-data-table-header {
    background-color: var(--v-red-base);
  }
  .v-data-table-header > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--v-red-base) !important;
  }
  .v-data-table-header > tr > th {
    color: white !important;
  }

  .v-data-table.v-data-table--fixed-header thead th {
    background-color: var(--v-red-base) !important;
  }

  /** background une ligne sur deux */
  .nth-child-2n {
    background-color: var(--v-secondary-lighten2) !important;
  }
  
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.nth-child-2n) {
    background-color: white !important;
  }  
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button.white--text {
    color: white !important;
  }
</style>