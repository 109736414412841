<template>
  <v-bottom-navigation
    background-color="red"
    dark
    app
    :value="$route.name"
    grow
  >
    <v-btn value="home" to="/home">
      <span class="ml-14">Accueil</span>

      <v-icon class="ml-14">mdi-home</v-icon>
    </v-btn>
    
    <v-menu transition="slide-y-transition" open-on-hover rounded="t-xl" top offset-y>

      <template v-slot:activator="{ on, attrs }">
        <v-btn value="assembly_folder" link v-bind="attrs" v-on="on">
          <span>Montage</span>

          <v-icon>mdi-file-document-edit-outline</v-icon>
          <v-icon style="position: absolute; top: 5px; right: 5px;">mdi-upload-multiple</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group
          color="red"
        >
          <v-list-item to="/assembly_folder/init">
            <v-list-item-icon>
              <v-icon>mdi-file-document-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Initialisation</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/assembly_folder/edit">
            <v-list-item-icon>
              <v-icon>mdi-file-sign</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dossiers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    
    <v-btn value="shipping" to="/shipping">
      <span>Expédition véhicule</span>

      <v-icon>mdi-truck-delivery-outline</v-icon>
    </v-btn>

    <v-btn value="documentation" to="/documentation">
      <span>Documentation</span>

      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    
    <v-btn value="layout" to="/layout">
      <span>Emplacements</span>

      <v-icon>mdi-map-marker-check</v-icon>
    </v-btn>

    <v-menu transition="slide-y-transition" open-on-hover rounded="t-xl" top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn value="reception" link v-bind="attrs" v-on="on">
          <span>Réception</span>

          <v-icon>mdi-package-down</v-icon>
          <v-icon style="position: absolute; top: 5px; right: 5px;">mdi-upload-multiple</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group
          color="red"
        >
          <v-list-item to="/reception/tippers">
            <v-list-item-icon>
              <v-icon>mdi-dump-truck</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Bennes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/reception/lockers">
            <v-list-item-icon>
              <v-icon>mdi-toolbox</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Coffres</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/reception/chassis">
            <v-list-item-icon>
              <v-icon>mdi-car-convertible</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Châssis</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-menu transition="slide-y-transition" open-on-hover rounded="t-xl" top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn value="data" link v-bind="attrs" v-on="on">
          <span>Flux</span>

          <v-icon>mdi-database-eye-outline</v-icon>
          <v-icon style="position: absolute; top: 5px; right: 5px;">mdi-upload-multiple</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group
          color="red"
        >
          <v-list-item to="/data/tippers">
            <v-list-item-icon>
              <v-icon>mdi-dump-truck</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Bennes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/data/lockers">
            <v-list-item-icon>
              <v-icon>mdi-toolbox</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Coffres</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/data/complements">
            <v-list-item-icon>
              <v-icon>mdi-folder-question</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Compléments</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/data/assembly_orders">
            <v-list-item-icon>
              <v-icon>mdi-car-wrench</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Ordres de montage</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/data/assembly_folders">
            <v-list-item-icon>
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dossiers de montage</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "Footer",

  components: { },

  props: {

  }
};
</script>