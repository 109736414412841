import AssemblyFoldersServices from '@/services/assemblyFoldersServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }  
}

// actions
const actions = {
  loadFromWS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, page, start, limit } = payload;

        commit('download/increaseCounter', null, { root: true });
        AssemblyFoldersServices.getList(
          //kIDUser,
          kIDUser,
          //xmlFiltersSorts,
          "<xmlst><Filters/><FiltersIN/><FiltersNOTIN/><FiltersOR/><Sorts/></xmlst>",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          // Nous avons récupéré les data, il faut maintenant faire le lien avec les autres stores
          commit('setRecords', response.Item);

          dispatch("setRelationship");
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  setRelationship({ state, commit, rootGetters }, from) {
    state.records?.forEach(data => {
      const enrichData = (primaryKey, storePath, searchFn) => {
        // Si la data existe
        if (data[primaryKey]) {
          // Recherche dans son store
          const richData = rootGetters[storePath].find(
            searchFn ? searchFn :
            el => el[primaryKey] == data[primaryKey]
          );

          // Si elle existe, il faut enrichir la data
          data[primaryKey] = richData ? richData : data[primaryKey];
        }
      }
      
      if (from == null) {
        enrichData("kIDChassis", "chassis/getRecords");
        enrichData("kIDTipper", "tipper/getRecords", el => el.kIDSupply == data.kIDTipper);
        enrichData("kIDLocker", "locker/getRecords", el => el.kIDSupply == data.kIDLocker);
      }
      else {
        switch (from) {
          case "kIDChassis":
            enrichData("kIDBrand", "chassis/getRecords");
            break;
          case "kIDTipper":
            enrichData("kIDContractor", "tipper/getRecords", el => el.kIDSupply == data.kIDTipper);
            break;
          case "kIDLocker":
            enrichData("kIDInvoicedClient", "locker/getRecords", el => el.kIDSupply == data.kIDLocker);
            break;
        
          default:
            break;
        }
      }
    });
    
    commit('sort');
  },
  create({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDAssemblyCenter, sProjectOrder, sProjectLine, kIDChassis, kIDTipper, kIDLocker, dFolderCreationDate, sFolderComment, sFolderCustomerReference } = payload;

        commit('download/increaseCounter', null, { root: true });
        AssemblyFoldersServices.create(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDFolder",
          //isBckff 
          0,
          //kIDComponent
          "011590",
          //viewName
          `wft_T0115Folder(${kIDUser}, '')`,
          //xmlData
          `<xmlst>`
          + `<T0115Folder>`
            + `<Item>`
              + `<kIDFolder>-1</kIDFolder>`
              + `<kIDCreator>${kIDUser}</kIDCreator>`
              + `<kIDAssemblyCenter>${kIDAssemblyCenter}</kIDAssemblyCenter>`
              + `<kIDWFStatus>0</kIDWFStatus>`
              + `<kIDWFStatusFrom>0</kIDWFStatusFrom>`
              + `<kIDComponent>011590</kIDComponent>`
              + `<dAssemblyStartDate>${dFolderCreationDate.getDate()}/${dFolderCreationDate.getMonth()+1}/${dFolderCreationDate.getYear()+1900} ${dFolderCreationDate.getHours()}:${dFolderCreationDate.getMinutes()}:${dFolderCreationDate.getSeconds()}</dAssemblyStartDate>`
              + `<sProjectOrder>${sProjectOrder}</sProjectOrder>`
              + `<sProjectLine>${sProjectLine}</sProjectLine>`
              + `<kIDChassis>${kIDChassis}</kIDChassis>`
              + `<kIDTipper>${kIDTipper}</kIDTipper>`
              + `<kIDLocker>${kIDLocker}</kIDLocker>`
              + `<sFolderComment>${sFolderComment}</sFolderComment>`
              + `<sFolderCustomerReference>${sFolderCustomerReference}</sFolderCustomerReference>`
            + `</Item>`
          + `</T0115Folder>`
        + `</xmlst>`
        ).then((response) => {
          const record = response.Item;
          const enrichData = (primaryKey, storePath, searchFn) => {
            // Si la data existe
            if (record[primaryKey]) {
              // Recherche dans son store
              const richData = rootGetters[storePath].find(
                searchFn ? searchFn :
                el => el[primaryKey] == record[primaryKey]
              );

              // Si elle existe, il faut enrichir la data
              record[primaryKey] = richData ? richData : record[primaryKey];
            }
          }
        
          enrichData("kIDChassis", "chassis/getRecords");
          enrichData("kIDTipper", "tipper/getRecords", el => el.kIDSupply == record.kIDTipper);
          enrichData("kIDLocker", "locker/getRecords", el => el.kIDSupply == record.kIDLocker);

          commit('setRecord', record);
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  mount({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDFolder } = payload;

        commit('download/increaseCounter', null, { root: true });
        AssemblyFoldersServices.mount(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDFolder",
          //isBckff 
          0,
          //kIDComponent
          "011591",
          //viewName
          `wft_T0115Folder(${kIDUser}, '')`,
          //xmlData
          `<xmlst>`
          + `<T0115Folder>`
            + `<Item>`
              + `<kIDFolder>${kIDFolder}</kIDFolder>`
              + `<kIDCreator>${kIDUser}</kIDCreator>`
              + `<kIDWFStatus>01150900</kIDWFStatus>`
              + `<kIDWFStatusFrom>0</kIDWFStatusFrom>`
              + `<kIDComponent>011591</kIDComponent>`
            + `</Item>`
          + `</T0115Folder>`
        + `</xmlst>`
        ).then((response) => {
          const record = response.Item;
          const enrichData = (primaryKey, storePath, searchFn) => {
            // Si la data existe
            if (record[primaryKey]) {
              // Recherche dans son store
              const richData = rootGetters[storePath].find(
                searchFn ? searchFn :
                el => el[primaryKey] == record[primaryKey]
              );

              // Si elle existe, il faut enrichir la data
              record[primaryKey] = richData ? richData : record[primaryKey];
            }
          }
        
          enrichData("kIDChassis", "chassis/getRecords");
          enrichData("kIDTipper", "tipper/getRecords", el => el.kIDSupply == record.kIDTipper);
          enrichData("kIDLocker", "locker/getRecords", el => el.kIDSupply == record.kIDLocker);

          commit('setRecord', record);
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  validate({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDFolder } = payload;

        commit('download/increaseCounter', null, { root: true });
        AssemblyFoldersServices.validate(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDFolder",
          //isBckff 
          0,
          //kIDComponent
          "11592",
          //viewName
          `wft_T0115Folder(${kIDUser}, '')`,
          //xmlData
          `<xmlst>`
          + `<T0115Folder>`
            + `<Item>`
              + `<kIDFolder>${kIDFolder}</kIDFolder>`
              + `<kIDCreator>${kIDUser}</kIDCreator>`
              + `<kIDWFStatus>1150910</kIDWFStatus>`
              + `<kIDWFStatusFrom>1150900</kIDWFStatusFrom>`
              + `<kIDComponent>11592</kIDComponent>`
            + `</Item>`
          + `</T0115Folder>`
        + `</xmlst>`
        ).then((response) => {
          const record = response.Item;
          const enrichData = (primaryKey, storePath, searchFn) => {
            // Si la data existe
            if (record[primaryKey]) {
              // Recherche dans son store
              const richData = rootGetters[storePath].find(
                searchFn ? searchFn :
                el => el[primaryKey] == record[primaryKey]
              );

              // Si elle existe, il faut enrichir la data
              record[primaryKey] = richData ? richData : record[primaryKey];
            }
          }
        
          enrichData("kIDChassis", "chassis/getRecords");
          enrichData("kIDTipper", "tipper/getRecords", el => el.kIDSupply == record.kIDTipper);
          enrichData("kIDLocker", "locker/getRecords", el => el.kIDSupply == record.kIDLocker);

          commit('setRecord', record);
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  expedition({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDFolder, sFolderShipmentComment, sFolderShipmentAddress } = payload;

        commit('download/increaseCounter', null, { root: true });
        AssemblyFoldersServices.expedition(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDFolder",
          //isBckff 
          0,
          //kIDComponent
          "11593",
          //viewName
          `wft_T0115Folder(${kIDUser}, '')`,
          //xmlData
          `<xmlst>`
          + `<T0115Folder>`
            + `<Item>`
              + `<kIDFolder>${kIDFolder}</kIDFolder>`
              + `<sFolderShipmentComment>${sFolderShipmentComment}</sFolderShipmentComment>`
              + `<sFolderShipmentAddress>${sFolderShipmentAddress}</sFolderShipmentAddress>`
              + `<kIDCreator>${kIDUser}</kIDCreator>`
              + `<kIDWFStatus>1150920</kIDWFStatus>`
              + `<kIDWFStatusFrom>1150910</kIDWFStatusFrom>`
              + `<kIDComponent>11593</kIDComponent>`
            + `</Item>`
          + `</T0115Folder>`
        + `</xmlst>`
        ).then((response) => {
          const record = response.Item;
          const enrichData = (primaryKey, storePath, searchFn) => {
            // Si la data existe
            if (record[primaryKey]) {
              // Recherche dans son store
              const richData = rootGetters[storePath].find(
                searchFn ? searchFn :
                el => el[primaryKey] == record[primaryKey]
              );

              // Si elle existe, il faut enrichir la data
              record[primaryKey] = richData ? richData : record[primaryKey];
            }
          }
        
          enrichData("kIDChassis", "chassis/getRecords");
          enrichData("kIDTipper", "tipper/getRecords", el => el.kIDSupply == record.kIDTipper);
          enrichData("kIDLocker", "locker/getRecords", el => el.kIDSupply == record.kIDLocker);

          commit('setRecord', record);
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
}

// mutations
const mutations = {
  setRecords(state, records) {
    state.records = Array.isArray(records) ? records : typeof records == 'object' ? [records] : [];
  },
  setRecord(state, record) {
    const exist = state.records.find(el => el.kIDFolder == record.kIDFolder);

    if (exist) {
      exist.kIDWFStatus = record.kIDWFStatus;
      exist.kIDWFStatusFrom = record.kIDWFStatusFrom;
    }
    else {
      state.records.push(record);
    }
  },
  setPageStep(state, payload) {
    const { kIDFolder, iPageStep} = payload;
    
    const exist = state.records.find(record => record.kIDFolder == kIDFolder);

    if (exist) {
      exist.iGlobalFormContentStep = iPageStep;
    }

  },
  sort(state) {
    state.records?.sort((a, b) => {
      if (parseInt(a.sProjectOrder) < parseInt(b.sProjectOrder)) {
        return -1;
      }
      else if (parseInt(a.sProjectOrder) > parseInt(b.sProjectOrder)) {
        return 1;
      }
      else if (parseInt(a.sProjectOrder) == parseInt(b.sProjectOrder)) {
        if (parseInt(a.sProjectLine) < parseInt(b.sProjectLine)) {
          return -1;
        }
        else if (parseInt(a.sProjectLine) > parseInt(b.sProjectLine)) {
          return 1;
        }
        else {
          return 0;
        }
      }
      else {
        return 1;
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}