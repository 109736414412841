<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="d-flex flex-column" >
      <v-toolbar
        dark
        color="red"
        style="flex: 0;"
      >
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ selectedEventData ? selectedEventData.sEventStatusTitle : '' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ selectedFolderData ? (selectedFolderData.sContractorTitle + ' - ' + selectedFolderData.sProjectOrderLine) : '' }}</v-toolbar-title>
      </v-toolbar>
      <div class="px-4 py-2">
        <v-container>
          <v-row class="mt-4">
            <v-col cols="6" v-if="selectedEventData && selectedEventData.sEventType == '10'">
              <Field title="Numéro de série" :data="selectedEventData ? selectedEventData.sAvarieEntitySerialNumber : '-'" />
              <Field title="Entité" :data="selectedEventData ? selectedEventData.sAvarieEntityType : '-'" />
              <Field title="Marque" :data="selectedEventData ? selectedEventData.sBrandTitle : '-'" />
              <Field title="Modèle" :data="selectedEventData ? selectedEventData.sModelTitle : '-'" />
              
              <v-btn v-if="selectedEventData && selectedEventData.sEventType == '10'" block class="mt-2" color="info" @click="popUpSelectedImage = selectedEventData.vAvariePhoto1; dialogDetails = true">
                <span>Voir les détails de l'avarie</span>
              </v-btn>
            </v-col>
            <v-col cols="6" v-else>
              <Field title="Question" :data="selectedEventData ? selectedEventData.sConsigneQuestion : '-'" />
              <Field title="Réponse" :data="selectedEventData ? selectedEventData.sConsigneAnswer : '-'" />
              <Field title="Question code" :data="selectedEventData ? selectedEventData.sConsigneQuestionCode : '-'" />
            </v-col>
            <v-col cols="6">
              <span>Commentaire :</span>
              <div>
                {{ selectedEventData ? selectedEventData.sTopikComment : '' }}
              </div>
            </v-col>
            <v-col cols="12">
              <div>Commentaire de résolution :</div>
              <v-textarea v-model="commentary" :disabled="selectedEventData && selectedEventData.kIDWFStatus.toString().endsWith('99')" solo hide-details height="100px"></v-textarea>
            </v-col>
            <v-col cols="6">
            <div>Photo de preuve de résolution :</div>
              <EventPhoto :update="updateImg" :disabled="selectedEventData && selectedEventData.kIDWFStatus.toString().endsWith('99')" :selectedEventData="selectedEventData" @onChange="onPhotoChange" />
            </v-col>
            <v-col cols="6">
              <v-btn v-if="selectedEventData && !selectedEventData.kIDWFStatus.toString().endsWith('99')" class="cmp_btn elevation-10" style="width: 100%; height: 100%; min-height: 100px;" color="success" @click="validate">
                <span style="font-size: large">Confirmer et signer</span>
              </v-btn>
              <div v-else class="h-100 center-y">
                <div class="text-h5" style="font-family: 'Indie Flower' !important;"><i><b>Confirmée le {{ selectedEventData ? new Date(selectedEventData.dEventResolutionDate).toLocaleString() : '' }}</b></i></div>
                <div class="text-h5" style="font-family: 'Indie Flower' !important;"><i><b>par {{ selectedEventData ? selectedEventData.sEventResolutionerTitle : '' }}</b></i></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-dialog
        v-model="dialogDetails"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
      >
        <v-toolbar
          dark
          color="red"
          style="flex: 0;"
        >
          <v-btn
            icon
            dark
            @click="dialogDetails = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Détails de l'avarie</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ selectedEventData ? selectedEventData.sEventStatusTitle : '' }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ selectedFolderData ? (selectedFolderData.sContractorTitle + ' - ' + selectedFolderData.sProjectOrderLine) : '' }}</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-row class="ma-0">
            <v-col xl="6" lg="6" md="6" sm="12" xs="12" cols="6">
              <div class="mx-4 my-4">
                <p>{{ selectedEventData && selectedEventData.sDamageComment }}</p>
              </div>
              <v-spacer></v-spacer>
              <v-row class="mx-2 my-2 center-xy">
                <v-col v-if="selectedEventData && selectedEventData.vAvariePhoto1" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                  <LstImage width="100%" height="100%" :imgKey="selectedEventData.vAvariePhoto1" @click="popUpSelectedImage = selectedEventData.vAvariePhoto1"></LstImage>
                </v-col>
                <v-col v-if="selectedEventData && selectedEventData.vAvariePhoto2" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                  <LstImage width="100%" height="100%" :imgKey="selectedEventData.vAvariePhoto2" @click="popUpSelectedImage = selectedEventData.vAvariePhoto2"></LstImage>
                </v-col>
                <v-col v-if="selectedEventData && selectedEventData.vAvariePhoto3" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                  <LstImage width="100%" height="100%" :imgKey="selectedEventData.vAvariePhoto3" @click="popUpSelectedImage = selectedEventData.vAvariePhoto3"></LstImage>
                </v-col>
                <v-col v-if="selectedEventData && selectedEventData.vAvariePhoto4" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                  <LstImage width="100%" height="100%" :imgKey="selectedEventData.vAvariePhoto4" @click="popUpSelectedImage = selectedEventData.vAvariePhoto4"></LstImage>
                </v-col>
                <v-col v-if="selectedEventData && selectedEventData.vAvariePhoto5" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                  <LstImage width="100%" height="100%" :imgKey="selectedEventData.vAvariePhoto5" @click="popUpSelectedImage = selectedEventData.vAvariePhoto5"></LstImage>
                </v-col>
                <v-col v-if="selectedEventData && selectedEventData.vAvariePhoto6" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                  <LstImage width="100%" height="100%" :imgKey="selectedEventData.vAvariePhoto6" @click="popUpSelectedImage = selectedEventData.vAvariePhoto6"></LstImage>
                </v-col>
                <v-col v-if="selectedEventData && selectedEventData.vAvariePhoto7" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                  <LstImage width="100%" height="100%" :imgKey="selectedEventData.vAvariePhoto7" @click="popUpSelectedImage = selectedEventData.vAvariePhoto7"></LstImage>
                </v-col>
                <v-col v-if="selectedEventData && selectedEventData.vAvariePhoto8" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                  <LstImage width="100%" height="100%" :imgKey="selectedEventData.vAvariePhoto8" @click="popUpSelectedImage = selectedEventData.vAvariePhoto8"></LstImage>
                </v-col>
              </v-row>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" xs="12" cols="6">
              <PinchZoom>
                <LstImage height="500px" minHeight="500px" :imgKey="popUpSelectedImage"></LstImage>
              </PinchZoom>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Field from './Field.vue'
import EventPhoto from './EventPhoto.vue'
import PinchZoom from 'vue-pinch-zoom';
import LstImage from '@/components/LstImage.vue';

export default {
  name: "UpdateEventPopUp",
  components: { PinchZoom, LstImage, Field, EventPhoto },
  props: {
    dialog: {
      default: false
    },
    selectedFolderData: {
      type: Object,
      default: () => ({
        kIDFolder: null
      })
    },
    selectedEventData: {
      type: Object,
      default: () => ({
        kIDEventID: null
      })
    }
  },
  watch: {
    dialog: function() {
      this.updateImg = new Date().getTime();
      this.commentary = this.selectedEventData ? this.selectedEventData.sEventFixCommentary : null;
      this.src = this.selectedEventData ? this.selectedEventData.vEventFixPicturePath ? this.selectedEventData.vEventFixPicturePath : null : null;
    }
  },
  computed: {
    ...mapGetters({
      avarieRecords: 'avaries/getRecords',
      consigneRecords: 'consignes/getRecords',
      getUser: 'user/getUser'
    })
  },
  data: function () {
    return {
      src: null,
      commentary: null,
      dialogDetails: false,
      popUpSelectedImage: this.selectedEventData ? this.selectedEventData.vAvariePhoto1 : null,
      updateImg: 0
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    validate: function() {
      this.$store.commit('app/mask');

      if (this.selectedEventData.sEventType == '10') {
        this.$store.dispatch("avaries/evolution", {
          kIDUser: this.getUser.kIDUser, 
          kIDEvent: this.selectedEventData.kIDEvent,
          sEventFixCommentary: this.commentary,
          vEventFixPicturePath: this.src ? this.src.src : null
        }).then(() => {
          this.$emit('updateData');
          this.$store.commit('app/setMessage', {
            title: "Demande réussite",
            message: "Avarie validée et signée"
          });

          this.$store.commit('app/unmask');
        }).catch((error) => {
          this.$store.commit("app/setError");
          console.error(error);
          this.$store.commit('app/unmask');
        });
      }
      else {
        this.$store.dispatch("consignes/evolution", {
          kIDUser: this.getUser.kIDUser, 
          kIDEvent: this.selectedEventData.kIDEvent,
          sEventFixCommentary: this.commentary,
          vEventFixPicturePath: this.src ? this.src.src : null
        }).then(() => {
          this.$emit('updateData');
          this.$store.commit('app/setMessage', {
            title: "Demande réussite",
            message: "Consignes validée et signée"
          });

          this.$store.commit('app/unmask');
        }).catch((error) => {
          this.$store.commit("app/setError");
          console.error(error);
          this.$store.commit('app/unmask');
        });
      }
      this.close();
    },
    onPhotoChange: function(src) {
      this.src = src;
    }
  },
}
</script>
<style>
  .cmp_btn > .v-btn__content {
    flex-direction: column;
  }
</style>