<template>
<div v-if="globalshow" style="display: inline-flex; align-items: center; flex-grow: 1;">
  <v-btn icon @click="show = !show;">
    <v-icon>mdi-magnify</v-icon>
  </v-btn>
  <v-expand-x-transition>
    <v-card rounded="pill" v-show="show">
      <v-text-field
        v-model="value"
        label="Recherche"
        rounded
        dense
        solo
        clearable
        hide-details
        @keyup="onKeyUp"
        @click:clear="onCleared"
      ></v-text-field>
    </v-card>
    
  </v-expand-x-transition>
</div>

</template>

<script>
import { debounce } from "debounce";
import { EventBus } from '@/event-bus'

export default {
  name: 'SearchableHeader',

  props: {
    
  },

  data: function() {
    return {
      show: false,
      value: null
    }
  },

  computed: {
    globalshow() {
      return this.$route?.meta?.searchable;
    }
  },

  watch: {
    $route() {
      this.value = null;
      this.show = false;
    }
  },

  methods: {
    onKeyUp: debounce(function() {
      this.emitChange();
    }, 500),

    onCleared: function() {
      this.value = null;
      this.emitChange();
    },

    emitChange: function() {
      EventBus.$emit('headerSearchChanged', this.value);
    }
  }
}
</script>