import http from "@/http-common";

class ImageServices {
  getImage(sLink) {
    const url = 'getImage';
    
    const formData = new URLSearchParams();

    formData.append('sLink', sLink);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.Image) {
        return jsonData.Image;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new ImageServices();