import http from "@/http-common-json";

class AssemblyFoldersServices {
  getList(kIDUser, xmlFiltersSorts, page, start, limit) {
    const url = 'getJList';
    
    const formData = new URLSearchParams();

    formData.append('sName', `wft_T0115Folder(${kIDUser}, '')`);
    formData.append('xmlFiltersSorts', xmlFiltersSorts);
    formData.append('page', page);
    formData.append('start', start);
    formData.append('limit', limit);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.Records) {
        return jsonData.Records;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  create(kIDUser, kIDApplication, kIDCompany, primaryKey, isBckff, kIDComponent, viewName, xmlData) {
    const url = 'setJEvolution';
    
    const formData = new URLSearchParams();

    formData.append('kIDUser', kIDUser);
    formData.append('kIDApplication', kIDApplication);
    formData.append('kIDCompany', kIDCompany);
    formData.append('primaryKey', primaryKey);
    formData.append('isBckff', isBckff);
    formData.append('kIDComponent', kIDComponent);
    formData.append('viewName', viewName);
    formData.append('xmlData', xmlData);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.T0115Folder) {
        return jsonData.T0115Folder;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  mount(kIDUser, kIDApplication, kIDCompany, primaryKey, isBckff, kIDComponent, viewName, xmlData) {
    const url = 'setJEvolution';
    
    const formData = new URLSearchParams();

    formData.append('kIDUser', kIDUser);
    formData.append('kIDApplication', kIDApplication);
    formData.append('kIDCompany', kIDCompany);
    formData.append('primaryKey', primaryKey);
    formData.append('isBckff', isBckff);
    formData.append('kIDComponent', kIDComponent);
    formData.append('viewName', viewName);
    formData.append('xmlData', xmlData);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.T0115Folder) {
        return jsonData.T0115Folder;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  validate(kIDUser, kIDApplication, kIDCompany, primaryKey, isBckff, kIDComponent, viewName, xmlData) {
    const url = 'setJEvolution';
    
    const formData = new URLSearchParams();

    formData.append('kIDUser', kIDUser);
    formData.append('kIDApplication', kIDApplication);
    formData.append('kIDCompany', kIDCompany);
    formData.append('primaryKey', primaryKey);
    formData.append('isBckff', isBckff);
    formData.append('kIDComponent', kIDComponent);
    formData.append('viewName', viewName);
    formData.append('xmlData', xmlData);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.T0115Folder) {
        return jsonData.T0115Folder;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  expedition(kIDUser, kIDApplication, kIDCompany, primaryKey, isBckff, kIDComponent, viewName, xmlData) {
    const url = 'setJEvolution';
    
    const formData = new URLSearchParams();

    formData.append('kIDUser', kIDUser);
    formData.append('kIDApplication', kIDApplication);
    formData.append('kIDCompany', kIDCompany);
    formData.append('primaryKey', primaryKey);
    formData.append('isBckff', isBckff);
    formData.append('kIDComponent', kIDComponent);
    formData.append('viewName', viewName);
    formData.append('xmlData', xmlData);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.T0115Folder) {
        return jsonData.T0115Folder;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new AssemblyFoldersServices();