import AuthServices from '@/services/authServices.js';

// initial state
const state = () => ({
  access_token: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  usersAssemblyCenter: localStorage.getItem('usersAssemblyCenter') ? JSON.parse(localStorage.getItem('usersAssemblyCenter')) : null,
});

// getters
const getters = {
  getUser: (state) => {
    return state.user;
  },
  getAccessToken: (state) => {
    return state.access_token;
  },
  getUsersAssemblyCenter: (state) => {
    if (Array.isArray(state.usersAssemblyCenter)) {
      let title = "";
      let more = 0;
      for (let index = 0; index < state.usersAssemblyCenter.length; index++) {
        if (index > 3) {
          more ++;
        }
        else {
          const element = state.usersAssemblyCenter[index];

          if (element) {
            if (title == "") {
              title = element.sAssemblyCenterTitle; 
            }
            else {
              title += ", " + element.sAssemblyCenterTitle; 
            }
          }
        }
      }

      return more > 0 ? title + "..." : title;
    }
    else {
      return "";
    }
  }
}

// actions
const actions = {
  login({ commit }, payload) {
    const { email, password } = payload;

    return new Promise((resolve, reject) => {
      try {
        AuthServices.login(email, password).then((response) => {
          commit('setUser', response.user);
          commit('setAccessToken', response.access_token);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },

  getUsersAssemblyCenter({ commit }, payload) {
    const { kIDUser } = payload;

    return new Promise((resolve, reject) => {
      try {
        AuthServices.getUsersAssemblyCenter(kIDUser).then((response) => {
          commit('setUsersAssemblyCenter', response.Item);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },

  logout({ commit }) {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    
    commit('articles/reset', null, { root: true });
    commit('assemblyOrders/reset', null, { root: true });
    commit('brands/reset', null, { root: true });
    commit('chassis/reset', null, { root: true });
    commit('model/reset', null, { root: true });
    commit('thirdParties/reset', null, { root: true });
    commit('app/reset', null, { root: true });

    window.location.reload();
  }  
}

// mutations
const mutations = {
  setUser(state, user) {
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  setAccessToken(state, access_token) {
    state.access_token = access_token;
    localStorage.setItem('access_token', access_token);
  },
  setUsersAssemblyCenter(state, usersAssemblyCenter) {
    state.usersAssemblyCenter = Array.isArray(usersAssemblyCenter) ? usersAssemblyCenter : [usersAssemblyCenter];
    localStorage.setItem('usersAssemblyCenter', JSON.stringify(state.usersAssemblyCenter));
  },

  reset(state) {
    state.access_token = null;
    state.user = null;
    state.usersAssemblyCenter = null;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}