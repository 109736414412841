<template>
  <v-stepper ref="stepper" class="d-flex flex-column" v-model="step" non-linear>
    <v-stepper-header>
        <v-stepper-step :editable="isEditable((index))" :class="isComplete((index + 1)) ? 'isOk' : ''" v-for="(page, index) in getPages" :key="page.iFieldPage" :step="index + 1" color="red">
        </v-stepper-step>
    </v-stepper-header>
    <v-stepper-content v-for="(page, index) in getPages" :key="page.iFieldPage" :step="index + 1" class="no-transition center-y pa-4 pt-0 pb-0" :style="`opacity: ${loading ? 0 : 1}`">
      <div :sFieldPage="page.iFieldPage" class="d-flex flex-column pa-4 px-16 mx-16">
        <Field v-for="field in page.fields" :key="field.kIDGlobalFormField" :ref="'kIDGlobalFormField_' + field.kIDGlobalFormField" :field="field" :active="step == index+1" :data="data" :kIDGlobalFormContent="getGlobalFormContentRecord ? getGlobalFormContentRecord.kIDGlobalFormContent : null" :reset="reset" class="px-1" />
      </div>
    </v-stepper-content>
    <div class="pa-4 d-flex">
      <v-alert v-if="error" type="error">
        {{ validateError == null ? 'Tous les champs ne sont pas complets !' : validateError }}
      </v-alert>
      <v-alert v-if="serverError" type="error">
        Communication avec le serveur en echec !
      </v-alert>
      <v-spacer></v-spacer>
      <div v-if="!lastPage">
        <v-btn v-if="step != 1" x-large class="mr-2" dark @click="previous()">Etape précédente</v-btn>
        <v-btn x-large dark color="red" @click="save(false)">Etape suivante</v-btn>
      </div>
      <div v-else>
        <v-btn v-if="step != 1" x-large class="mr-2" dark @click="previous()">Etape précédente</v-btn>
        <v-btn
          dark
          x-large
          color="red"
          @click="save(true)"
        >
          Sauvegarder
        </v-btn>
      </div>
    </div>
  </v-stepper>
</template>

<script>
import { mapGetters } from 'vuex'

import { PageDTO } from './dto/page';

import Field from './Field';

export default {
  name: 'GlobalForm',

  components: { Field },

  props: {
    kIDGlobalForm: {
      default: null
    },
    kIDData: {
      default: null
    },
    reset: {
      default: null
    },
    dialog: {
      default: false
    },
    data: {
      type: Object,
      default: () => ({
        
      })
    }
  },
  
  watch: {
    reset() {
      this.pages = [];
      this.step = 0;
    },
    dialog() {
      this.dialog == true ? this.generate() : () => {};
    }
  },
  
  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
      getGlobalFormFieldRecords: 'globalFormField/getRecords',
      getGlobalFormContentRecords: 'globalFormContent/getRecords'
    }),
    getRecords() {
      return this.getGlobalFormFieldRecords.filter(el => el.kIDGlobalForm == this.kIDGlobalForm)
    },
    getGlobalFormContentRecord() {
      return this.getGlobalFormContentRecords.length > 0 ? this.getGlobalFormContentRecords[this.getGlobalFormContentRecords.length - 1] : null
    },
    getPages() {
      return this.pages.filter(el => el.fields.length > 0)
    },
    lastPage() {
      return this.step == this.getPages.length
    },
    isComplete() {
      return page => {
        if (parseInt(this.getGlobalFormContentRecord.iPageStep) == 0) {
          return false;
        }
        if(parseInt(this.getGlobalFormContentRecord.iPageStep) < parseInt(page)) {
          return false;
        }

        return true;
      }
    },
    isEditable() {
      return page => {
        if (parseInt(this.getGlobalFormContentRecord.iPageStep) == 0 || parseInt(this.getGlobalFormContentRecord.iPageStep) == 1) {
          return false;
        }
        if(parseInt(this.getGlobalFormContentRecord.iPageStep) < parseInt(page)) {
          return false;
        }

        return true;
      }
    }
  },

  data: function() {
    return {
      pages: [],
      step: 0,
      loading: false,
      error: false,
      serverError: false,
      validateError: null
    }
  },
  
  methods: {
    validate: function(page) {
      let ok = true;
      this.validateError = null;
      // Pour chaque champs, on valide qu'il est ok
      page.fields.forEach(element => {
        const pageCmp = this.$refs["kIDGlobalFormField_" + element.kIDGlobalFormField][0];
        
        // Le champs est obligatoire
        if (element.iFieldDisplayStatus == "1") {
          // Si le champs est un champs par défaut, vérification qu'il est validé
          if (pageCmp.itIsDefaultValue() && !pageCmp.defaultValueValidate) {
            this.validateError = "Merci de valider toutes les valeurs des champs";
            ok = false;
          }
          
          // En fonction du type de champs
          if(element.sFieldType == "T") {
            if (pageCmp.rules.required(pageCmp.value) == false) {
              ok = false;
            }
          }
          else if(element.sFieldType == "C") {
            if (pageCmp.validateC(pageCmp.value) == false) {
              ok = false;
            }
          }
          else if(element.sFieldType == "M") {
            if (pageCmp.validateM(pageCmp.value) == false) {
              ok = false;
            }
          }
          else if(element.sFieldType == "F") {
            if (pageCmp.rules.required(pageCmp.value) == false) {
              ok = false;
            }
          }
          else if(element.sFieldType == "S") {
            if(pageCmp.$refs['signaturePad_' + element.sFieldName]) {
              const signature = pageCmp.$refs['signaturePad_' + element.sFieldName].saveSignature();

              if (signature && signature.isEmpty == true) {
                ok = false;
              }
            }
          }
        }
      });

      return ok;
    },
    getStep: function(iFieldPage) {
      if (parseInt(this.getGlobalFormContentRecord.iPageStep) >= parseInt(iFieldPage)) {
        return this.getGlobalFormContentRecord.iPageStep
      }

      return iFieldPage;
    },
    save: function(finish) {
      this.$store.commit('app/mask');
      this.loading = true;
      this.error = false;
      this.serverError = false;
      
      // id de la page en cours
      const iFieldPage = this.$refs['stepper'].$el.querySelector('div.v-stepper__content[style="opacity: 1; transform-origin: center top 0px;"]').querySelector('.d-flex').getAttribute('sfieldpage');

      const page = this.pages.find(el => el.iFieldPage == iFieldPage);

      const data = [];

      if (page) {
        // validation des datas
        const valid = this.validate(page);

        if (valid == false) {
          this.cleanMask();

          this.error = true;
          return false;
        }

        // récupération des datas
        page.fields.forEach(element => {
          const pageCmp = this.$refs["kIDGlobalFormField_" + element.kIDGlobalFormField][0];
          
          if (element.sFieldType == 'S' && pageCmp.$refs['signaturePad_' + element.sFieldName]) {
            const signature = pageCmp.$refs['signaturePad_' + element.sFieldName].saveSignature();
            data.push({
              kIDGlobalFormFieldContent: pageCmp.getGlobalFormFieldContentRecord.length == 0 ? null : pageCmp.getGlobalFormFieldContentRecord[0].kIDGlobalFormFieldContent,
              kIDGlobalFormContent: this.getGlobalFormContentRecord ? this.getGlobalFormContentRecord.kIDGlobalFormContent : null,
              kIDGlobalForm: this.kIDGlobalForm,
              kIDGlobalFormField: element.kIDGlobalFormField,
              sFieldName: element.sFieldName,
              sFieldQuestion: element.sFieldQuestion,
              sFieldType: element.sFieldType,
              sFieldResponseContent: null,
              sFieldCommentary: pageCmp.$data.commentary,
              vFieldPhoto1FilePath: null,
              vFieldPhoto2FilePath: null,
              vFieldSignatureFilePath: signature.data
            })
          }
          if (element.sFieldType == 'M') {
            let multipleData = "";
            pageCmp.$data.value.forEach(element => {
              multipleData += element + "|";
            });

            multipleData = multipleData.slice(0, -1);

            data.push({
              kIDGlobalFormFieldContent: pageCmp.getGlobalFormFieldContentRecord.length == 0 ? null : pageCmp.getGlobalFormFieldContentRecord[0].kIDGlobalFormFieldContent,
              kIDGlobalFormContent: this.getGlobalFormContentRecord ? this.getGlobalFormContentRecord.kIDGlobalFormContent : null,
              kIDGlobalForm: this.kIDGlobalForm,
              kIDGlobalFormField: element.kIDGlobalFormField,
              sFieldName: element.sFieldName,
              sFieldQuestion: element.sFieldQuestion,
              sFieldType: element.sFieldType,
              sFieldResponseContent: multipleData,
              sFieldCommentary: pageCmp.$data.commentary,
              vFieldPhoto1FilePath: null,
              vFieldPhoto2FilePath: null,
              vFieldSignatureFilePath: null
            })
          }
          else if (pageCmp && pageCmp.$data.value) {
            data.push({
              kIDGlobalFormFieldContent: pageCmp.getGlobalFormFieldContentRecord.length == 0 ? null : pageCmp.getGlobalFormFieldContentRecord[0].kIDGlobalFormFieldContent,
              kIDGlobalFormContent: this.getGlobalFormContentRecord ? this.getGlobalFormContentRecord.kIDGlobalFormContent : null,
              kIDGlobalForm: this.kIDGlobalForm,
              kIDGlobalFormField: element.kIDGlobalFormField,
              sFieldName: element.sFieldName,
              sFieldQuestion: element.sFieldQuestion,
              sFieldType: element.sFieldType,
              sFieldResponseContent: pageCmp.$data.value,
              sFieldCommentary: pageCmp.$data.commentary,
              vFieldPhoto1FilePath: null,
              vFieldPhoto2FilePath: null,
              vFieldSignatureFilePath: null
            })
          }
        });
      }
      
      // sauvegarde des datas
      const payload = {
        kIDGlobalFormContent: this.getGlobalFormContentRecord ? this.getGlobalFormContentRecord.kIDGlobalFormContent : null,
        kIDGlobalForm: this.kIDGlobalForm,
        kIDUser: this.getUser.kIDUser,
        kIDAssemblyCenter: this.data.kIDAssemblyCenter,
        kIDFolder: this.data.kIDFolder,
        iPageStep: this.getStep(iFieldPage),
        items: data,
        finish: finish
      }
      
      this.$emit('save', payload);

      return true;
    },
    next: function() {
      if (this.step < this.pages.length) {
        this.step = this.step + 1;
      }

      setTimeout(() => { 
        this.cleanMask();
      }, 200)
    },
    previous: function() {
      if (this.step > 1) {
        this.step = this.step - 1;
      }
    },
    showServerError: function() {
      this.serverError = true;
    },
    cleanMask: function() {
      this.loading = false;
      this.$store.commit('app/unmask'); 
    },
    generate: function() {
      this.$store.commit('app/mask');
      this.getRecords.forEach(record => {
        const page = this.pages.find(el => el.iFieldPage == record.iFieldPage);

        if (page) {
          page.addField(record);
        }
        else {
          const page = new PageDTO(record);
          page.addField(record);
          this.pages.push(page);
        }
      });

      this.pages.sort((a,b) => { return parseInt(a.iFieldPage) - parseInt(b.iFieldPage) });

      // Il faut que le step change après le render du step content, d'ou le timeout
      setTimeout(() => {
        this.step = parseInt(this.getStep(1));
        
        if (this.step < this.pages.length) {
          this.step = this.step != 1 ? this.step + 1 : this.step;
        }

        this.$store.commit('app/unmask');
      }, 1000)
    }
  },

  mounted() {
    this.generate();
  }
}
</script>
<style>
  .no-transition { transition: none !important; }
  .isOk > .v-stepper__step__step {
    background-color:#87E990 !important;
    border-color: #87E990 !important;
  }
</style>