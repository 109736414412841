<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="d-flex flex-column" >
      <v-toolbar
        dark
        color="red"
        style="flex: 0;"
      >
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Formulaire d'autocontrôle</v-toolbar-title>
      </v-toolbar>
      <GlobalForm ref="globalform" style="flex: 1;" kIDGlobalForm="1" :kIDData="folder ? folder.kIDFolder : null" :dialog="dialog" :reset="reset" :data="folder" @save="save"></GlobalForm>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import GlobalForm from '../../../components/GlobalForm/index.vue';
export default {
  name: "CarrossagePopUp",
  components: { GlobalForm },
  props: {
    dialog: {
      default: false
    },
    folder: {
      type: Object,
      default: () => ({
        kIDFolder: null
      })
    }
  },
  data: function () {
    return {
      reset: null
    };
  },
  methods: {
    save: function (payload) {
      this.$store.dispatch('globalFormContent/save', payload).then((res) => {
        if (res && res.response && res.response.T0115GlobalFormContent && res.response.T0115GlobalFormContent.Item && res.response.T0115GlobalFormContent.Item.iPageStep) {
          const iPageStep = res.response.T0115GlobalFormContent.Item.iPageStep;
          const kIDFolder = this.folder.kIDFolder;

          this.$store.commit('assemblyFolders/setPageStep', { kIDFolder, iPageStep});
        }
        if (payload.finish) {
          this.$refs["globalform"].cleanMask();
          
          this.close();
          
          Vue.notify({
            group: 'app',
            type: 'success',
            title: 'Formulaire d\'autocontrôle',
            text: 'Le formulaire d\'autocontrôle est terminé.'
          });
        }
        else {
          this.$refs["globalform"].next();
        }
      }).catch(() => {
        this.$refs["globalform"].showServerError();
        this.$refs["globalform"].cleanMask();
      })
    },
    close: function () {
      this.reset = new Date().getTime();
      this.$emit("close");
    }
  },
}
</script>