<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="d-flex flex-column" >
      <v-toolbar
        dark
        color="red"
        style="flex: 0;"
      >
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Évènements</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ selectedFolderData ? (selectedFolderData.sContractorTitle + ' - ' + selectedFolderData.sProjectOrderLine) : '' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>Requis : {{ getTitle() }}</v-toolbar-title>
      </v-toolbar>
      <div class="px-4 py-2">
        <v-row style="display: grid; overflow: hidden; grid-template-columns: repeat(2, 1fr); grid-auto-rows: 1fr; grid-column-gap: 5px;">
          <v-col style="height: 45vh; max-height: 45vh;">
            <v-card style="height: 100%">
              <v-subheader>Avaries à traiter ({{ unfinishedAvariesCount }})</v-subheader>
              <v-list subheader style="overflow: auto; height: 80%">
                <div v-for="item in unfinishedAvaries" :key="item.kIDEvent">
                  <v-card class="ma-4" @click="openUpdate(item)">
                    <div class="d-flex">
                      <v-col cols="10" class="center-y pa-0">
                        <v-card-title style="font-size: 15px;">{{ item.sEventStatusTitle }} {{ item.sAvarieEntityType }} {{ item.sAvarieEntitySerialNumber }}</v-card-title>
                        <v-card-subtitle>{{ item.sDamageComment }}</v-card-subtitle>
                      </v-col>
                      <v-col cols="2" class="center-y pa-0">
                        <v-icon color="red">mdi-timer-sand</v-icon>
                      </v-col>
                    </div>
                  </v-card>
                </div>
              </v-list>
            </v-card>
          </v-col>
          <v-col style="height: 45vh; max-height: 45vh;">
            <v-card style="height: 100%">
              <v-subheader>Consignes à traiter ({{ unfinishedConsignesCount }})</v-subheader>
              <v-list subheader style="overflow: auto; height: 80%">
                <div v-for="item in unfinishedConsignes" :key="item.kIDEvent">
                  <v-card class="ma-4" @click="openUpdate(item)">
                    <div class="d-flex">
                      <v-col cols="10" class="center-y pa-0">
                        <v-card-title style="font-size: 15px;">{{ item.sConsigneQuestionCode  }}</v-card-title>
                        <v-card-subtitle>{{ item.sConsigneQuestion }} : {{ item.sConsigneAnswer }}</v-card-subtitle>
                      </v-col>
                      <v-col cols="2" class="center-y pa-0">
                        <v-icon color="red">mdi-timer-sand</v-icon>
                      </v-col>
                    </div>
                  </v-card>
                </div>
              </v-list>
            </v-card>
          </v-col>
          <v-col style="height: 45vh; max-height: 45vh;">
            <v-card style="height: 100%">
              <v-subheader>Avaries traitées ({{ finishedAvariesCount }})</v-subheader>
              <v-list subheader style="overflow: auto; height: 80%">
                <div v-for="item in finishedAvaries" :key="item.kIDEvent">
                  <v-card class="ma-4" @click="openUpdate(item)">
                    <div class="d-flex">
                      <v-col cols="10" class="center-y pa-0">
                        <v-card-title style="font-size: 15px;">{{ item.sEventStatusTitle }} {{ item.sAvarieEntityType }} {{ item.sAvarieEntitySerialNumber }}</v-card-title>
                        <v-card-subtitle>{{ item.sDamageComment }}</v-card-subtitle>
                      </v-col>
                      <v-col cols="2" class="center-y pa-0">
                        <v-icon color="green">mdi-check</v-icon>
                      </v-col>
                    </div>
                  </v-card>
                </div>
              </v-list>
            </v-card>
          </v-col>
          <v-col style="height: 45vh; max-height: 45vh;">
            <v-card style="height: 100%">
              <v-subheader>Consignes traitées ({{ finishedConsignesCount }})</v-subheader>
              <v-list subheader style="overflow: auto; height: 80%">

                <div v-for="item in finishedConsignes" :key="item.kIDEvent">
                  <v-card class="ma-4" @click="openUpdate(item)">
                    <div class="d-flex">
                      <v-col cols="10" class="center-y pa-0">
                        <v-card-title style="font-size: 15px;">{{ item.sConsigneQuestionCode  }}</v-card-title>
                        <v-card-subtitle>{{ item.sConsigneQuestion }} : {{ item.sConsigneAnswer }}</v-card-subtitle>
                      </v-col>
                      <v-col cols="2" class="center-y pa-0">
                        <v-icon color="green">mdi-check</v-icon>
                      </v-col>
                    </div>
                  </v-card>
                </div>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <UpdateEventPopUp :selectedFolderData="selectedFolderData" :selectedEventData="selectedEvent" :dialog="updatePopUp" @updateData="refreshKey++" @close="updatePopUp = false" />
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import UpdateEventPopUp from './UpdateEventPopUp.vue'

export default {
  name: "EventPopUpCmp",
  components: { UpdateEventPopUp },
  props: {
    dialog: {
      default: false
    },
    selectedFolderData: {
      type: Object,
      default: () => ({
        kIDFolder: null
      })
    }
  },
  computed: {
    ...mapGetters({
      avarieRecords: 'avaries/getRecords',
      consigneRecords: 'consignes/getRecords'
    }),
    avaries() {
      this.refreshKey;

      if (this.selectedFolderData) {
        let avaries = this.avarieRecords.filter(e => e.sFolderOrder + '-' + e.sFolderOrderLine == this.selectedFolderData.sProjectOrderLine);
        avaries = avaries ? Array.isArray(avaries) ? avaries : [avaries] : [];

        return avaries;
      }

      return [];
    },
    consignes() {
      this.refreshKey;

      if (this.selectedFolderData) {
        let consignes = this.consigneRecords.filter(e => e.sFolderOrder + '-' + e.sFolderOrderLine == this.selectedFolderData.sProjectOrderLine);
        consignes = consignes ? Array.isArray(consignes) ? consignes : [consignes] : [];

        return consignes;
      }

      return [];
    },
    unfinishedAvaries() {
      this.refreshKey;

      let unfinishedAvaries = this.avaries ? this.avaries.filter(e => !e.kIDWFStatus.toString().endsWith('99')) : [];
      unfinishedAvaries = unfinishedAvaries ? Array.isArray(unfinishedAvaries) ? unfinishedAvaries : [unfinishedAvaries] : [];

      return unfinishedAvaries;
    },
    unfinishedAvariesCount() {
      this.refreshKey;

      return (this.unfinishedAvaries.length);
    },
    finishedAvaries() {
      this.refreshKey;

      let finishedAvaries = this.avaries ? this.avaries.filter(e => e.kIDWFStatus.toString().endsWith('99')) : [];
      finishedAvaries = finishedAvaries ? Array.isArray(finishedAvaries) ? finishedAvaries : [finishedAvaries] : [];

      return finishedAvaries;
    },
    finishedAvariesCount() {
      this.refreshKey;

      return (this.finishedAvaries.length);
    },
    unfinishedConsignes() {
      this.refreshKey;

      let unfinishedConsignes = this.consignes ? this.consignes.filter(e => !e.kIDWFStatus.toString().endsWith('99')) : [];
      unfinishedConsignes = unfinishedConsignes ? Array.isArray(unfinishedConsignes) ? unfinishedConsignes : [unfinishedConsignes] : [];

      return unfinishedConsignes;
    },
    unfinishedConsignesCount() {
      this.refreshKey;

      return (this.unfinishedConsignes.length);
    },
    finishedConsignes() {
      this.refreshKey;

      let finishedConsignes = this.consignes ? this.consignes.filter(e => e.kIDWFStatus.toString().endsWith('99')) : [];
      finishedConsignes = finishedConsignes ? Array.isArray(finishedConsignes) ? finishedConsignes : [finishedConsignes] : [];

      return finishedConsignes;
    },
    finishedConsignesCount() {
      this.refreshKey;

      return (this.finishedConsignes.length);
    },
    finishedEventCount() {
      this.refreshKey;

      return (this.finishedAvaries.length + this.finishedConsignes.length);
    },
    eventCount() {
      this.refreshKey;

      return (this.avaries.length + this.consignes.length);
    }
  },
  data: function () {
    return {
      selectedEvent: null,
      updatePopUp: false,
      refreshKey: 0
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    getTitle: function() {
      return this.finishedEventCount + ' / ' + this.eventCount
    },
    openUpdate: function(event) {
      this.updatePopUp = true;
      this.selectedEvent = event;
    }
  },
}
</script>