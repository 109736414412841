<template>
  <div>
    <v-data-table
      :height="getTableHeight"
      fixed-header
      :headers="headers"
      :items="getRecords"
      :item-class="getOddBackground"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :page.sync="page"
      item-key="kIDProject"
      no-data-text="Aucun complément disponible"
      show-expand
      class="elevation-1"
      disable-filtering
      disable-sort
      @click:row="onRowClicked"
      @page-count="onPageCountChange"
    >
      <template v-slot:item.sProjectOrder="{ item }">
        {{ item.sProjectOrder }} / {{ item.sProjectLine }}
      </template>
      <template v-slot:item.dProjectDate="{ item }">
        <CustomDate :date="item.dProjectDate" />
      </template>
      <template v-slot:item.kIDContractor="{ item }">
        <CustomChip color="#1D2A31"
          :text="typeof item.kIDContractor == 'object' ? item.kIDContractor.sAnybodyTitle : item.kIDContractor" 
        />
      </template>
      <template v-slot:item.kIDInvoicedClient="{ item }">
        {{ typeof item.kIDInvoicedClient == "object" ? item.kIDInvoicedClient.sAnybodyTitle : item.kIDInvoicedClient }}
      </template>
      <template v-slot:item.kIDBrand="{ item }">
        {{ typeof item.kIDBrand == "object" ? item.kIDBrand.sBrandTitle : item.kIDBrand }}
      </template>
      <template v-slot:item.kIDWFStatus="{ item }">
        <Fa :icon="item.sWFGlyphSymbol" :color="item.sWFGlyphColor" size="fa-2x" />
      </template>
      <template v-slot:item.sOptionCountExpected="{ item }">
        <CustomChip 
          :color="item.options.length >= item.options[0].sOptionCountExpected ? 'green' : 'orange'"
          :icon="item.options.length >= item.options[0].sOptionCountExpected ? 'mdi-check-circle-outline' : 'mdi-timer-sand-complete'"
          :text="item.options.length + '/' + item.options[0].sOptionCountExpected"
        />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          <v-container class="mx-0" style="max-width: unset;">
            <v-row>
              <v-col v-for="option in item.options" :key="option.kIDOption" xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                <v-card>
                  <v-card-title>{{ option.sOptionQuestionCode  }}</v-card-title>
                  <v-card-subtitle>{{ option.sOptionQuestionLabel }} : {{ option.sOptionAnswerLabel }}</v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

import CustomDate from "@/components/DataGrid/CustomDate";
import CustomChip from "@/components/DataGrid/CustomChip";
import Fa from "@/components/Fa";

import DataGridMixin from '@/components/DataGrid/DataGridMixin';

export default {
  name: 'Complements',

  mixins: [DataGridMixin],

  components: { CustomDate, CustomChip, Fa },

  data: function() {
    return {
      headers: [
        { text: 'N° commande/ligne', value: 'sProjectOrder', },
        { text: 'Date de la commande', value: 'dProjectDate' },
        { text: "Donneur d'ordre", value: 'kIDContractor' },
        { text: "Client facturé", value: 'kIDInvoicedClient' },
        { text: 'Marque', value: 'kIDBrand' },
        { text: 'Statut', value: 'kIDWFStatus' },
        { text: '', value: 'sOptionCountExpected' },
      ]
    }
  },
  
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      complement: 'complement/getRecords',
      assemblyOrders: 'assemblyOrders/getRecords'
    }),
    records() {
      const data = [];

      this.complement.forEach(element => {
        // recherche de l'assembly qui correspond
        const assemblyOrders = this.assemblyOrders.find(el => `${element.sOptionProjectOrder} / ${element.sOptionProjectOrderLine}` == `${el.sProjectOrder} / ${el.sProjectLine}`);

        if (assemblyOrders) {
          const exist = data.find(el => `${el.sProjectLine} / ${el.sProjectOrder}` == element.sProjectLineProjectNumber);
          
          if (exist) {
            exist.options.push(element);
          }
          else {
            if(assemblyOrders) {
              data.push({
                ...assemblyOrders,
                options: [
                  element
                ]
              })
            }
          }
        }
      });

      return data;
    }
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
    EventBus.$on('headerPaginationChanged', this.onHeaderValueChanged);
  },

  methods: {
    getOddBackground: function(item) {
      return this.getRecords.findIndex(el => el.kIDOption == item.kIDOption) % 2 ? "nth-child-2n" : "";
    }
  }
}
</script>
<style>
  .v-application .text-overline {
    line-height: 1rem !important;
  }

  .v-data-table-header {
    background-color: var(--v-red-base);
  }
  .v-data-table-header > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--v-red-base) !important;
  }
  .v-data-table-header > tr > th {
    color: white !important;
  }

  .v-data-table.v-data-table--fixed-header thead th {
    background-color: var(--v-red-base) !important;
  }

  /** background une ligne sur deux */
  .nth-child-2n {
    background-color: var(--v-secondary-lighten2) !important;
  }
  
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.nth-child-2n) {
    background-color: white !important;
  }  
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button.white--text {
    color: white !important;
  }
</style>