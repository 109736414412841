<template>
  <v-card class="mx-auto" outlined>
    <v-btn
      v-if="collapse"
      style="position: absolute; right: 0; bottom: 0; z-index: 3;"
      class="mt-2 mx-2"
      icon
      @click="show = !show"
    >
      <v-icon>{{ show ? 'mdi-dots-horizontal' : 'mdi-dots-horizontal' }}</v-icon>
    </v-btn>

    <slot name="content"></slot>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <slot name="collapsed"></slot>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'CollapsedCard',

  props: {
    collapse: {
      type: Boolean,
      default: true
    },
  },

  data: function() {
    return {
      show: false
    }
  },

  methods: {

  }
}
</script>