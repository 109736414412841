/**
 * C'est dans ce fichier que sont déclarer les machines à états/store VueX
 * Pour chaque store, il faut importer son modules et le déclarer dans les modules de l'export
 */
import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import stats from './modules/stats'
import articles from './modules/articles'
import assemblyOrders from './modules/assemblyOrders'
import assemblyFolders from './modules/assemblyFolders'
import chassis from './modules/chassis'
import brands from './modules/brands'
import model from './modules/model'
import thirdParties from './modules/thirdParties'
import layout from './modules/layout'
import layoutParent from './modules/layoutParent'
import tipper from './modules/tipper'
import locker from './modules/locker'
import complement from './modules/complement'
import avaries from './modules/avaries'
import consignes from './modules/consignes'
import globalFormField from './modules/globalFormField'
import globalFormContent from './modules/globalFormContent'
import globalFormFieldContent from './modules/globalFormFieldContent'
import folderMedia from './modules/folderMedia'
import tabSettings from './modules/tabSettings'
import download from './modules/download'
import upload from './modules/upload'

/**
 * Il faut savoir que lors de reload, les datas des stores sont perdus
 * Cette lib permet le stockage des stores dans IDB (et oui encore lui)
 * Cela ce fait par une double clé dans le localstorage puis la gestion dans IDB
 * Néanmoins c'est pas un outil automatique, il faut lui préciser pour chaque store, ce qu'on veux garder.
 * L'aspect pratique c'est que c'est très fast et eco
 */
import createPersistedState from "vuex-persist-indexeddb";

// Permet de savoir quand l'application a fini de charger IDB
localStorage.setItem('idb_ready', false);

const filesState = createPersistedState({
  paths: [
    'articles.records',
    'assemblyOrders.records',
    'assemblyFolders.records',
    'chassis.records',
    'brands.records',
    'model.records',
    'thirdParties.records',
    'layout.records',
    'layoutParent.records',
    'tipper.records',
    'locker.records',
    'complement.records',
    'avaries.records',
    'consignes.records',
    'globalFormField.records',
    'stats.records',
    'tabSettings.records',
  ],
  overwrite: false,
  rehydrated : () => {
    // Permet de savoir quand l'application a fini de charger IDB
    localStorage.setItem('idb_ready', true);
  }
});

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    user,
    articles,
    assemblyOrders,
    assemblyFolders,
    chassis,
    brands,
    model,
    thirdParties,
    layout,
    layoutParent,
    tipper,
    locker,
    complement,
    avaries,
    consignes,
    globalFormField,
    globalFormContent,
    globalFormFieldContent,
    folderMedia,
    tabSettings,
    stats,
    download,
    upload
  },
  plugins: [filesState]
});