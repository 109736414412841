<template>
  <v-card @click="onClick" class="acard card  acard-stats elevation-10 mb-4 mb-xl-0">
      <div class="acard-body card-body center-x">
        <div class="arow row">
          <div class="col-auto">
            <div class="aicon aicon-shape abg-danger atext-white arounded-circle ashadow">
              <v-icon dark>mdi-{{ icon }}</v-icon>
            </div>
          </div>
          <div class="acol col">
            <span class="font-weight-bold mb-0 text-h6" style="font-weight: initial !important;"  :style="valueStyle">{{ value || "0" }}</span> <span>{{ suffixe }}</span>
            <div class="acard-title mb-0 text-md-body-2">{{ title }}</div>
            <div v-if="subtitle != ''" class="acard-title mb-0 text-md-body-2" style="font-size: 9px !important; line-height: 12px;">{{ subtitle }}</div>
          </div>
        </div>
      </div>
  </v-card>
</template>

<script>
export default {
  name: 'StatsCard',

  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    route: {
      type: String,
      default: ""
    },
    suffixe: {
      type: String,
      default: ""
    },
    valueStyle: {
      type: String,
      default: ""
    }
  },

  methods: {
    onClick: function() {
      if (this.route != "") {
        this.$router.push(this.route);
      }
    }
  }
}
</script>
<style>
.acard {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: .375rem;
  background-color: #fff;
  background-clip: border-box;
}

.acard-body {
  padding: 1.5rem;
  flex: 1 1 auto;
}

.acard-title {
  margin-bottom: 1.25rem;
}

.acard-stats .acard-body {
  padding: 1rem 1.5rem;
}
.arow {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
}
.acol {
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
}
.atext-muted {
  color: #8898aa !important;
}

.atext-uppercase {
  text-transform: uppercase !important;
}

.aicon-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.aicon {
  width: 3rem;
  height: 3rem;
}
.ashadow {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
}
.arounded-circle {
  border-radius: 50% !important;
}
.abg-danger {
  background-color: #7879C2 !important;
}

.atext-white {
  color: #fff!important;
}
</style>