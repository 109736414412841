<template>
  <v-card
    v-if="show"
    class="mx-auto floatBR"
    color="cyan"
    dark
    max-width="400"
  >
    <v-app-bar flat color="rgba(0, 0, 0, 0)">
      <v-icon left>mdi-information-variant</v-icon>

      <v-toolbar-title class="text-h6 white--text pl-0">
        {{ title }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn color="white" icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    
    <slot name="content"></slot>
  </v-card>
</template>

<script>
export default {
  name: 'CustomFieldPopin',

  props: {
    title: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: true
    },
  },

  data: function() {
    return {
      
    }
  },

  methods: {

  }
}
</script>
<style>
  .floatBR {
    position: fixed;
    bottom: 0;
    right: 120px;
  }
</style>