<template>
  <div class="h-100">
    <v-row class="h-100 ma-0">
      <v-col class="h-100" cols="3" xl="3" lg="3" md="3" sm="2" xs="2" style="  overflow:auto">
        <v-card class="d-flex flex-column h-100">
          <v-card-title class="ml-4 text-subtitle-2" style="align-items: end;">
            <v-icon class="mr-4">mdi-file-document-edit-outline</v-icon><span>Dossier de montage</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-iterator
            style="flex:20; max-height: 73vh; overflow: auto;"
            :items="getRecords"
            item-key="kIDFolder"
            hide-default-footer
          >
            <template v-slot:default="{items}">
              <v-list subheader>
                <v-list-item-group mandatory v-model="selectedFolder">
                  <v-list-item
                    v-for="item in items"
                    :key="item.kIDFolder"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="d-flex text-subtitle-2">{{ item.sProjectOrderLine }} <v-spacer>&nbsp;</v-spacer> {{ item.sBrandTitle }} {{ item.sModelTitle }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.sContractorTitle }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ item.sChassisSerialNumber }}</v-list-item-subtitle>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
      <v-col v-if="selectedFolder != null" class="h-100 d-flex flex-column" cols="9" xl="9" lg="9" md="9" sm="10" xs="10">
        <div style="min-height: 180px;">
          <v-row class="py-2">
            <v-col cols="4" xl="4" lg="4" md="4" sm="4" xs="4">
              <Field title="Châssis" :data="selectedFolderData ? getChassisTitle(selectedFolderData.kIDChassis) : '-'" />
              <Field title="Commande" :data="`${selectedFolderData ? selectedFolderData.sProjectOrderLine : '-'}`" />
              <Field title="Référence interne centre" :data="selectedFolderData ? selectedFolderData.sFolderCustomerReference : '-'" />
              <Field title="Couleur" :data="selectedFolderData ? getChassisData(selectedFolderData.kIDChassis, 'sChassisColor') : '-'" />
            </v-col>
            <v-col cols="4" xl="4" lg="4" md="4" sm="4" xs="4">
              <Field title="Empattement" :data="selectedFolderData ? getChassisData(selectedFolderData.kIDChassis, 'sChassisWheelBase') : '-'" />
              <Field title="PTAC" :data="selectedFolderData ? selectedFolderData.sPTAC : '-'" />
              <Field title="Type de cabine" :data="selectedFolderData ? getChassisData(selectedFolderData.kIDChassis, 'sChassisCabin') : '-'" />
            </v-col>
            <v-col cols="4" xl="4" lg="4" md="4" sm="4" xs="4">
              <Field title="N° Série Châssis" :data="selectedFolderData ? getChassisData(selectedFolderData.kIDChassis, 'sChassisNumber') : '-'" />
              <Field title="N° Série Benne" :data="selectedFolderData ? getTipperData(selectedFolderData.kIDTipper, 'sSupplySerialNumber') : '-'" />
              <Field title="N° Série Coffre" :data="selectedFolderData ? getLockerData(selectedFolderData.kIDLocker, 'sSupplySerialNumber') : '-'" />
            </v-col>
          </v-row>
          <v-row class="mt-0 mb-0">
            <v-col cols="4">
              <v-row>
                <v-col cols="12" class="center-x">
                  <v-chip
                    color="indigo"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                    {{ selectedFolderData ? selectedFolderData.sContractorTitle : '-' }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col cols="12" class="center-x">
                  <v-chip
                    color="green"
                    text-color="white"
                  >
                    <v-avatar
                      left
                      class="green darken-4"
                    >
                      {{ selectedFolderData ? getComplementsLength(selectedFolderData) : '-' }}
                    </v-avatar>
                    Compléments
                  </v-chip>
                  <v-chip
                    @click="detailspopup = true"
                    class="ml-2"
                    color="green"
                    text-color="white"
                  >
                    <v-avatar left>
                    <v-icon>mdi-information</v-icon>
                    </v-avatar>
                    Détails
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="6">
              <v-chip
                color="teal"
                text-color="white"
              >
                <v-avatar left>
                  <v-icon>mdi-chat-alert</v-icon>
                </v-avatar>
                Commentaire du dossier
              </v-chip>
              <v-card-subtitle class="pa-0 pt-2"><b>{{ selectedFolderData ? selectedFolderData.sFolderComment : '-' }}</b></v-card-subtitle>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
          <v-row class="pt-4 pb-2">
            <v-col class="center-y" sm="4" md="4" lg="4" xl="4" col="4">
              <div class="center-y" style="height: 49%;">
                <Field title="Client facturé :" :data="selectedFolderData ? selectedFolderData.sInvoicedClientTitle : '-'" />
                <Field title="Destinaire :" :data="selectedFolderData ? selectedFolderData.sRecipientTitle : '-'" />
              </div>
              <v-divider></v-divider>
              <div class="center-y" style="height: 49%;">
                <Field title="Code affaire :" :data="selectedFolderData ? selectedFolderData.sProjectDealCode : '-'" />
                <Field title="Code carrossage :" :data="selectedFolderData ? selectedFolderData.sProjectAssemblyCode : '-'" />
              </div>
            </v-col>
            <v-col class="center-y" sm="5" md="5" lg="5" xl="5" col="5">
              <div>
                <v-textarea
                  v-model="sFolderShipmentComment"
                  outlined
                  readonly
                  @click="onClickShipmentComment"
                >
                  <template v-slot:label>
                    <div>
                      Commentaire d'expédition
                    </div>
                  </template>
                </v-textarea>
              </div>
              <div>
                <v-textarea
                  v-model="sFolderShipmentAddress"
                  outlined
                  hide-details
                  readonly
                  @click="onClickShipmentAddress"
                >
                  <template v-slot:label>
                    <div>
                      Complément d'adresse d'expédition
                    </div>
                  </template>
                </v-textarea>
              </div>
            </v-col>
            <v-col class="center-y pl-1 pr-4" sm="3" md="3" lg="3" xl="3" col="3">
              <v-btn class="elevation-10" style="height: 50%" color="success" @click="confirm">
                <span style="font-size: large">Confirmer <br/>l'expédition <br/>du véhicule</span>
              </v-btn>
            </v-col>
          </v-row>
          <EditAFDetailsPopUp :selectedFolder="selectedFolderData" :dialog="detailspopup" @close="detailspopup = false;" />
      </v-col>
      <v-col v-else style="display: flex; flex-direction: column;" class="h-100 center-xy" cols="9" xl="9" lg="9" md="9" sm="10" xs="10">
        <img :src="folderImg" style="max-width: 80vh;" />
        <div class="text-h4 mt-4">Veuillez choisir un dossier pour continuer</div>
      </v-col>
    
      <v-dialog
        v-model="shipmentCommentPopup"
        transition="dialog-bottom-transition"
      >
        <template>
          <v-card class="px-4" style="position: absolute; top: 20px; max-width: 95vw; left: 30px;">
            <v-card-title class="pl-0">Commentaire d'expédition</v-card-title>
            <v-textarea
              v-model="sFolderShipmentComment"
              outlined
              hide-details
            >
            </v-textarea>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="shipmentCommentPopup = false">Fermer</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog
        v-model="shipmentAddressPopup"
        transition="dialog-bottom-transition"
      >
        <template>
          <v-card class="px-4" style="position: absolute; top: 20px; max-width: 95vw; left: 30px;">
            <v-card-title class="pl-0">Commentaire d'adresse d'expédition</v-card-title>
            <v-textarea
              v-model="sFolderShipmentAddress"
              outlined
              hide-details
            >
            </v-textarea>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="shipmentAddressPopup = false">Fermer</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog
        v-model="confirmPopup"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template>
          <v-card>
            <div>
              <p class="text-h5 text-center pt-6">Êtes-vous sûr de confirmer l'expédition du véhicule ?</p>
              <p class="text-body-1 text-center">Si vous confirmez, le véhicule sera noté comme expédié.</p>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded color="success" class="pr-2" large @click="validate()"><v-icon class="pl-2 pr-2">mdi-check</v-icon><span class="pr-2">Je confirme</span></v-btn>
              <v-btn rounded @click="confirmPopup = false" large><v-icon class="pr-2 pl-2">mdi-cancel</v-icon><span class="pr-2">Annuler</span></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

import FloatMixin from '@/mixins/FloatMixin';

import Field from '../AssemblyFolders/components/Field.vue'
import EditAFDetailsPopUp from '../AssemblyFolders/components/EditAFDetailsPopUp.vue';

import folderImg from '@/assets/images/folder.jpg';

export default {
  name: 'Expedition',

  mixins: [FloatMixin],

  components: { Field, EditAFDetailsPopUp },

  data: function() {
    return {
      search: null,
      selectedFolder: null,
      detailspopup: false,
      folderImg: folderImg,
      confirmPopup: false,
      shipmentCommentPopup: false,
      shipmentAddressPopup: false,
      sFolderShipmentComment: null,
      sFolderShipmentAddress: null
    }
  },

  watch: {
    getRecords: function() {
      this.selectFirst();
    },
    selectedFolder: function() {
      this.sFolderShipmentComment = null;
      this.sFolderShipmentAddress = null;
    }
  },
  
  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
      assemblyFoldersRecords: 'assemblyFolders/getRecords',
      complementRecords: 'complement/getRecords',
    }),
    getRecords() {
      let result = this.assemblyFoldersRecords.filter(el => el.kIDWFStatus == 1150920)

      // Gestion de la recherche texte first
      if (this.search) {
        const me = this;
        
        result = result.filter((el) => {
          if (el.search) {
            return el.search.toUpperCase().includes(me.search.toUpperCase());
          }
          else {
            return false;
          }
        });
      }

      return result;
    },
    selectedFolderData() {
      return this.getRecords[this.selectedFolder];
    },
    canValidate() {
      let validate = false;

      return validate;
    }
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
    this.selectFirst();
  },

  methods: {
    onClickShipmentComment: function(e) {
      e.stopPropagation();
      this.shipmentCommentPopup = this.shipmentCommentPopup ? false : true;
    },
    onClickShipmentAddress: function(e) {
      e.stopPropagation();
      this.shipmentAddressPopup = this.shipmentAddressPopup ? false : true;
    },
    onSearchValueChanged: function(value) {
      this.search = value;
    },

    selectFirst: function() {
      if (this.getRecords.length > 0 && this.selectedFolder == null) {
        this.selectedFolder = 0;
      }
    },

    getChassisTitle: function(chassis) {
      if (chassis) {
        return `${typeof chassis.kIDBrand == "object" ? chassis.kIDBrand.sBrandTitle : chassis.kIDBrand} ${typeof chassis.kIDModel == "object" ? chassis.kIDModel.sModelTitle : chassis.kIDModel}`;
      }

      return "";
    },

    getChassisData: function(data, key) {
      if (typeof data == 'object') {
        return data[key];
      }

      return '-'
    },

    getTipperData: function(data, key) {
      if (typeof data == 'object') {
        return data[key];
      }

      return '-'
    },

    getLockerData: function(data, key) {
      if (typeof data == 'object') {
        return data[key];
      }

      return '-'
    },
    
    getComplementsLength: function(ao) {
      if (ao && ao.sProjectOrder && ao.sProjectLine) {
        const record = this.complementRecords.filter(el => el.sOptionProjectOrder == ao.sProjectOrder && el.sOptionProjectOrderLine == ao.sProjectLine);
        return record.length;
      }
      else {
        return '?'
      }
    },

    getFilterPopUpTitle: function() {
      return this.selectedFolderData ? ` - ${this.selectedFolderData.sBrandTitle} - ${this.selectedFolderData.sModelTitle} - Commun` : ``;
    },

    confirm: function() {
      this.confirmPopup = true;
    },

    validate: function() {
      this.$store.commit('app/mask');
      this.confirmPopup = false;
      
      this.$store.dispatch("assemblyFolders/expedition", {
        kIDUser: this.getUser.kIDUser, 
        kIDFolder: this.selectedFolderData.kIDFolder,
        sFolderShipmentComment: this.sFolderShipmentComment,
        sFolderShipmentAddress: this.sFolderShipmentAddress,
      }).then(() => {
        this.$store.commit('app/setMessage', {
          title: "Confirmation de l'expédition du véhicule.",
          message: "L'expédition du véhicule est confirmée."
        });
        this.sFolderShipmentComment = null;
        this.sFolderShipmentAddress = null;

        this.selectedFolder = null;

        this.$store.commit('app/unmask');
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.$store.commit('app/unmask');
      });
    }
  }
}
</script>
<style>
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.nth-child-2n) {
    background-color: transparent !important;
  }

  .cmp_btn > .v-btn__content {
    flex-direction: column;
  }
</style>