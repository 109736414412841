import http from "@/http-common-json";

class AvariesServices {
  getList(kIDUser, xmlFiltersSorts, page, start, limit) {
    const url = 'getJList';
    
    const formData = new URLSearchParams();

    formData.append('sName', `wft_T0115Event_Avaries(${kIDUser}, '')`);
    formData.append('xmlFiltersSorts', xmlFiltersSorts);
    formData.append('page', page);
    formData.append('start', start);
    formData.append('limit', limit);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.Records) {
        return jsonData.Records;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
  
  create(kIDUser, kIDApplication, kIDCompany, primaryKey, isBckff, kIDComponent, viewName, xmlData) {
    const url = 'setJEvolution';
    
    const formData = new URLSearchParams();

    formData.append('kIDUser', kIDUser);
    formData.append('kIDApplication', kIDApplication);
    formData.append('kIDCompany', kIDCompany);
    formData.append('primaryKey', primaryKey);
    formData.append('isBckff', isBckff);
    formData.append('kIDComponent', kIDComponent);
    formData.append('viewName', viewName);
    formData.append('xmlData', xmlData);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.T0115Event) {
        return jsonData.T0115Event;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  fixFromAO(kIDUser, kIDApplication, kIDCompany, primaryKey, isBckff, kIDComponent, viewName, xmlData) {
    const url = 'setJEvolution';
    
    const formData = new URLSearchParams();

    formData.append('kIDUser', kIDUser);
    formData.append('kIDApplication', kIDApplication);
    formData.append('kIDCompany', kIDCompany);
    formData.append('primaryKey', primaryKey);
    formData.append('isBckff', isBckff);
    formData.append('kIDComponent', kIDComponent);
    formData.append('viewName', viewName);
    formData.append('xmlData', xmlData);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.T0115Event) {
        const obj = {
          T0115Event: jsonData.T0115Event
        }

        if (jsonData.T0115Chassis) {
          obj.T0115Chassis = jsonData.T0115Chassis
        }

        if (jsonData.T0115Locker) {
          obj.T0115Locker = jsonData.T0115Locker
        }

        if (jsonData.T0115Tipper) {
          obj.T0115Tipper = jsonData.T0115Tipper
        }

        return obj;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new AvariesServices();