<template>
  <div>
    <v-data-table
      :height="getTableHeight"
      fixed-header
      :headers="headers"
      :items="getRecords"
      :item-class="getOddBackground"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :page.sync="page"
      item-key="kIDFolder"
      no-data-text="Aucun dossier de montage disponible"
      show-expand
      class="elevation-1"
      disable-filtering
      disable-sort
      @click:row="onRowClicked"
      @page-count="onPageCountChange"
    >
      <template v-slot:item.sProjectOrder="{ item }">
        {{ item.sProjectOrder }} / {{ item.sProjectLine }}
      </template>
      <template v-slot:item.kIDChassis="{ item }">
        {{ typeof item.kIDChassis == 'object' ? typeof item.kIDChassis.kIDBrand == 'object' ? item.kIDChassis.kIDBrand.sBrandTitle : '-' : '-' }}
      </template>
      <template v-slot:item.dAssemblyStartDate="{ item }">
        <CustomDate :date="item.dAssemblyStartDate" />
      </template>
      <template v-slot:item.dProjectExpectedAssemblyDate="{ item }">
        <b><CustomDate :date="item.dProjectExpectedAssemblyDate" /></b>
      </template>
      <template v-slot:item.kIDWFStatus="{ item }">
        <Fa :icon="item.sWFGlyphSymbol" :color="item.sWFGlyphColor" size="fa-2x" />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          <v-container class="mx-0" style="max-width: unset;">
            <v-row>
              <v-col xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                <CollapsedCard style="min-height: 180px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Commentaire :
                        </div>
                        <div>
                          {{ item.sFolderComment }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>  
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Référence client"
                          :data="item.sFolderCustomerReference"
                        />

                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Marque/Modèle du châssis"
                          :data="`${getChassisBrand(item)} ${getChassisModel(item)}`"
                        />

                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type de benne"
                          :data="getTipperData(item, 'sSupplyDumpType')"
                        />

                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Coffre"
                          :data="item.kIDLocker ? 'OUI' : 'NON'"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCard>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                <CollapsedCard style="min-height: 180px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Châssis
                        </div>

                        <CustomSubtitle 
                          title="Date de réception"
                          :data="getChassisData(item, 'dChassisReceptionDate') ? new Date(getChassisData(item, 'dChassisReceptionDate')).toLocaleDateString() : null"
                        />

                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Numéro de série"
                          :data="getChassisData(item, 'sChassisNumber')"
                        />

                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Marque/Modèle"
                          :data="`${getChassisBrand(item)} ${getChassisModel(item)}`"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Empattement"
                          :data="getChassisData(item, 'sChassisWheelBase')"
                        />

                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type de roues" 
                          :data="getChassisData(item, 'sChassisWheelType')"
                        />                   
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Cabine" 
                          :data="getChassisData(item, 'sChassisCabin')"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur châssis" 
                          :data="getReadabledFloat(getChassisData(item, 'fChassisWidth'))"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Porte à faux" 
                          :data="getChassisData(item, 'sChassisPaf')"
                        />
                      
                        <CustomSubtitle
                          cssClass="mt-2"
                          title="Coffre déplacement roue de secours" 
                          :data="getChassisData(item, 'sChassisBootWheel')"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Coffre coupe châssis" 
                          :data="getChassisData(item, 'sChassisBootChassis')"
                        />                    
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Benne déplacement roue de secours" 
                          :data="getChassisData(item, 'sChassisDumpWheel')"
                        />
                
                        <CustomSubtitle
                          cssClass="mt-2"
                          title="Poids maxi essieu avant" 
                          :data="getChassisData(item, 'sChassisMaxWeightFrontAxle')"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Poids maxi essieu arrière" 
                          :data="getChassisData(item, 'sChassisMaxWeightBackAxle')"
                        />
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Nombre de places avant" 
                          :data="getChassisData(item, 'sChassisNumberFrontSeat')"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Nombre de places arrière" 
                          :data="getChassisData(item, 'sChassisNumberBackSeat')"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCard>
              </v-col>
              
              <v-col xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                <CollapsedCard style="min-height: 180px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Benne
                        </div>

                        <CustomSubtitle 
                          title="Date de réception"
                          :data="getTipperData(item, 'dSupplyDeliveryDate') ? new Date(getTipperData(item, 'dSupplyDeliveryDate')).toLocaleDateString() : null"
                        />
                        
                        <CustomSubtitle
                          cssClass="mt-2"
                          title="Type de benne" 
                          :data="getTipperData(item, 'sSupplyDumpType')"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>  
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Longueur" 
                          :data="getReadabledFloat(getTipperData(item, 'fSupplyDumpLength'))"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur" 
                          :data="getReadabledFloat(getTipperData(item, 'fSupplyDumpWidth'))"
                        />                      
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Manquants bloquants" 
                          :data="getTipperData(item, 'sSupplyMandatoryMissing')"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Manquants non bloquants" 
                          :data="getTipperData(item, 'sSupplyUnMandatoryMissing')"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur Faux Châssis" 
                          :data="getReadabledFloat(getTipperData(item, 'fSupplyFalseChassisWidth'))"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Plateau Faux Châssis" 
                          :data="getTipperData(item, 'sSupplyPlateauFalseChassis')"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Finition" 
                          :data="getTipperData(item, 'sSupplyFinishing')"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Couleur commerciale" 
                          :data="getTipperData(item, 'sSupplyCommercialColor')"
                        />
                      
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur Châssis" 
                          :data="getReadabledFloat(getTipperData(item, 'fSupplyChassisWidth'))"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Cabine" 
                          :data="getTipperData(item, 'sSupplyCabin')"
                        />                      
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Empattement" 
                          :data="getTipperData(item, 'sSupplyWheelBase')"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type de roue" 
                          :data="getTipperData(item, 'sSupplyWheelType')"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Tonnage" 
                          :data="getTipperData(item, 'sSupplyTonnage')"
                        />
                
                        <CustomSubtitle
                          cssClass="mt-2"
                          title="Porte Arrière" 
                          :data="getTipperData(item, 'sSupplyBackDoor')"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Hauteur Porte" 
                          :data="getReadabledFloat(getTipperData(item, 'fSupplyDoorHeight'))"
                        />          
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Hauteur Ridelle" 
                          :data="getReadabledFloat(getTipperData(item, 'fSupplyWallHeight'))"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Matière Ridelle" 
                          :data="getTipperData(item, 'sSupplyWallMaterial')"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Matière Porte" 
                          :data="getTipperData(item, 'sSupplyDoorMaterial')"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type PE" 
                          :data="getTipperData(item, 'sSupplyPeType')"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Matière PE" 
                          :data="getTipperData(item, 'sSupplyPeMaterial')"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur Berce" 
                          :data="getReadabledFloat(getTipperData(item, 'fSupplyCradleWidth'))"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Attelage" 
                          :data="getTipperData(item, 'sSupplyHitch')"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCard>
              </v-col>

            </v-row>
          </v-container>
        </td>
    </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

import CustomDate from "@/components/DataGrid/CustomDate";
import Fa from "@/components/Fa";
import CollapsedCard from '@/components/DataGrid/ExpandedItem/CollapsedCard';
import CustomSubtitle from '@/components/DataGrid/ExpandedItem/CustomSubtitle';

import DataGridMixin from '@/components/DataGrid/DataGridMixin';

export default {
  name: 'AssemblyOrders',

  mixins: [DataGridMixin],

  components: { CustomDate, Fa, CollapsedCard, CustomSubtitle },

  data: function() {
    return {
      headers: [
        { text: 'N° commande/ligne', value: 'sProjectOrder', },
        { text: "Donneur d'ordre", value: 'sContractorTitle' },
        { text: "Référence client", value: 'sFolderCustomerReference' },
        { text: "Marque du châssis", value: 'kIDChassis' },
        { text: 'Montage prévu le', value: 'dProjectExpectedAssemblyDate' },
        { text: 'Statut', value: 'kIDWFStatus' },
        { text: 'Réceptionneur', value: 'sCreatorName' },
      ]
    }
  },
  
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      records: 'assemblyFolders/getRecords'
    }),
    getChassisData: function () {
      return function (record, field) {
        return typeof record.kIDChassis == 'object' ? record.kIDChassis[field] : ' - ';
      };
    },
    getChassisBrand: function() {
      return function (item) {
        return typeof item.kIDChassis == 'object' ? typeof item.kIDChassis.kIDBrand == 'object' ? item.kIDChassis.kIDBrand.sBrandTitle : '-' : '-'
      }
    },
    getChassisModel: function() {
      return function (item) {
        return typeof item.kIDChassis == 'object' ? typeof item.kIDChassis.kIDModel == 'object' ? item.kIDChassis.kIDModel.sModelTitle : '-' : '-'
      }
    },
    getLockerData: function () {
      return function (record, field) {
        return typeof record.kIDLocker == 'object' ? record.kIDLocker[field] : ' - ';
      };
    },
    getTipperData: function () {
      return function (record, field) {
        return typeof record.kIDTipper == 'object' ? record.kIDTipper[field] : ' - ';
      };
    }
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
    EventBus.$on('headerPaginationChanged', this.onHeaderValueChanged);
  },

  methods: {
    getOddBackground: function(item) {
      return this.getRecords.findIndex(el => el.kIDFolder == item.kIDFolder) % 2 ? "nth-child-2n" : "";
    }
  }
}
</script>
<style>
  .v-application .text-overline {
    line-height: 1rem !important;
  }

  .v-data-table-header {
    background-color: var(--v-red-base);
  }
  .v-data-table-header > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--v-red-base) !important;
  }
  .v-data-table-header > tr > th {
    color: white !important;
  }

  .v-data-table.v-data-table--fixed-header thead th {
    background-color: var(--v-red-base) !important;
  }

  /** background une ligne sur deux */
  .nth-child-2n {
    background-color: var(--v-secondary-lighten2) !important;
  }
  
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.nth-child-2n) {
    background-color: white !important;
  }  
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button.white--text {
    color: white !important;
  }
</style>