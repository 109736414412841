import ComplementsServices from '@/services/complementsServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }
}

// actions
const actions = {
  loadFromWS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, page, start, limit } = payload;

        commit('download/increaseCounter', null, { root: true });
        ComplementsServices.getList(
          //kIDUser,
          kIDUser,
          //xmlFiltersSorts,
          "",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          commit('setRecords', response.Item);
          
          // Il faut MAJ les stores qui utilise ce store comme data secondaire
          const list = [];

          list.forEach(store => {
            dispatch(store + "/setRelationship", "kIDOption", {root:true});
          });

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  setRecords(state, records) {
    const data = Array.isArray(records) ? records : typeof records == 'object' ? [records] : [];

    const _ = [];

    data.forEach(element => {
      // Ne pas enregister toutes les valeurs, ça éco quasiement 2Mo de data a stocker
      const __ = {
        kIDOption: element.kIDOption,
        sOptionQuestionCode: element.sOptionQuestionCode,
        sOptionQuestionLabel: element.sOptionQuestionLabel,
        sOptionAnswerLabel: element.sOptionAnswerLabel,
        sOptionProjectOrder: element.sOptionProjectOrder,
        sProjectLineProjectNumber:`${element.sOptionProjectOrderLine} / ${element.sOptionProjectOrder}`,
        sOptionProjectOrderLine: element.sOptionProjectOrderLine,
        sOptionCountExpected: element.sOptionCountExpected
      }

      _.push(__);
    });

    state.records = Object.freeze(_);
  },

  reset(state) {
    state.records = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}