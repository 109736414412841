<template>
  <div style="display: flex; justify-content: center;">
    <div>
      <v-img
        class="mx-4 my-4"
        src="@/assets/flags/fr.png"
        :max-width="50"
        @click="switchLang('fr_FR')"
      >
      </v-img>
      <div :style="'height: 2px; background-color: black; display:' + (current == 'fr_FR' ? 'block' : 'none')"></div>
    </div>
    <div>    
      <v-img
        class="mx-4 my-4"
        src="@/assets/flags/gb.png"
        :max-width="50"
        @click="switchLang('en_US')"
      >
      </v-img>
      <div :style="'height: 2px; background-color: black; display:' + (current == 'en_US' ? 'block' : 'none')"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "LanguageSwitcher",
  data: function() {
    return {
      current: localStorage.getItem('lang'),
      lang: [
        {
          title: "Français",
          logo: "fr.png",
          value: "fr_FR"
        },
        {
          title: "English",
          logo: "gb.png",
          value: "en_US"
        }
      ]
    }
  },
  methods: {
    switchLang: function(value) {
      const lang = this.lang.find(v => v.value == value);

      if (lang) {
        localStorage.setItem('lang', lang.value);
        window.location.reload();
      }
    }
  },
};
</script>