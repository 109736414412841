import AssemblyOrdersServices from '@/services/assemblyOrdersServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }
}

// actions
const actions = {
  loadFromWS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, page, start, limit } = payload;

        commit('download/increaseCounter', null, { root: true });
        AssemblyOrdersServices.getList(
          //kIDUser,
          kIDUser,
          //xmlFiltersSorts,
          "<xmlst><Filters/><FiltersIN/><FiltersNOTIN> <iClosed>90,2</iClosed></FiltersNOTIN><FiltersOR/><Sorts><sProjectOrder>ASC</sProjectOrder></Sorts></xmlst>",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          // Nous avons récupéré les data, il faut maintenant faire le lien avec les autres stores
          commit('setRecords', response.Item);

          dispatch("setRelationship");
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  setRelationship({ state, commit, rootGetters }, from) {
    state.records?.forEach(data => {
      const enrichData = (primaryKey, storePath, searchFn) => {
        // Si la data existe
        if (data[primaryKey]) {
          // Recherche dans son store
          const richData = rootGetters[storePath].find(
            searchFn ? searchFn :
            el => el[primaryKey] == data[primaryKey]
          );

          // Si elle existe, il faut enrichir la data
          data[primaryKey] = richData ? richData : data[primaryKey];
        }
      }
      
      if (from == null) {
        enrichData("kIDBrand", "brands/getRecords");
        enrichData("kIDContractor", "thirdParties/getRecords", el => el.kIDAnybody == data.kIDContractor);
        enrichData("kIDInvoicedClient", "thirdParties/getRecords", el => el.kIDAnybody == data.kIDInvoicedClient);
        enrichData("kIDItem", "articles/getRecords");
        enrichData("kIDModel", "model/getRecords");
      }
      else {
        switch (from) {
          case "kIDBrand":
            enrichData("kIDBrand", "brands/getRecords");
            break;
          case "kIDContractor":
            enrichData("kIDContractor", "thirdParties/getRecords", el => el.kIDAnybody == data.kIDContractor);
            break;
          case "kIDInvoicedClient":
            enrichData("kIDInvoicedClient", "thirdParties/getRecords", el => el.kIDAnybody == data.kIDInvoicedClient);
            break;
          case "kIDItem":
            enrichData("kIDItem", "articles/getRecords");
            break;
          case "kIDModel":
            enrichData("kIDModel", "model/getRecords");
            break;
        
          default:
            break;
        }
      }
    });
    
    commit('sort');
  },
}

// mutations
const mutations = {
  setRecords(state, records) {
    state.records = Array.isArray(records) ? records : typeof records == 'object' ? [records] : [];
  },
  sort(state) {
    state.records?.sort((a, b) => {
      if (parseInt(a.sProjectOrder) < parseInt(b.sProjectOrder)) {
        return -1;
      }
      else if (parseInt(a.sProjectOrder) > parseInt(b.sProjectOrder)) {
        return 1;
      }
      else if (parseInt(a.sProjectOrder) == parseInt(b.sProjectOrder)) {
        if (parseInt(a.sProjectLine) < parseInt(b.sProjectLine)) {
          return -1;
        }
        else if (parseInt(a.sProjectLine) > parseInt(b.sProjectLine)) {
          return 1;
        }
        else {
          return 0;
        }
      }
      else {
        return 1;
      }
    })
  },

  reset(state) {
    state.records = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}