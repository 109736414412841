<template>
  <div class="h-100">
    <v-row class="h-100 ma-0">
      <v-col class="h-100" cols="3" xl="3" lg="3" md="3" sm="2" xs="2" style="  overflow:auto">
        <v-card class="d-flex flex-column h-100" >
          <div class="pa-4">
            <v-checkbox
              v-model="checkbox_prio"
              class="mt-0"
              hide-details
              label="Uniquement les prioritaires"
            ></v-checkbox>
            <v-checkbox
              v-model="checkbox_assemblyorder"
              class="mt-0"
              hide-details
              label="Ordre de montage associé"
            ></v-checkbox>
            <v-autocomplete
                v-model="selectedAnybodyRecords"
                class="mt-2"
                :items="getAnybodyRecords"
                prepend-inner-icon="mdi-account"
                item-value="kIDAnybody"
                item-text="sAnybodyTitle"
                label="Donneur d'ordre"
                clearable
                hide-details
                dense
                outlined
              ></v-autocomplete>
          </div>
          <v-divider></v-divider>
          <v-card-title class="ml-9 text-subtitle-2" style="align-items: end;">
            <v-icon class="mr-4">mdi-car-convertible</v-icon> Châssis reçus
          </v-card-title>
          <v-divider></v-divider>
          <v-data-iterator
            style="flex:20; max-height: 55vh; overflow: auto;"
            :items="getChassisRecords"
            item-key="kIDChassis"
            hide-default-footer
          >
            <template v-slot:default="{items}">
              <v-list subheader>
                <v-list-item-group v-model="selectedChassis">
                  <v-list-item
                    v-for="item in items"
                    :key="item.kIDChassis"
                    @click="onClickChassis(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2">{{ item.sChassisNumber }}</v-list-item-title>
                      <v-list-item-subtitle>{{ getChassisTitle(item) }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ typeof item.kIDContractor == 'object' ? item.kIDContractor.sAnybodyTitle : item.kIDContractor }}</v-list-item-subtitle>
                      <v-list-item-subtitle><b>Montage pour le {{ getInfoFromAO(item, "dProjectExpectedAssemblyDate") ? new Date(getInfoFromAO(item, "dProjectExpectedAssemblyDate")).toLocaleDateString() : ""}}</b></v-list-item-subtitle>
                      <v-list-item-subtitle>Référence centre {{ item.sChassisNumberOrder }}</v-list-item-subtitle>
                      <v-list-item-subtitle>Empattement : {{ item.sChassisWheelBase }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="(getInfoFromTipperAndLocker(item) != false)">{{ getInfoFromTipperAndLocker(item) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <div style="display: inline-grid">
                      <v-icon v-if="chassisAvarieMandatory(item)" color="red">mdi-car-wrench</v-icon>
                      <v-icon v-if="tipperAvarieMandatory(item)" color="red">mdi-dump-truck</v-icon>
                      <v-icon v-if="lockerAvarieMandatory(item)" color="red">mdi-toolbox</v-icon>
                      <v-icon v-if="item.iChassisPriority == '1'" color="red">mdi-exclamation-thick</v-icon>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
      <v-col v-if="selectedChassis != null" style="display: flex; flex-direction: column;" class="h-100" cols="9" xl="9" lg="9" md="9" sm="10" xs="10">
        <v-row v-if="selectedChassisAO" class="py-2">
          <v-col cols="4" xl="4" lg="4" md="4" sm="4" xs="4">
            <Field title="Châssis" :data="getChassisTitle(selectedChassisData)" />
            <Field title="N° Série" :data="selectedChassisData.sChassisNumber" />
            <Field title="Montage pour le" :data="new Date(selectedChassisAO.dProjectExpectedAssemblyDate).toLocaleDateString()" dataStyle="text-h6" />
          </v-col>
          <v-col cols="4" xl="4" lg="4" md="4" sm="4" xs="4">
            <Field title="Empattement" :data="selectedChassisData.sChassisWheelBase" />
            <Field title="PTAC" :data="selectedChassisAO.sProjectPtac" />
            <Field title="Couleur" :data="selectedChassisData.sChassisColor" />
            <Field title="Type de cabine" :data="selectedChassisData.sChassisCabin"/>
          </v-col>
          <v-col cols="4" xl="4" lg="4" md="4" sm="4" xs="4">
            <Field title="Commande" :data="`${selectedChassisAO.sProjectOrder}-${selectedChassisAO.sProjectLine}`" />
            <Field title="Référence interne centre" :data="selectedChassisAO.sProjectNumberOrder" />
            <div class="center-xy pt-3" style="display: inline-flex; width: inherit;">
              <v-icon v-if="chassisAvarieMandatory(selectedChassisData)" class="mx-4" large color="red" @click="onChassisStatusClick">mdi-car-wrench</v-icon>
              <v-icon v-if="tipperAvarieMandatory(selectedChassisData)" class="mx-4" large color="red" @click="onTipperStatusClick" >mdi-dump-truck</v-icon>
              <v-icon v-if="lockerAvarieMandatory(selectedChassisData)" class="mx-4" large color="red" @click="onLockerStatusClick">mdi-toolbox</v-icon>
            </div>
            <UpdateEventPopUp :selectedFolderData="{ sContractorTitle: 'Sur Châssis', sProjectOrderLine: selectedChassisData.sChassisNumber }" :selectedEventData="selectedEventData" :dialog="updateEventPopUp" @updateData="refreshKey++" @close="updateEventPopUp = false" />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-card-title>
                <v-icon class="mr-4" color="red">mdi-cancel</v-icon>Pas d'ordre de montage associé
            </v-card-title>
          </v-col>
        </v-row>
        <v-divider class="mb-4"></v-divider>
        <v-row style="flex: 80">
          <v-col style="display: flex; flex-direction: column;" class="py-1" cols="7" xl="7" lg="7" md="7" sm="7" xs="7" >
            <v-row v-if="selectedChassisAO && getSelectedTipper" style="flex:0">
              <v-col cols="12" class="pb-0">
                <v-card-title class="pt-0 pl-0 pb-1" @click="clickTipperIcon">
                  <v-icon class="mr-4 pl-2" :color="getTipperWFStatus == '20' ? 'green' : ''">mdi-dump-truck</v-icon>Benne
                </v-card-title>
                <v-card class="pa-4" :style="getSelectedTipper.replaced ? 'border: 1px solid var(--v-primary-base);' : ''">
                  <v-row>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Type : <b>{{ `${getSelectedTipper.sSupplyDumpType}` }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Manquants bloquants : <b>{{ `${getSelectedTipper.sSupplyMandatoryMissing ? getSelectedTipper.sSupplyMandatoryMissing : '-'}` }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Couleur : <b>{{ `${getSelectedTipper.sSupplyProductionColor}` }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Manquants non bloquants : <b>{{ `${getSelectedTipper.sSupplyUnMandatoryMissing ? getSelectedTipper.sSupplyUnMandatoryMissing : '-'}` }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Longueur : <b>{{ `${getReadabledFloat(getSelectedTipper.fSupplyDumpLength)}` }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Largeur : <b>{{ `${getReadabledFloat(getSelectedTipper.fSupplyDumpWidth)}` }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0">N° série : <b>{{ getSelectedTipper.sSupplySerialNumber }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0">N° pile : <b>{{ getSelectedTipper.sSupplyStackNumber }}</b></v-card-subtitle>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="center-x">
                      <v-row v-if="getTipperWFStatus == '10'">
                        <v-col cols="6">
                          <v-btn large block color="primary" @click="replaceTippersPopup = true"><v-icon class="pr-2">mdi-refresh</v-icon>Substituer</v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn large block color="success" @click="validateTipper" ><v-icon class="pr-2">mdi-check</v-icon>Confirmer</v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-else-if="getTipperWFStatus == '20'">
                        <v-col cols="12">
                          <v-btn large block color="info" @click="removeTipper" ><v-icon class="pr-2">mdi-cancel</v-icon>Libérer la benne</v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="6">
                          <v-btn large block color="primary" @click="replaceTippersPopup = true"><v-icon class="pr-2">mdi-refresh</v-icon>Substituer</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-card-title>
                    <v-icon class="mr-4" color="red">mdi-help</v-icon>Pas de benne associée
                </v-card-title>
                <v-card-text>
                  <v-col cols="6">
                    <v-btn large block color="primary" @click="replaceTippersPopup = true"><v-icon class="pr-2">mdi-refresh</v-icon>Substituer</v-btn>
                  </v-col>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row v-if="selectedChassisAO && getSelectedLocker" class="mt-6">
              <v-col cols="12" class="pt-0">
                <v-card-title class="pt-0 pl-2 pb-1" @click="clickLockerIcon">
                  <v-icon class="mr-4" :color="getLockerWFStatus == '20' ? 'green' : ''" >mdi-toolbox</v-icon> Coffre
                </v-card-title>
                <v-card class="pa-4" :style="getSelectedLocker.replaced ? 'border: 1px solid var(--v-primary-base);' : ''">
                  <v-row>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Dimensions : <b>{{ getSelectedLocker.sSupplyBootDimensions }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Manquants bloquants : <b>{{ getSelectedLocker.sSupplyMandatoryMissing ? getSelectedLocker.sSupplyMandatoryMissing : '-' }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Couleur : <b>{{ getSelectedLocker.sSupplyProductionColor }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0" >Manquants non bloquants : <b>{{ getSelectedLocker.sSupplyUnMandatoryMissing ? getSelectedLocker.sSupplyUnMandatoryMissing : '-' }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0">N° série : <b>{{ getSelectedLocker.sSupplySerialNumber }}</b></v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="pa-2 pb-0">
                      <v-card-subtitle class="pa-0">N° pile : <b>{{ getSelectedLocker.sSupplyStackNumber }}</b></v-card-subtitle>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="center-x">
                      <v-row v-if="getLockerWFStatus == '10'">
                        <v-col cols="6">
                          <v-btn large block color="primary" @click="replaceLockersPopup = true"><v-icon class="pr-2">mdi-refresh</v-icon>Substituer</v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn large block color="success" @click="validateLocker" ><v-icon class="pr-2">mdi-check</v-icon>Confirmer</v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-else-if="getLockerWFStatus == '20'">
                        <v-col cols="12">
                          <v-btn large block color="info" @click="removeLocker" ><v-icon class="pr-2">mdi-cancel</v-icon>Libérer le coffre</v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="6">
                          <v-btn large block color="primary" @click="replaceLockersPopup = true"><v-icon class="pr-2">mdi-refresh</v-icon>Substituer</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-card-title v-if="isLockerNeeded">
                    <v-icon class="mr-4" color="red">mdi-help</v-icon>Pas de coffre associé (coffre attendu)
                </v-card-title>
                <v-card-title v-else>
                    <v-icon class="mr-4" color="green">mdi-toolbox </v-icon>Aucun coffre associé à ce dossier
                </v-card-title>
                <v-card-text v-if="isLockerNeeded">
                  <v-col cols="6">
                    <v-btn large block color="primary" @click="replaceLockersPopup = true"><v-icon class="pr-2">mdi-refresh</v-icon>Substituer</v-btn>
                  </v-col>
                </v-card-text>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="5" xl="5" lg="5" md="5" sm="5" xs="5" class="pt-1" style="max-height: 67vh;">
            <div style="display: flex; flex-direction:column; flex-grow: 1; height:100%">
              <div style="flex-grow: 1; overflow: auto;">
                <v-card-title class="pt-0 pb-0">
                  <v-icon class="mr-4">mdi-chat-processing</v-icon>
                  Compléments
                  <v-icon class="ml-4 mr-2" :color="getComplementColor()" >{{ getComplementIcon() }}</v-icon>
                  <span :style="'color: ' + getComplementColor()" >{{ selectedComplements ? selectedComplements.length : '0' }} / {{ selectedChassisAO ? selectedChassisAO.sProjectOptionNumber : '?' }}</span>
                </v-card-title>
                <div v-if="selectedChassisAO && selectedComplements" class="pa-2">
                  <v-card v-for="complement in selectedComplements" :key="complement.kIDOption" class="mt-2">
                    <v-card-subtitle style="font-size: small; line-height: initial;" class="pb-0 pt-0"><b>{{ complement.sOptionQuestionCode }}</b></v-card-subtitle>
                    <v-card-text style="font-size: x-small; line-height: initial;" class="pb-0">{{ complement.sOptionQuestionLabel }} : {{ complement.sOptionAnswerLabel }}</v-card-text>
                  </v-card>
                </div>
              </div>
              <v-divider></v-divider>
              <div>
                <div class="center-x mb-2 mt-2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2 pa-2"
                        :style="'border-radius: 50%; border: 1px solid ' + (selectedChassisAO ? 'green' : 'red')"
                        :color="selectedChassisAO ? 'green' : 'red'"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-car-wrench
                      </v-icon>
                    </template>
                    <span>Ordre de montage</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2 pa-2"
                        :style="'border-radius: 50%; border: 1px solid ' + (getTipperWFStatus == 20 ? 'green' : 'red')"
                        :color="getTipperWFStatus == 20 ? 'green' : 'red'"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-dump-truck
                      </v-icon>
                    </template>
                    <span>Benne {{ getTipperWFStatus != 20 ? 'non' : '' }} confirmée</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2 pa-2"
                        :style="'border-radius: 50%; border: 1px solid ' + (getLockerWFStatus == 20 ? 'green' : isLockerNeeded ? 'red' : 'green')"
                        :color="getLockerWFStatus == 20 ? 'green' : isLockerNeeded ? 'red' : 'green'"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-toolbox
                      </v-icon>
                    </template>
                    <span v-if="isLockerNeeded" >Coffre {{ getLockerWFStatus != 20 ? 'non' : '' }} confirmé</span>
                    <span v-else >Aucun coffre associé</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2 pa-2"
                        :style="'border-radius: 50%; border: 1px solid ' + (selectedComplements && selectedChassisAO ? selectedComplements.length == parseInt(selectedChassisAO.sProjectOptionNumber) ? 'green' : 'red' : 'red')"
                        :color="(selectedComplements && selectedChassisAO ? selectedComplements.length == parseInt(selectedChassisAO.sProjectOptionNumber) ? 'green' : 'red' : 'red')"
                        dark
                      >
                        mdi-chat-processing
                      </v-icon>
                    </template>
                    <span>Compément(s) {{ (selectedComplements && selectedChassisAO ? selectedComplements.length == parseInt(selectedChassisAO.sProjectOptionNumber) ? '' : 'in': 'in') }}complet(s)</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2 pa-2"
                        :style="'border-radius: 50%; border: 1px solid ' + (avarieBlock ? 'red' : 'green')"
                        :color="avarieBlock ? 'red' : 'green'"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-paperclip
                      </v-icon>
                    </template>
                    <span>Avarie(s) bloquante(s)</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pa-2"
                        :style="'border-radius: 50%; border: 1px solid ' + (isManquantBloquant() ? 'red' : 'green')"
                        :color="isManquantBloquant() ? 'red' : 'green'"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert
                      </v-icon>
                    </template>
                    <span>Manquant(s) bloquant(s)</span>
                  </v-tooltip>
                </div>
                <v-btn :disabled="!canCreate" large block color="success" @click="createPopup = true" >Créer mon dossier de montage</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      
      <v-col v-else style="display: flex; flex-direction: column;" class="h-100 center-xy" cols="9" xl="9" lg="9" md="9" sm="10" xs="10">
        <img :src="chassis" style="max-width: 40vh;" />
        <div class="text-h4 mt-4">Veuillez choisir un châssis pour continuer</div>
      </v-col>
    </v-row>
    <CustomFieldPopup fullscreen :title="chassisPopupTitle" :show="chassisStatusPopup" @close="chassisStatusPopup = false">
      <template v-slot:content>
        <v-row class="ma-0">
          <v-col xl="4" lg="4" md="4" sm="12" xs="12" cols="4">
            <div class="mx-4 my-4" style="display: flex; flex-direction: column; height: 95%;">
              <p style="max-height: 50vh; overflow: auto;">{{ chassisPopUpDetail }}</p>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="fixAvarie">Résoudre l'avarie</v-btn>
            </div>
          </v-col>
          <v-col xl="1" lg="1" md="1" sm="12" xs="12" cols="1">
            <div>
              <LstImage style="height: 60px; width: 60px; border: 1px solid black" class="mb-2 center-x" v-for="i in selectedChassisPhoto" :key="i.keys" width="100%" height="100%" :imgKey="i.keys" @click="popUpSelectedImage = i.keys"></LstImage>
            </div>
          </v-col>
          <v-col style="height: 80vh;" class="center-x" xl="7" lg="7" md="7" sm="12" xs="12" cols="7">
            <PinchZoom backgroundColor="white">
              <LstImage width="100%" height="500px" minHeight="500px" :imgKey="popUpSelectedImage"></LstImage>
            </PinchZoom>
          </v-col>
        </v-row>
      </template>
    </CustomFieldPopup>
    <v-dialog v-model="replaceTippersPopup">
      <v-card>
        <v-toolbar
          dark
          color="red"
        >
          <v-btn
            icon
            dark
            @click="replaceSelectedTippers = null; replaceTippersPopup = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Substitution des bennes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :disabled="replaceSelectedTippers == null || replaceSelectedTippers.length <= 0"
              dark
              text
              @click="replaceTippersPopup = false;"
            >
              Confirmer la substitution
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Tippers :open="replaceTippersPopup" :hash="selectedChassisAO ? selectedChassisAO.sProjectTipperHash : null" @changed="(tippers) => replaceSelectedTippers = tippers" />
      </v-card>
    </v-dialog>
    <v-dialog v-model="replaceLockersPopup">
      <v-card>
        <v-toolbar
          dark
          color="red"
        >
          <v-btn
            icon
            dark
            @click="replaceSelectedLockers = null; replaceLockersPopup = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Substitution des coffres</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :disabled="replaceSelectedLockers == null || replaceSelectedLockers.length <= 0"
              dark
              text
              @click="replaceLockersPopup = false;"
            >
              Confirmer la substitution
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Lockers :open="replaceLockersPopup" :hash="selectedChassisAO ? selectedChassisAO.sProjectLockerHash : null" @changed="(lockers) => replaceSelectedLockers = lockers" />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="createPopup"
    >
      <v-card style="height: 80vh;">
        <v-toolbar
          dark
          color="red"
        >
          <v-btn
            icon
            dark
            @click="createPopup = null; createPopup = false; folderCreationDateISO8601 = new Date().toISOString(); folderAssemblyCenterInternalNumber = null; folderAssemblyCenterOtherData = null;"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Création du dossier de montage</v-toolbar-title>
        </v-toolbar>
        <div class="mx-2 mt-2 pt-2" style="display: flex;">
          <div class="px-4">
            <v-card-title class="pa-0" style="font-weight: unset;">Date de début du montage</v-card-title>
            <v-date-picker 
              v-model="folderCreationDateISO8601"
              color="red"
              dark
              first-day-of-week="1"
              :min="new Date().toISOString()"
            >
            </v-date-picker>
          </div>
          <div style="flex: 1;" class="pl-4 pr-8 align-self-center">
            <v-text-field
              v-model="folderAssemblyCenterInternalNumber"
              label="Numéro interne au centre de montage"
              outlined
            ></v-text-field>
            <v-textarea
              v-model="folderAssemblyCenterOtherData"
              label="Commentaire du centre de montage"
              outlined
              color="red"
            >
            </v-textarea>
            <div>
              <v-btn :disabled="!canCreate" large right color="success" @click="createAF" >Créer mon dossier de montage</v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAvarieToFix"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card class="d-flex flex-column" >
        <v-toolbar
          dark
          color="red"
          style="flex: 0;"
        >
          <v-btn
            icon
            dark
            @click="showAvarieToFix = false; avarieToFix = null;"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ chassisPopupTitle }}</v-toolbar-title>
        </v-toolbar>
        <div class="px-4 py-2">
          <v-container>
            <v-row class="mt-4">
              <v-col cols="6">
                <Field title="Numéro de série" :data="avarieToFix ? avarieToFix.sAvarieEntitySerialNumber : '-'" />
                <Field title="Entité" :data="avarieToFix ? avarieToFix.sAvarieEntityType : '-'" />
                <Field title="Marque" :data="avarieToFix ? avarieToFix.sBrandTitle : '-'" />
                <Field title="Modèle" :data="avarieToFix ? avarieToFix.sModelTitle : '-'" />
              </v-col>
              <v-col cols="6">
                <span>Commentaire :</span>
                <div>
                  {{ avarieToFix ? avarieToFix.sTopikComment : '' }}
                </div>
              </v-col>
              <v-col cols="12">
                <div>Commentaire de résolution :</div>
                <v-textarea v-model="avarieToFixCommentary" :disabled="avarieToFix && avarieToFix.kIDWFStatus.toString().endsWith('99')" solo hide-details height="100px"></v-textarea>
              </v-col>
              <v-col cols="6">
              <div>Photo de preuve de résolution :</div>
                <EventPhoto :update="avarieToFixUpdateImg" :disabled="avarieToFix && avarieToFix.kIDWFStatus.toString().endsWith('99')" :selectedEventData="avarieToFix" @onChange="(src) => avarieToFixSrc = src" />
              </v-col>
              <v-col cols="6">
                <v-btn v-if="avarieToFix && !avarieToFix.kIDWFStatus.toString().endsWith('99')" class="cmp_btn elevation-10" style="width: 100%; height: 100%; min-height: 100px;" color="success" @click="validateAvarieToFix">
                  <span style="font-size: large">Confirmer et signer</span>
                </v-btn>
                <div v-else class="h-100 center-y">
                  <div class="text-h5" style="font-family: 'Indie Flower' !important;"><i><b>Confirmée le {{ avarieToFix ? new Date(avarieToFix.dEventResolutionDate).toLocaleString() : '' }}</b></i></div>
                  <div class="text-h5" style="font-family: 'Indie Flower' !important;"><i><b>par {{ avarieToFix ? avarieToFix.sEventResolutionerTitle : '' }}</b></i></div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

import FloatMixin from '@/mixins/FloatMixin';

import Field from './components/Field.vue'

import PinchZoom from 'vue-pinch-zoom';
import CustomFieldPopup from '@/components/DataGrid/CustomFieldPopup.vue';
import LstImage from '@/components/LstImage.vue';

import Tippers from './components/Tippers.vue';
import Lockers from './components/Lockers.vue';

import chassis from '@/assets/images/chassis.jpg';

import UpdateEventPopUp from './components/UpdateEventPopUp.vue'

import EventPhoto from './components/EventPhoto.vue'

export default {
  name: 'AssemblyFolders',

  mixins: [FloatMixin],

  components: { Field, PinchZoom, CustomFieldPopup, LstImage, Tippers, Lockers, UpdateEventPopUp, EventPhoto },

  data: function() {
    return {
      search: null,
      checkbox_prio: true,
      checkbox_assemblyorder: false,
      selectedAnybodyRecords: null,
      selectedChassis: null,
      selectedChassisData: null,
      selectedChassisPhoto: [],
      selectedChassisAO: null,
      selectedTipper: null,
      selectedLocker: null,
      selectedComplements: null,
      chassisStatusPopup: false,
      popUpSelectedImage: null,
      replaceTippersPopup: false,
      replaceSelectedTippers: null,
      replaceLockersPopup: false,
      replaceSelectedLockers: null,
      createPopup: false,
      folderCreationDate: new Date(),
      folderAssemblyCenterInternalNumber: null,
      folderAssemblyCenterOtherData: null,
      chassis: chassis,
      refreshKey: 0,
      updateEventPopUp: false,
      selectedEventData: null,
      chassisPopUpDetail: null,
      chassisPopupTitle: null,
      chassisPopupType: null,
      counter: false,
      showAvarieToFix: false,
      avarieToFix: null,
      avarieToFixUpdateImg: 0,
      avarieToFixSrc: null,
      avarieToFixCommentary: null
    }
  },
  watch: {
    showAvarieToFix: function() {
      this.avarieToFixUpdateImg = new Date().getTime();
      this.avarieToFixCommentary = this.avarieToFix ? this.avarieToFix.sEventFixCommentary : null;
      this.avarieToFixSrc = this.avarieToFix ? this.avarieToFix.vEventFixPicturePath ? this.avarieToFix.vEventFixPicturePath : null : null;
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
      chassisRecords: 'chassis/getRecords',
      assemblyOrderRecords: 'assemblyOrders/getRecords',
      anybodyRecords: 'thirdParties/getRecords',
      tipperRecords: 'tipper/getRecords',
      lockerRecords: 'locker/getRecords',
      complementRecords: 'complement/getRecords',
      avariesRecords: 'avaries/getRecords'
    }),
    getAnybodyRecords() {
        // Il faut seulement les anybody qui sont dans les chassis reçus
        const buffer = this.anybodyRecords.filter(el => {
          return this.chassisRecords.find(chassis => chassis.kIDContractor && chassis.kIDContractor.kIDAnybody == el.kIDAnybody) ? true : false
        })
        
        return buffer.sort((a, b) => a.sAnybodyTitle.localeCompare(b.sAnybodyTitle));
    },
    getChassisRecords() {
      let result = this.chassisRecords.filter(el => el.kIDWFStatus < 1150420)
      
      // Gestion de la recherche texte first
      if (this.search) {
        result = result.filter(el => el.search.toUpperCase().includes(this.search.toUpperCase()));
      }

      // Gestion du uniquement prio
      if (this.checkbox_prio) {
        result = result.filter(el => {
          return el.iChassisPriority == '1';
        })
      }

      // Gestion du uniquement avec ordre de montage
      if(this.checkbox_assemblyorder) {
        result = result.filter(el => {
          return this.getAO(el);
        });
      }

      // Gestion du donneur d'ordre
      if (this.selectedAnybodyRecords) {
        result = result.filter(el => el.kIDContractor && el.kIDContractor.kIDAnybody == this.selectedAnybodyRecords);
      }

      return result.sort((a, b) => a.sChassisNumber.localeCompare(b.sChassisNumber));
    },

    avarieBlock() {
      return (this.selectedChassisData ? this.selectedChassisData.kIDChassisStatus == 1 : false)
        || (this.selectedTipper ? this.selectedTipper.kIDSupplyStatus == 1 : false)
        || (this.selectedLocker ? this.selectedLocker.kIDSupplyStatus == 1 : this.isLockerNeeded ? true : false)
    },

    canCreate() {
      // Possible de créer seulement si bennes et coffres status 20 et compléments a N/N
      return this.selectedChassisAO 
        && this.getTipperWFStatus == 20
        && (this.isLockerNeeded ? this.getLockerWFStatus == 20 : true)
        && (this.selectedComplements && this.selectedChassisAO ? this.selectedComplements.length == parseInt(this.selectedChassisAO.sProjectOptionNumber): true)
        && (this.selectedChassisData ? this.selectedChassisData.kIDChassisStatus != 1 : false)
        && (this.selectedTipper ? this.selectedTipper.kIDSupplyStatus != 1 : false)
        && (this.selectedLocker ? this.selectedLocker.kIDSupplyStatus != 1 : this.isLockerNeeded ? false : true)
        && !this.isManquantBloquant()
    },

    folderCreationDateISO8601: {
      get() {
        return this.folderCreationDate.toISOString().substr(0, 10)
      },
      set(value) {
        this.folderCreationDate = new Date(value);
      }
    },

    getSelectedTipper() {
      if (this.replaceSelectedTippers) {
        if (this.replaceSelectedTippers.length > 0) {
          return {...this.replaceSelectedTippers[0], replaced: true };
        }
      }

      return this.selectedTipper;
    },

    getSelectedLocker() {
      if (this.replaceSelectedLockers) {
        if (this.replaceSelectedLockers.length > 0) {
          return {...this.replaceSelectedLockers[0], replaced: true };
        }
      }

      return this.selectedLocker;
    },

    isLockerNeeded() {
      return this.selectedChassisAO ? this.selectedChassisAO.sProjectLockerHash && this.selectedChassisAO.sProjectLockerHash != null & this.selectedChassisAO.sProjectLockerHash != '' : false;
    },

    getTipperWFStatus() {
      return this.getSelectedTipper ? this.getSelectedTipper.kIDWFStatus.substr(-2) : -1;
    },

    getLockerWFStatus() {
      return this.getSelectedLocker ? this.getSelectedLocker.kIDWFStatus.substr(-2) : -1;
    },
    
    chassisAvarieMandatory() {
      return (item) => {
        if (item.kIDChassisStatus == "1") {
          return true;
        }

        return false;
      }
    },

    // 0 = ok
    // 1 = bloquant
    // 2 = non bloquant
    // 3 = bloquant mais ok
    // 4 = ???
    tipperAvarieMandatory() {
      return (item) => {
        const tipper = this.getTipper(this.getAO(item));
        if (tipper && tipper.kIDSupplyStatus) {
          if (tipper.kIDSupplyStatus == "1") {
            return true;
          }
        }

        return false;
      }
    },


    lockerAvarieMandatory() {
      return (item) => {
        const locker = this.getLocker(this.getAO(item));
        if (locker && locker.kIDSupplyStatus) {
          if (locker.kIDSupplyStatus == "1") {
            return true;
          }
        }

        return false; 
      }
    }
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
  },

  methods: {
    clickTipperIcon: function() {
      this.counter = this.counter + 1;
      
      if (this.counter > 1) {
        setTimeout(() => {
          this.counter = 0;
          
          alert('Hash benne : ' + this.getSelectedTipper.sSupplyTipperHash)
        }, 500);

        return;
      }
      else {
        setTimeout(() => {
          this.counter = 0;
        }, 501);
      }
    },
    clickLockerIcon: function() {
      this.counter = this.counter + 1;
      
      if (this.counter > 1) {
        setTimeout(() => {
          this.counter = 0;
          
          alert('Hash coffre : ' + this.getSelectedLocker.sSupplyLockerHash)
        }, 500);

        return;
      }
      else {
        setTimeout(() => {
          this.counter = 0;
        }, 501);
      }
    },
    onChassisStatusClick: function() {
      this.chassisStatusPopup = true;
      
      this.chassisPopupTitle = "Avarie bloquante du châssis";
      this.chassisPopUpDetail = this.selectedChassisData ? this.selectedChassisData.sDamageComment : ''
      this.chassisPopupType = "CHASSIS"

      this.selectedChassisPhoto = [];
      this.popUpSelectedImage = null;

      if (this.selectedChassisData.vChassisPhoto1) {
        this.selectedChassisPhoto.push({
          keys: this.selectedChassisData.vChassisPhoto1
        })
      }

      if (this.selectedChassisData.vChassisPhoto2) {
        this.selectedChassisPhoto.push({
          keys: this.selectedChassisData.vChassisPhoto2
        })
      }

      if (this.selectedChassisData.vChassisPhoto3) {
        this.selectedChassisPhoto.push({
          keys: this.selectedChassisData.vChassisPhoto3
        })
      }

      if (this.selectedChassisData.vChassisPhoto4) {
        this.selectedChassisPhoto.push({
          keys: this.selectedChassisData.vChassisPhoto4
        })
      }

      if (this.selectedChassisPhoto[0]) {
        this.popUpSelectedImage = this.selectedChassisPhoto[0].keys;
      }
    },

    onLockerStatusClick: function() {
      this.chassisStatusPopup = true;
      
      this.chassisPopupTitle = "Avarie bloquante du coffre";
      this.chassisPopUpDetail = this.getSelectedLocker ? this.getSelectedLocker.sDamageComment : ''
      this.chassisPopupType = "LOCKER"

      this.selectedChassisPhoto = [];
      this.popUpSelectedImage = null;

      if (this.getSelectedLocker.vSupplyPhoto1) {
        this.selectedChassisPhoto.push({
          keys: this.getSelectedLocker.vSupplyPhoto1
        })
      }

      if (this.getSelectedLocker.vSupplyPhoto2) {
        this.selectedChassisPhoto.push({
          keys: this.getSelectedLocker.vSupplyPhoto2
        })
      }

      if (this.getSelectedLocker.vSupplyPhoto3) {
        this.selectedChassisPhoto.push({
          keys: this.getSelectedLocker.vSupplyPhoto3
        })
      }

      if (this.getSelectedLocker.vSupplyPhoto4) {
        this.selectedChassisPhoto.push({
          keys: this.getSelectedLocker.vSupplyPhoto4
        })
      }

      if (this.selectedChassisPhoto[0]) {
        this.popUpSelectedImage = this.selectedChassisPhoto[0].keys;
      }
    },

    
    onTipperStatusClick: function() {
      this.chassisStatusPopup = true;

      this.chassisPopupTitle = "Avarie bloquante de la benne";
      this.chassisPopUpDetail = this.getSelectedLocker ? this.getSelectedLocker.sDamageComment : ''
      this.chassisPopupType = "TIPPER"
      
      this.selectedChassisPhoto = [];
      this.popUpSelectedImage = null;

      if (this.getSelectedTipper.vSupplyPhoto1) {
        this.selectedChassisPhoto.push({
          keys: this.getSelectedTipper.vSupplyPhoto1
        })
      }

      if (this.getSelectedTipper.vSupplyPhoto2) {
        this.selectedChassisPhoto.push({
          keys: this.getSelectedTipper.vSupplyPhoto2
        })
      }

      if (this.getSelectedTipper.vSupplyPhoto3) {
        this.selectedChassisPhoto.push({
          keys: this.getSelectedTipper.vSupplyPhoto3
        })
      }

      if (this.getSelectedTipper.vSupplyPhoto4) {
        this.selectedChassisPhoto.push({
          keys: this.getSelectedTipper.vSupplyPhoto4
        })
      }

      if (this.selectedChassisPhoto[0]) {
        this.popUpSelectedImage = this.selectedChassisPhoto[0].keys;
      }
    },

    getChassisTitle: function(chassis) {
      if (chassis) {
        return `${typeof chassis.kIDBrand == "object" ? chassis.kIDBrand.sBrandTitle : chassis.kIDBrand} ${typeof chassis.kIDModel == "object" ? chassis.kIDModel.sModelTitle : chassis.kIDModel}`;
      }

      return "";
    },

    getChassisAODate: function(chassis) {
      return new Date(chassis.dChassisReceptionDate).toLocaleDateString();
    },

    getExtraData: function(chassis) {
      this.selectedChassisAO = null;
      this.selectedChassisAO = this.getAO(chassis);

      if (this.selectedChassisAO) {
        this.selectedTipper = null;
        this.selectedTipper = this.getTipper(this.selectedChassisAO);

        this.selectedLocker = null;
        this.selectedLocker = this.getLocker(this.selectedChassisAO);

        this.selectedComplements = null;
        this.selectedComplements = this.getComplements(this.selectedChassisAO);
      }
    },

    onClickChassis: function(chassis) {
      this.selectedChassisData = chassis;

      this.replaceSelectedTippers = null;
      this.replaceSelectedLockers = null;

      this.getExtraData(this.selectedChassisData);
    },

    // Liaison entre chassis et AO : (chassis : sChassisNumber ) (ao : sProjectChassis )
    getAO: function(chassis) {
      return this.assemblyOrderRecords.find((el) => {
        if (typeof chassis.sChassisNumber != 'undefined' && el.sProjectChassis == chassis.sChassisNumber) {
          return true;
        }
        
        return false;
      });
    },

    getTipper: function(ao) {
      if (typeof ao == "undefined") {
        return null;
      }

      const tippers = this.tipperRecords.filter(el => el.sProjectOrder == ao.sProjectOrder && el.sProjectLine == ao.sProjectLine);

      if (tippers.length > 0) {
        // Maintenant il faut seulement le plus récent
        let tipper = null;

        tippers.forEach(el => {
          if (tipper) {
            // Si el dispose d'une date
            if (el.dLastSubstitutionDate) {
              // Si tipper dispose d'une date
              if (tipper.dLastSubstitutionDate) {
                // Si la date de tipper est plus grand, ne rien faire
                if (new Date(tipper.dLastSubstitutionDate).getTime() < new Date(el.dLastSubstitutionDate).getTime()) {
                  tipper = el;
                }
              }
              else {
                tipper = el;
              }
            }

          }
          else {
            tipper = el;
          }
        });

        return tipper;
      }

      return null;
    },

    getLocker: function(ao) {
      if (typeof ao == "undefined") {
        return null;
      }
      const lockers = this.lockerRecords.filter(el => el.sProjectOrder == ao.sProjectOrder && el.sProjectLine == ao.sProjectLine);

      if (lockers && lockers.length > 0) {
        // Maintenant il faut seulement le plus récent
        let locker = null;

        lockers.forEach(el => {
          if (locker) {
            // Si el dispose d'une date
            if (el.dLastSubstitutionDate) {
              // Si locker dispose d'une date
              if (locker.dLastSubstitutionDate) {
                // Si la date de locker est plus grand, ne rien faire
                if (new Date(locker.dLastSubstitutionDate).getTime() < new Date(el.dLastSubstitutionDate).getTime()) {
                  locker = el;
                }
              }
              else {
                locker = el;
              }
            }

          }
          else {
            locker = el;
          }
        });

        return locker;
      }

      return null;
    },

    getComplements: function(ao) {
      const record = this.complementRecords.filter(el => el.sOptionProjectOrder == ao.sProjectOrder && el.sOptionProjectOrderLine == ao.sProjectLine);
      return record.length > 0 ? record : null;
    },

    getComplementColor: function() {
      if (this.selectedChassisAO && this.selectedChassisAO.sProjectOptionNumber == 0) {
        return 'green';
      }
      else if (this.selectedComplements && this.selectedChassisAO) {
        return this.selectedComplements.length < this.selectedChassisAO.sProjectOptionNumber ? 'orange' : 'green';
      }

      return "red";
    },

    getComplementIcon: function() {
      if (this.selectedComplements && this.selectedChassisAO) {
        return this.selectedComplements.length < this.selectedChassisAO.sProjectOptionNumber ? 'mdi-timer-sand' : 'mdi-check';
      }

      return "";
    },

    isManquantNonBloquant: function() {
      if (this.selectedLocker) {
        if (this.selectedLocker.sSupplyUnMandatoryMissing && this.selectedLocker.sSupplyUnMandatoryMissing == "OUI") {
          return true;
        }
      }

      if (this.getSelectedTipper) {
        if (this.getSelectedTipper.sSupplyUnMandatoryMissing && this.getSelectedTipper.sSupplyUnMandatoryMissing == "OUI") {
          return true;
        }
      }

      return false;
    },

    isManquantBloquant: function() {
      if (this.selectedLocker) {
        if (this.selectedLocker.sSupplyMandatoryMissing && this.selectedLocker.sSupplyMandatoryMissing == "OUI") {
          return true;
        }
      }

      if (this.getSelectedTipper) {
        if (this.getSelectedTipper.sSupplyMandatoryMissing && this.getSelectedTipper.sSupplyMandatoryMissing == "OUI") {
          return true;
        }
      }

      return false;
    },

    onSearchValueChanged: function(value) {
      this.search = value;
    },

    selectFirst: function() {
      if (this.getChassisRecords.length > 0 && this.selectedChassis == null) {
        this.selectedChassis = 0;
        this.selectedChassisData = this.getChassisRecords[0];
        this.getExtraData(this.selectedChassisData);
      }
    },
    
    validateTipper: function() {
      const tipper = this.getSelectedTipper;
      const kIDSupply = tipper.kIDSupply; // Benne qui va être associé
      const sProjectOrder = this.selectedChassisAO.sProjectOrder; // Projet a mettre dans la benne
      const sProjectLine = this.selectedChassisAO.sProjectLine; // Projet a mettre dans la benne
      const sProjectPreviousOrder = tipper.sProjectOrder || -1 // Ancien projet de la benne 
      const sProjectPreviousLine = tipper.sProjectLine || -1 // Ancien projet de la benne 
      
      this.$store.commit('app/mask');
      
      this.$store.dispatch("tipper/setProject", {
        kIDUser: this.getUser.kIDUser, kIDSupply, sProjectOrder, sProjectLine, sProjectPreviousOrder, sProjectPreviousLine
      }).then(() => {
        this.$store.commit('app/setMessage', {
          title: "Réservation de la benne",
          message: "La benne est réservée."
        });

        this.replaceTippersPopup = false;
        
        this.getExtraData(this.selectedChassisData);
        this.$store.commit('app/unmask');
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.$store.commit('app/unmask');
      });
    },

    removeTipper: function() {
      const tipper = this.getSelectedTipper;
      const kIDSupply = tipper.kIDSupply;
      const sProjectOrder = tipper.sProjectPreviousOrder;
      const sProjectLine = tipper.sProjectPreviousLine;
      const sProjectPreviousOrder = tipper.sProjectOrder;
      const sProjectPreviousLine = tipper.sProjectLine;
      
      this.$store.commit('app/mask');
      
      this.$store.dispatch("tipper/removeProject", {
        kIDUser: this.getUser.kIDUser, kIDSupply, sProjectOrder, sProjectLine, sProjectPreviousOrder, sProjectPreviousLine
      }).then(() => {
        this.$store.commit('app/setMessage', {
          title: "Libération de la benne",
          message: "La benne est libérée."
        });

        this.replaceTippersPopup = false;
        
        this.getExtraData(this.selectedChassisData);
        this.$store.commit('app/unmask');
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.$store.commit('app/unmask');
      });
    },
    
    validateLocker: function() {
      const locker = this.getSelectedLocker;
      const kIDSupply = locker.kIDSupply; // Coffre qui va être associé
      const sProjectOrder = this.selectedChassisAO.sProjectOrder; // Projet a mettre dans la coffre
      const sProjectLine = this.selectedChassisAO.sProjectLine; // Projet a mettre dans la coffre
      const sProjectPreviousOrder = locker.sProjectOrder || -1 // Ancien projet de la coffre 
      const sProjectPreviousLine = locker.sProjectLine || -1 // Ancien projet de la coffre 
      
      this.$store.commit('app/mask');
      
      this.$store.dispatch("locker/setProject", {
        kIDUser: this.getUser.kIDUser, kIDSupply, sProjectOrder, sProjectLine, sProjectPreviousOrder, sProjectPreviousLine
      }).then(() => {
        this.$store.commit('app/setMessage', {
          title: "Réservation du coffre",
          message: "Le coffre est réservé."
        });

        this.replaceLockersPopup = false;
        
        this.getExtraData(this.selectedChassisData);
        this.$store.commit('app/unmask');
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.$store.commit('app/unmask');
      });
    },

    removeLocker: function() {
      const locker = this.getSelectedLocker;
      const kIDSupply = locker.kIDSupply;
      const sProjectOrder = locker.sProjectPreviousOrder;
      const sProjectLine = locker.sProjectPreviousLine;
      const sProjectPreviousOrder = locker.sProjectOrder;
      const sProjectPreviousLine = locker.sProjectLine;
      
      this.$store.commit('app/mask');
      
      this.$store.dispatch("locker/removeProject", {
        kIDUser: this.getUser.kIDUser, kIDSupply, sProjectOrder, sProjectLine, sProjectPreviousOrder, sProjectPreviousLine
      }).then(() => {
        this.$store.commit('app/setMessage', {
          title: "Libération du coffre",
          message: "Le coffre est libéré."
        });

        this.replaceLockersPopup = false;
        
        this.getExtraData(this.selectedChassisData);
        this.$store.commit('app/unmask');
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.$store.commit('app/unmask');
      });
    },

    createAF: function() {
      this.$store.commit('app/mask');
      
      this.$store.dispatch("assemblyFolders/create", {
        kIDUser: this.getUser.kIDUser, 
        kIDAssemblyCenter: this.selectedChassisAO.kIDAssemblyCenter, 
        sProjectOrder: this.selectedChassisAO.sProjectOrder, 
        sProjectLine: this.selectedChassisAO.sProjectLine, 
        kIDChassis: this.selectedChassisData.kIDChassis, 
        kIDTipper: this.selectedTipper ? this.selectedTipper.kIDSupply : null,
        kIDLocker: this.selectedLocker ? this.selectedLocker.kIDSupply : null,
        dFolderCreationDate: this.folderCreationDate,
        sFolderComment: this.folderAssemblyCenterOtherData,
        sFolderCustomerReference: this.folderAssemblyCenterInternalNumber
      }).then(() => {
        // update des châssis
        this.$store.dispatch('chassis/loadFromWS', { kIDUser: this.getUser.kIDUser }).finally(() => {
          this.$store.commit('app/setMessage', {
            title: "Dossier de montage créé",
            message: "Le dossier de montage est créé."
          });

          this.createPopup = false;

          this.selectedChassis = null;
          
          this.folderCreationDateISO8601 = new Date().toISOString(); 
          this.folderAssemblyCenterInternalNumber = null; 
          this.folderAssemblyCenterOtherData = null;

          this.$store.commit('app/unmask');
        })
      }).catch((error) => {
        this.$store.commit("app/setError");
        console.error(error);
        this.$store.commit('app/unmask');
      });
    },

    getInfoFromAO: function(chassis, item) {
      try {
        const ao = this.getAO(chassis);

        return ao[item];
      } catch (error) {
        return "";
      }   
    },

    getInfoFromTipperAndLocker: function(chassis) {
      try {
        const ao = this.getAO(chassis);

        const tipper = this.getTipper(ao);
        const locker = this.getLocker(ao);
        
        let returnString = "Pas de coffre ni de benne";

        if (tipper && locker) {
          returnString = "Coffre et Benne";
        }
        else if (tipper) {
          returnString = "Benne";
        }
        else if (locker) {
          returnString = "Coffre";
        }

        return returnString; 
      } catch (error) {
        return false;
      }     
    },

    fixAvarie: function() {
      if (this.chassisPopupType == "CHASSIS") {
        const avarie = this.avariesRecords.filter(el => el.sAvarieEntityType == this.chassisPopupType && el.sAvarieEntitySerialNumber == this.selectedChassisAO.sProjectChassis);
        
        if (avarie.length > 0) {
          this.avarieToFix = avarie[0];
          this.showAvarieToFix = true;
        }
      }
      else if (this.chassisPopupType == "TIPPER") {
        const avarie = this.avariesRecords.filter(el => el.sAvarieEntityType == "BENNE" && el.sAvarieEntitySerialNumber == this.selectedTipper.sProjectOrder + '-' + this.selectedTipper.sProjectLine);
        
        if (avarie.length > 0) {
          this.avarieToFix = avarie[0];
          this.showAvarieToFix = true;
        }
      }
      else if (this.chassisPopupType == "LOCKER") {
        const avarie = this.avariesRecords.filter(el => el.sAvarieEntityType == "COFFRE" && el.sAvarieEntitySerialNumber == this.selectedLocker.sProjectOrder + '-' + this.selectedLocker.sProjectLine);
        
        if (avarie.length > 0) {
          this.avarieToFix = avarie[0];
          this.showAvarieToFix = true;
        }
      }
    },

    validateAvarieToFix: function() {
      this.$store.commit('app/mask');

      this.$store.dispatch("avaries/evolutionFromAO", {
        kIDUser: this.getUser.kIDUser,
        kIDEvent: this.avarieToFix.kIDEvent,
        kIDEventID: this.avarieToFix.kIDEventID,
        sEventFixCommentary: this.avarieToFixCommentary,
        vEventFixPicturePath: this.avarieToFixSrc ? this.avarieToFixSrc.src : null
      }).then(() => {
        this.showAvarieToFix = false;
        this.$emit('updateData');
        this.$store.commit('app/setMessage', {
          title: "Demande réussite",
          message: "Avarie validée et signée"
        });

        this.$store.commit('app/unmask');
      }).catch((error) => {
        this.showAvarieToFix = false;
        this.$store.commit("app/setError");
        console.error(error);
        this.$store.commit('app/unmask');
      });
    }
  }
}
</script>
<style>
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.nth-child-2n) {
    background-color: transparent !important;
  }
</style>