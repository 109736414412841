import FolderMediaServices from '@/services/folderMediaServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }
}

// actions
const actions = {
  loadFromWS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDFolder, page, start, limit } = payload;

        commit('download/increaseCounter', null, { root: true });
        FolderMediaServices.getList(
          //kIDUser,
          kIDUser,
          // sParameter
          kIDFolder,
          //xmlFiltersSorts,
          "",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          commit('reset');
          
          commit('setRecords', response.Item);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDFolderMedia, kIDUser, kIDFolder, kIDAssemblyCenter, iFolderMediaType, vFolderMediaFilePath, iFolderMediaPosition, sFolderMediaCommentary } = payload;
        
        commit('download/increaseCounter', null, { root: true });
        FolderMediaServices.save(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDFolderMedia",
          //isBckff 
          0,
          //xmlData
          `<xmlst>`
          + `<T0115FolderMedia primaryKey="kIDFolderMedia" returnRecord="True">`
            + `<Item>`
              + `<kIDFolderMedia>${kIDFolderMedia ? kIDFolderMedia : '-1'}</kIDFolderMedia>`
              + `<kIDFolder>${kIDFolder}</kIDFolder>`
              + `<kIDCreator>${kIDUser}</kIDCreator>`
              + `<kIDAssemblyCenter>${kIDAssemblyCenter}</kIDAssemblyCenter>`
              + `<iFolderMediaType>${iFolderMediaType}</iFolderMediaType>`
              + `<iFolderMediaPosition>${iFolderMediaPosition}</iFolderMediaPosition>`
              + `<sFolderMediaCommentary>${sFolderMediaCommentary}</sFolderMediaCommentary>`
              + `<vFolderMediaFilePath>${vFolderMediaFilePath}</vFolderMediaFilePath>`
            + `</Item>`
          + `</T0115FolderMedia>`
        + `</xmlst>`
        ).then((response) => {
          if (response == false) {
            reject({ success: false });
          }
          else {
            commit('setRecord', response.T0115FolderMedia.Item);
            resolve({ success: true, response: response });
          }
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  setRecords(state, records) {
    const rs = Array.isArray(records) ? records.reverse() : typeof records == 'object' ? [records] : [];

    rs.forEach(record => {
      const exist = state.records.find(el => el.kIDFolderMedia == record.kIDFolderMedia);

      if (exist) {
        exist.kIDCreator = record.kIDCreator;
        exist.kIDFolderMedia = record.kIDFolderMedia;
        exist.vFolderMediaFilePath = record.vFolderMediaFilePath;
        exist.iFolderMediaType = record.iFolderMediaType;
        exist.iFolderMediaPosition = record.iFolderMediaPosition;
        exist.sFolderMediaCommentary = record.sFolderMediaCommentary;
        exist.dCreationDate = record.dCreationDate;
        exist.bEnabled = record.bEnabled;
      }
      else {
        state.records.push(record);
      }
    });
  },

  setRecord(state, record) {
    const exist = state.records.find(el => el.kIDFolderMedia == record.kIDFolderMedia);
    if (exist) {
      exist.kIDCreator = record.kIDCreator;
      exist.kIDFolderMedia = record.kIDFolderMedia;
      exist.vFolderMediaFilePath = record.vFolderMediaFilePath;
      exist.iFolderMediaType = record.iFolderMediaType;
      exist.iFolderMediaPosition = record.iFolderMediaPosition;
      exist.sFolderMediaCommentary = record.sFolderMediaCommentary;
      exist.dCreationDate = record.dCreationDate;
      exist.bEnabled = record.bEnabled;
    }
    else {
      state.records.push(record);
    }
  },

  reset(state) {
    state.records = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}