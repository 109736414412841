/**
 * Pattern qui va permettre de faire de la communication global en cmp
 * Par exemple, dans le cmp A je fais
 * EventBus.$emit('toto', {success: true});
 * 
 * Dans cmp B et cmp C lors du mounted il suffit de faire
 * EventBus.$on('toto', maFonction);
 * 
 * Et hop, B et C vont ecouter l'event 'toto' de partout
 * 
 * Il faut l'utiliser vraiment en dernier recourt, toujours priviligier la réactivité VueX ou le communication intercmp
 * Mais bon, des fois pas le choix, c'est quand même sympa !
 * 
 * P.S : C'est certaine fois mieux d'utiliser l'EventBus plutôt que de monter un store VueX simplement pour ça.
 * Mine de rien, les stores VueX ça pompe pas mal.
 */
import Vue from 'vue';

export const EventBus = new Vue();