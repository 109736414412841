<template>
  <v-app id="inspire">
    <Header v-if="isLogged" />
    <Footer v-if="isLogged" />

    <Uploader />
    <Downloader />
    
    <v-main class="grey lighten-3" :style="isLogged ? 'margin-top: 64px;' : ''">
      <Background v-if="$route.name == 'login'" />
      <vue-page-transition name="fade" class="h-100 center-y-parent">
        <router-view></router-view>
      </vue-page-transition>
    </v-main>

    <LoadingMask :overlay="getMask" :title="getMaskTitle" />
    <ErrorPopup :overlay="getError" />

    <notifications position="top center" group="app" />

    <Logout />
  </v-app>
</template>

<script>
import LoadingMask from '@/components/App/Mask.vue'
import ErrorPopup from '@/components/App/Error_Popup.vue'
import Header from '@/components/App/Header.vue'
import Footer from '@/components/App/Footer.vue'
import Background from '@/components/App/Background.vue'
import Uploader from "@/components/Uploader/Uploader.vue";
import Downloader from "@/components/Downloader/Downloader.vue";
import Logout from '@/components/App/Logout.vue'

// eslint-disable-next-line
import fa from '@/assets/fontawesome-5/css/all.css'

import { mapGetters } from 'vuex'

import { EventBus } from '@/event-bus';

export default {
  name: 'App',

  components: { LoadingMask, ErrorPopup, Header, Footer, Background, Logout, Uploader, Downloader },

  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
      getAccessToken: 'user/getAccessToken',
      getMask: 'app/getMask',
      getMaskTitle: 'app/getMaskTitle',
      getError: 'app/getError',
      getMenu: 'app/getMenu'
    }),
    isLogged() {
      return this.getAccessToken != null && this.getUser != null;
    }
  },
  data: function () {
    return {
      idbretry: 0
    };
  },
  methods: {
    loadStore: async function() {
      try {
        EventBus.$emit('lastRefreshChange')

        this.$store.commit('app/mask', "Configuration de l'application..."); 

        // Attend le chargement d'IDB puis lance le chargement des stores
        const load = async () => {
          if (this.idbretry > 100 && localStorage.getItem('idb_ready') != 'true') {
            localStorage.setItem('idb_ready', true);
            load();
          }
          else if (localStorage.getItem('idb_ready') == 'true') {
            this.$store.commit('app/mask', "Chargement des données..."); 
            // Données de context de l'utilisateur
            this.$store.dispatch('user/getUsersAssemblyCenter', { kIDUser: this.getUser.kIDUser });

            const promises = [];
            // Données secondaires
            promises.push(this.$store.dispatch('stats/loadFromWS', { kIDUser: this.getUser.kIDUser }));

            promises.push(this.$store.dispatch('articles/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            promises.push(this.$store.dispatch('brands/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            promises.push(this.$store.dispatch('model/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            promises.push(this.$store.dispatch('tipper/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            promises.push(this.$store.dispatch('locker/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            promises.push(this.$store.dispatch('complement/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            
            promises.push(this.$store.dispatch('avaries/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            promises.push(this.$store.dispatch('consignes/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            
            promises.push(this.$store.dispatch('thirdParties/loadFromWS', { kIDUser: this.getUser.kIDUser }));

            promises.push(this.$store.dispatch('layout/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            promises.push(this.$store.dispatch('layoutParent/loadFromWS', { kIDUser: this.getUser.kIDUser }));

            // Données primaires
            promises.push(this.$store.dispatch('assemblyOrders/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            promises.push(this.$store.dispatch('assemblyFolders/loadFromWS', { kIDUser: this.getUser.kIDUser }));
            promises.push(this.$store.dispatch('chassis/loadFromWS', { kIDUser: this.getUser.kIDUser }));

            promises.push(this.$store.dispatch('globalFormField/loadFromWS', { kIDUser: this.getUser.kIDUser }));

            promises.push(this.$store.dispatch('tabSettings/loadFromWS', { kIDUser: this.getUser.kIDUser }));

            Promise.allSettled(promises).
              then(() => { this.$store.commit('app/unmask');  });
          }
          else {
            this.idbretry = this.idbretry + 1;
            setTimeout(load, 100);
          }
        }

        load();
      } catch (error) {
        console.error(error);
        this.$store.commit('app/unmask');
      }
    }
  },

  mounted: async function() {
    EventBus.$on('refreshData', this.loadStore);

    // Si ce n'est pas la route login
    if (this.$route.name != "login") {
      // Si pas de data de connexion, logout
      if (this.getUser == null || this.getAccessToken == null) {
        this.$store.dispatch('user/logout');
        return;
      }
      else {
        // Chargement des stores
        this.loadStore();
      }
    }
  }
};
</script>
<style>
  @font-face {
    font-family: "Indie Flower";
    src: local("IndieFlower-Regular"),
    url(./assets/IndieFlower-Regular.ttf) format("truetype");
  }

  html {
    background-color: #eeeeee !important;
    overflow: hidden;
  }

  .center-x {    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-y {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .center-y-parent {
    position: relative;
  }

  .center-y-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .center-xy {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }
</style>
