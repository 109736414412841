// initial state
const state = () => ({
  counter: 0
});

// getters
const getters = {
  getDownloadCounter: (state) => {
    return state.counter;
  }
}

// actions
const actions = {

}

// mutations
const mutations = {
  increaseCounter(state) {
    state.counter++;
  },
  decreaseCounter(state) {
    state.counter--;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}