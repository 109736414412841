<template>
  <div class="h-100 mt-10">
    <v-row class="my-4 mx-4">
      <v-col cols="4">
        <StatsCard class="h-100" icon="weather-cloudy-clock" title="Date d'actualisation" :value="statsRecords && statsRecords[0] ? statsRecords[0].sLastCalculationTime : '-'"></StatsCard>
      </v-col>
      <v-col cols="4">
        <StatsCard valueStyle="font-size: xxx-large !important;" route="/assembly_folder/init" class="h-100" icon="truck-delivery-outline" title="Nombre de châssis réceptionnés non traités" :value="statsRecords && statsRecords[0] ? statsRecords[0].iAvailableChassis : '0'"></StatsCard>
      </v-col>
      <v-col cols="4">
        <StatsCard valueStyle="font-size: xxx-large !important;" route="/assembly_folder/init" class="h-100" icon="truck-alert-outline" title="Nombre de dossiers potentiels complets en attente de création" :value="statsRecords && statsRecords[0] ? statsRecords[0].iAwaitingChassis : '0'"></StatsCard>
      </v-col>
    </v-row>
    <v-row class="my-4 mx-4">
      <v-col cols="4">
        <StatsCard valueStyle="font-size: xxx-large !important;" route="/assembly_folder/edit" class="h-100" icon="folder-wrench-outline" title="Nombre de dossiers créés en attente de montage" :value="statsRecords && statsRecords[0] ? statsRecords[0].iAwaitingAssemblingFolders : '0'"></StatsCard>
      </v-col>
      <v-col cols="4">
        <StatsCard valueStyle="font-size: xxx-large !important;" route="/assembly_folder/edit" class="h-100" icon="folder-star-outline" title="Nombre de dossiers montés en attente de conformité" :value="statsRecords && statsRecords[0] ? statsRecords[0].iAwaitingComplianceFolders : '0'"></StatsCard>
      </v-col>
      <v-col cols="4">
        <StatsCard valueStyle="font-size: xxx-large !important;" route="/shipping" class="h-100" icon="folder-play-outline" title="Nombre de dossiers conformes en attente d'expédition" :value="statsRecords && statsRecords[0] ? statsRecords[0].iAwaitingShippingFolders : '0'"></StatsCard>
      </v-col>
    </v-row>
    <v-row class="my-4 mx-4">
      <v-col cols="5">
        <StatsCard valueStyle="font-size: xxx-large !important;" class="h-100" icon="sun-clock-outline" title="Temps moyen de traitement" subtitle="Temps moyen de traitement global de la réception des châssis à l'expédition pour les réceptions des derniers 100 jours" :value="statsRecords && statsRecords[0] && statsRecords[0].iGlobalAssemblingDelay ? statsRecords[0].iGlobalAssemblingDelay.toString() : '0'" suffixe="jours"></StatsCard>
      </v-col>
      <v-col cols="5">
        <StatsCard valueStyle="font-size: xxx-large !important;" class="h-100" icon="wrench-clock" title="Temps moyen de montage" subtitle="Temps moyen de l'émission de l'ordre de montage d'origine au montage physique de l'atelier pour les émissions des derniers 100 jours" :value="statsRecords && statsRecords[0] && statsRecords[0].iPhysicalAssemblingTreatmentDelay ? statsRecords[0].iPhysicalAssemblingTreatmentDelay.toString() : '0'" suffixe="jours"></StatsCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import StatsCard from './components/StatsCard'

export default {
  name: 'Home',

  components: { StatsCard },

  data: function() {
    return {
      
    }
  },
  
  computed: {
    ...mapGetters({
      statsRecords: 'stats/getRecords',
    })
  }
}
</script>
<style>
</style>