import ChassisServices from '@/services/chassisServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }
}

// actions
const actions = {
  loadFromWS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, page, start, limit } = payload;
        
        commit('download/increaseCounter', null, { root: true });
        ChassisServices.getList(
          //kIDUser,
          kIDUser,
          //xmlFiltersSorts,
          "",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          // Nous avons récupéré les data, il faut maintenant faire le lien avec les autres stores
          commit('setRecords', response.Item);
          dispatch("setRelationship");
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  setChassisLayout({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDChassis, kIDLayout } = payload;
        
        commit('download/increaseCounter', null, { root: true });
        ChassisServices.setLayout(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDChassis",
          //isBckff 
          0,
          //xmlData,
          `<xmlst><T0115Chassis><Item><kIDChassis>${kIDChassis}</kIDChassis><kIDLayout>${kIDLayout}</kIDLayout></Item></T0115Chassis></xmlst>`,
        ).then((response) => {
          // La data est upload, il faut faire la modification en local
          commit('setRecord', response.Item);
          dispatch("setRelationship");
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  setRelationship({ state, commit, rootGetters }, from) {          
    state.records?.forEach(data => {
      /**
       * Nous devons enrichir les data suivantes :
       * kIDBrand
       * kIDModel
       */
      const enrichData = (primaryKey, storePath, searchFn) => {
        // Si la data existe
        if (data[primaryKey]) {
          // Recherche dans son store
          const richData = rootGetters[storePath].find(
            searchFn ? searchFn :
            el => el[primaryKey] == data[primaryKey]
          );

          // Si elle existe, il faut enrichir la data
          data[primaryKey] = richData ? richData : data[primaryKey];
        }
      }
      
      
      if (from == null) {
        enrichData("kIDBrand", "brands/getRecords");
        enrichData("kIDModel", "model/getRecords");
        enrichData("kIDContractor", "thirdParties/getRecords", el => el.kIDAnybody == data.kIDContractor);
      }
      else {
        switch (from) {
          case "kIDBrand":
            enrichData("kIDBrand", "brands/getRecords");
            break;
          case "kIDModel":
            enrichData("kIDModel", "model/getRecords");
            break;
        
          default:
            break;
        }
      }
    });

    commit('sort');
  },
}

// mutations
const mutations = {
  setRecords(state, records) {
    state.records = Array.isArray(records) ? records : typeof records == 'object' ? [records] : [];
  },
  setRecord(state, record) {
    const exist = state.records.find(el => el.kIDChassis == record.kIDChassis);

    if (exist) {
      exist.sLayoutCode = record.sLayoutCode;
      exist.sLayoutTitle = record.sLayoutTitle;
      exist.kIDLayout = record.kIDLayout ? record.kIDLayout : '-1';

      if (record.kIDEventResolutioner) {
        exist.kIDEventResolutioner = record.kIDEventResolutioner;
      }

      if (record.dEventResolutionDate) {
        exist.dEventResolutionDate = record.dEventResolutionDate;
      }

      if (record.sEventResolutionerTitle) {
        exist.sEventResolutionerTitle = record.sEventResolutionerTitle;
      }

      exist.kIDWFStatus = record.kIDWFStatus;
      exist.kIDChassisStatus = record.kIDChassisStatus;
    }
  },
  sort(/*state*/) {/*
    state.records.sort((a, b) => {
      if (parseInt(a.sProjectOrder) < parseInt(b.sProjectOrder)) {
        return -1;
      }
      else if (parseInt(a.sProjectOrder) > parseInt(b.sProjectOrder)) {
        return 1;
      }
      else if (parseInt(a.sProjectOrder) == parseInt(b.sProjectOrder)) {
        if (parseInt(a.sProjectLine) < parseInt(b.sProjectLine)) {
          return -1;
        }
        else if (parseInt(a.sProjectLine) > parseInt(b.sProjectLine)) {
          return 1;
        }
        else {
          return 0;
        }
      }
      else {
        return 1;
      }
    })*/
  },

  reset(state) {
    state.records = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}