export class FieldDTO {
  constructor(data) {
    this.iFieldDisplayStatus = data.iFieldDisplayStatus
    this.kIDGlobalForm = data.kIDGlobalForm
    this.kIDGlobalFormField = data.kIDGlobalFormField
    this.iFieldPage = data.iFieldPage
    this.iFieldRank = data.iFieldRank
    
    if (data.sFieldName) {
      const convertName = document.createElement('span');
      convertName.innerHTML = data.sFieldName;
      this.sFieldName = convertName.textContent;
    }
    else {
      this.sFieldName = data.sFieldName;
    }

    if (data.sFieldQuestion) {

      const convertQuestion = document.createElement('span');
      convertQuestion.innerHTML = data.sFieldQuestion;
      this.sFieldQuestion = convertQuestion.textContent;
    }
    else {
      this.sFieldQuestion = data.sFieldQuestion;
    }

    this.sFieldType = data.sFieldType
    this.sFieldDefaultVariable = data.sFieldDefaultVariable
    this.sFieldDefaultValue = data.sFieldDefaultValue
    this.sFieldHelp = data.sFieldHelp

    if (data.sFieldPhase) {
      const convertPhase = document.createElement('span');
      convertPhase.innerHTML = data.sFieldPhase;
      this.sFieldPhase = convertPhase.textContent
    }
    else {
      this.sFieldPhase = data.sFieldPhase;
    }

    if (data.sFieldGroup) {
      const convertGroup = document.createElement('span');
      convertGroup.innerHTML = data.sFieldGroup;
      this.sFieldGroup = convertGroup.textContent
    }
    else {
      this.sFieldGroup = data.sFieldGroup;
    }

    this.fFieldMinValue = data.fFieldMinValue
    this.fFieldMaxValue = data.fFieldMaxValue

    if (this.sFieldType == 'C' || this.sFieldType == 'M') {
      this.responses = [];
      // transformation des réponses en un seul objet
      const responses = Object.keys(data).filter(el => el.startsWith('sFieldResponse'))
      responses.forEach(response => {
        this.responses.push({
          name: response,
          value: data[response]
        })
      });
    }
  }
}