<template>
  <v-overlay class="master-overlay" :value="overlay">
    <Truck :title="title"/>
  </v-overlay>
</template>

<script>
import Truck from './Truck.vue';

export default {
  name: "LoadingMask",
  components: { Truck },
  props: ["overlay", "title"]
};
</script>
<style>
  .v-overlay--active.master-overlay {
    z-index: 999 !important;
  }
</style>