import http from "@/http-common-json";

class ArticlesServices {
  getList(kIDUser, xmlFiltersSorts, page, start, limit) {
    const url = 'getJList';
    
    const formData = new URLSearchParams();

    formData.append('sName', `wft_T0115Item(${kIDUser}, '')`);
    formData.append('xmlFiltersSorts', xmlFiltersSorts);
    formData.append('page', page);
    formData.append('start', start);
    formData.append('limit', limit);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.Records) {
        return jsonData.Records;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new ArticlesServices();