/**
 * Service HTTP pour faire des requêtes automatiquement manipulé
 */
import axios from "axios";
import env from './config/env';
import X2JS  from 'x2js';

import { EventBus } from './event-bus'

const apiClient = axios.create({
  baseURL: env.config.api_url
});

/**
 * Branchée sur le onerror
 * Cela nous permet de facilement traiter les erreurs
 * @param {*} messages 
 * @returns 
 */
function parseError(messages) {
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject({ messages: messages });
    } else {
      return Promise.reject({ messages: [ messages ] });
    }
  } else {
    return Promise.reject({ messages: [ '---' ] });
  }
}

/**
 * Branchée sur le onsuccess
 * Cela nous permet de facilement pré-traiter le body pour n'avoir plus qu'un objet dans la réponse du service.
 * @param {*} response 
 * @returns 
 */
function parseBody(response) {
  if (response.status >= 200 && response.status <= 299) {
    // Les webservices de Labelsoft retourne du XML DANS DU XML, il faut donc parse le premier XML pour ensuite avoir le XML de data
    const x2js = new X2JS();
    const document = x2js.xml2js(response.data);
    const jsonData = x2js.xml2js(document.string.toString());
    
    response.jsonData = jsonData ? jsonData.xmlst ? jsonData.xmlst : jsonData : null;

    if (response.jsonData.Success == 'FALSE') {
      throw response.jsonData;
    }

    return response;
  } else {
    return this.parseError(response.data.messages);
  }
}

/**
 * Un intercepteur de requête qui permet d'integrer le token JWT (ou autre token si jamais) directement dans chaque requête
 * Il sera possible de faire plein d'autre chose avec, c'est juste pour montrer le principe d'intercepteur de req
 */
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.access_token;

    if (token) {
      config.headers.common['Authorization'] = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Un intercepteur de réponse cette fois-ci
 * Globalement, on va juste branché les fonctions de parseBody/Error
 */
apiClient.interceptors.response.use(
  (response) => {
    try {
      return parseBody(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    console.warn('Error status', error.response.status);
    // Si le status est 403, alors deco
    if (error.response.status == 403) {
      EventBus.$emit('forceLogout');
    }
    else if (error.response) {
      return parseError(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }
);

export default apiClient;