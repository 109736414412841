import ConsignesServices from '@/services/consignesServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }
}

// actions
const actions = {
  loadFromWS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, page, start, limit } = payload;

        commit('download/increaseCounter', null, { root: true });
        ConsignesServices.getList(
          //kIDUser,
          kIDUser,
          //xmlFiltersSorts,
          "",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          commit('setRecords', response.Item);
          
          // Il faut MAJ les stores qui utilise ce store comme data secondaire
          const list = [];

          list.forEach(store => {
            dispatch(store + "/setRelationship", "kIDBrand", {root:true});
          });

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },

  evolution({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDEvent, sEventFixCommentary, vEventFixPicturePath } = payload;

        commit('download/increaseCounter', null, { root: true });
        ConsignesServices.create(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDEvent",
          //isBckff 
          0,
          //kIDComponent
          "011530",
          //viewName
          `wft_T0115Event_Consignes(${kIDUser}, '')`,
          //xmlData
          `<xmlst>`
          + `<T0115Event>`
            + `<Item>`
              + `<kIDEvent>${kIDEvent}</kIDEvent>`
              + `<kIDWFStatus>01150750</kIDWFStatus>`
              + `<kIDWFStatusFrom>01150799</kIDWFStatusFrom>`
              + `<kIDComponent>011530</kIDComponent>`
              + (sEventFixCommentary == null ? `` : `<sEventFixCommentary>${sEventFixCommentary}</sEventFixCommentary>`)
              + (vEventFixPicturePath == null ? `` : `<vEventFixPicturePath>${vEventFixPicturePath}</vEventFixPicturePath>`)
            + `</Item>`
          + `</T0115Event>`
        + `</xmlst>`
        ).then((response) => {
          // La data est upload, il faut faire la modification en local
          commit('setRecord', response.Item);
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  setRecords(state, records) {
    state.records = Object.freeze(Array.isArray(records) ? records : typeof records == 'object' ? [records] : []);
  },

  setRecord(state, record) {
    const exist = state.records.find(el => el.kIDEvent == record.kIDEvent);

    if (exist) {
      exist.kIDWFStatus = record.kIDWFStatus;
      exist.kIDWFStatusFrom = record.kIDWFStatusFrom;
      exist.sEventFixCommentary = record.sEventFixCommentary;
      exist.vEventFixPicturePath = record.vEventFixPicturePath;
    }
  },

  reset(state) {
    state.records = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}