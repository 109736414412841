import GlobalFormContentServices from '@/services/globalFormContentServices.js';

// initial state
const state = () => ({
  records: []
});

// getters
const getters = {
  getRecords: (state) => {
    return state.records;
  }
}

const createItems = (kIDGlobalFormContent, items) => {
  let itemsString = '';
  let itemCount = -50;
  
  items.forEach(item => {
    const dFieldResponseDate = new Date();
    itemCount = itemCount-1;
    itemsString += `<Item>`
        + `<kIDGlobalFormFieldContent>${item.kIDGlobalFormFieldContent ? item.kIDGlobalFormFieldContent : itemCount}</kIDGlobalFormFieldContent>`
        + `<kIDGlobalFormContent context="TAKE_ID">${kIDGlobalFormContent}</kIDGlobalFormContent>`
        + `<kIDGlobalForm>${item.kIDGlobalForm}</kIDGlobalForm>`
        + `<kIDGlobalFormField>${item.kIDGlobalFormField}</kIDGlobalFormField>`
        + `<dFieldResponseDate>${dFieldResponseDate.getDate()}/${dFieldResponseDate.getMonth()+1}/${dFieldResponseDate.getYear()+1900} ${dFieldResponseDate.getHours()}:${dFieldResponseDate.getMinutes()}:${dFieldResponseDate.getSeconds()}</dFieldResponseDate>`
        + `<sFieldName>${item.sFieldName}</sFieldName>`
        + `<sFieldQuestion>${item.sFieldQuestion}</sFieldQuestion>`
        + `<sFieldType>${item.sFieldType}</sFieldType>`
        + (item.sFieldResponseContent ? `<sFieldResponseContent>${item.sFieldResponseContent}</sFieldResponseContent>` : `<sFieldResponseContent></sFieldResponseContent>`)
        + (item.sFieldCommentary ? `<sFieldCommentary>${item.sFieldCommentary}</sFieldCommentary>` : `<sFieldCommentary></sFieldCommentary>`)
        + `<vFieldPhoto1FilePath>${item.vFieldPhoto1FilePath}</vFieldPhoto1FilePath>`
        + `<vFieldPhoto2FilePath>${item.vFieldPhoto2FilePath}</vFieldPhoto2FilePath>`
        + `<vFieldSignatureFilePath>${item.vFieldSignatureFilePath}</vFieldSignatureFilePath>`
      + `</Item>`;
  });

  return itemsString;
}

// actions
const actions = {
  loadFromWS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDFolder, page, start, limit } = payload;

        commit('download/increaseCounter', null, { root: true });
        GlobalFormContentServices.getList(
          //kIDUser,
          kIDUser,
          // sParameter
          [1, kIDFolder],
          //xmlFiltersSorts,
          "",
          //page,
          page || 1,
          //start, 
          start || 0,
          //limit
          limit || 0
        ).then((response) => {
          commit('reset');
          commit('setRecords', response.Item);

          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  },
  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        const { kIDUser, kIDGlobalFormContent, kIDGlobalForm, kIDAssemblyCenter, kIDFolder, iPageStep, items } = payload;
        const dFormResponseDate = new Date();
        commit('download/increaseCounter', null, { root: true });
        GlobalFormContentServices.save(
          //kIDUser,
          kIDUser,
          //kIDApplication
          15,
          //kIDCompany 
          1,
          //primaryKey
          "kIDFolder",
          //isBckff 
          0,
          //xmlData
          `<xmlst>`
          + `<T0115GlobalFormContent primaryKey="kIDGlobalFormContent" returnRecord="True">`
            + `<Item>`
              + `<kIDGlobalFormContent>${kIDGlobalFormContent ? kIDGlobalFormContent : '-1'}</kIDGlobalFormContent>`
              + `<kIDGlobalForm>${kIDGlobalForm}</kIDGlobalForm>`
              + `<kIDUser>${kIDUser}</kIDUser>`
              + `<kIDAssemblyCenter>${kIDAssemblyCenter}</kIDAssemblyCenter>`
              + `<kIDFolder>${kIDFolder}</kIDFolder>`
              + `<iPageStep>${iPageStep}</iPageStep>`
              + `<dFormResponseDate>${dFormResponseDate.getDate()}/${dFormResponseDate.getMonth()+1}/${dFormResponseDate.getYear()+1900} ${dFormResponseDate.getHours()}:${dFormResponseDate.getMinutes()}:${dFormResponseDate.getSeconds()}</dFormResponseDate>`
            + `</Item>`
          + `</T0115GlobalFormContent>`
          + `<T0115GlobalFormFieldContent primaryKey="kIDGlobalFormFieldContent" returnRecord="True">`
            + createItems(kIDGlobalFormContent ? kIDGlobalFormContent : '-1', items)
          + `</T0115GlobalFormFieldContent>`
        + `</xmlst>`
        ).then((response) => {
          if (response == false) {
            reject({ success: false });
          }
          else {
            commit('setRecord', response.T0115GlobalFormContent.Item);
            resolve({ success: true, response: response });
          }
        })
        .catch((error) => {
          reject({ success: false, error: error });
        })
        .finally(() => {
          commit('download/decreaseCounter', null, { root: true });
        })
      } catch (error) {
        reject({ success: false, error: error });
      }
    });
  }
}

// mutations
const mutations = {
  setRecords(state, records) {
    const rs = Array.isArray(records) ? records : typeof records == 'object' ? [records] : [];

    rs.forEach(record => {
      const exist = state.records.find(el => el.kIDGlobalFormContent == record.kIDGlobalFormContent);

      if (exist) {
        exist.dFormResponseDate = record.dFormResponseDate;
        exist.iID = record.iID;
        exist.dTimeStamp = record.dTimeStamp;
        exist.iPageStep = record.iPageStep;
        exist.kIDGlobalFormContent = record.kIDGlobalFormContent;
        exist.kIDFolder = record.kIDFolder;
        exist.kIDAssemblyCenter = record.kIDAssemblyCenter;
      }
      else {
        state.records.push(record);
      }
    });
  },

  setRecord(state, record) {
    const exist = state.records.find(el => el.kIDGlobalFormContent == record.kIDGlobalFormContent);

    if (exist) {
      exist.dFormResponseDate = record.dFormResponseDate;
      exist.iID = record.iID;
      exist.dTimeStamp = record.dTimeStamp;
      exist.iPageStep = record.iPageStep;
      exist.kIDGlobalFormContent = record.kIDGlobalFormContent;
      exist.kIDFolder = record.kIDFolder;
      exist.kIDAssemblyCenter = record.kIDAssemblyCenter;
    }
    else {
      state.records.push(record);
    }
  },

  reset(state) {
    state.records = [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}