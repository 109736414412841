import { FieldDTO } from "./field";

export class PageDTO {
  constructor(data) {
    this.iFieldPage = data.iFieldPage || null;
    this.fields = []
  }

  addField(field) {
    if (field.iFieldDisplayStatus == 1 || field.iFieldDisplayStatus == "1" || field.iFieldDisplayStatus == 2 || field.iFieldDisplayStatus == "2") {
      if (this.fields.find(el => el.kIDGlobalForm == field.kIDGlobalForm && el.kIDGlobalFormField == field.kIDGlobalFormField)) {
        return;
      }

      this.fields.push(new FieldDTO(field));
    }
  }
}