/**
 * Pour chaque "endpoint API" il est pratique d'avoir un service
 * Ici c'est le service qui va gérer l'auth des utilisateurs
 * Pour l'instant c'est du code pour de la gestion de JWT avec token d'autorefresh, ce sera modifié lors de l'intégration avec votre WS
 */
import http from "@/http-common";
import httpJSON from "@/http-common-json";

class AuthService {
  login(email, password) {
    const url = 'getConnexion';
    
    const formData = new URLSearchParams();

    formData.append('sUserIdentifier', email);
    formData.append('sUserPassword', password);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.Records.Item) {
        return {
          access_token: jsonData.Records.Item.kIDUser + ":" + jsonData.Records.Item.sUserToken,
          user: jsonData.Records.Item
        }
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  getUsersAssemblyCenter(kIDUser) {
    const url = 'getJList';
    
    const formData = new URLSearchParams();

    formData.append('sName', `wf_T0115AssemblyCenterContext(${kIDUser}, '')`);
    formData.append('xmlFiltersSorts', "");
    formData.append('page', 1);
    formData.append('start', 0);
    formData.append('limit', 0);

    return httpJSON.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.Records) {
        return jsonData.Records;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new AuthService();