/**
 * Service HTTP pour faire des requêtes automatiquement manipulé
 */
import axios from "axios";
import env from './config/env';

const apiClient = axios.create({
  baseURL: env.config.api_url,
  headers: {
    "Content-Type": "application/json"
  }
});

/**
 * Branchée sur le onerror
 * Cela nous permet de facilement traiter les erreurs
 * @param {*} messages 
 * @returns 
 */
function parseError(messages) {
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject({ messages: messages });
    } else {
      return Promise.reject({ messages: [ messages ] });
    }
  } else {
    return Promise.reject({ messages: [ '---' ] });
  }
}

/**
 * Branchée sur le onsuccess
 * Cela nous permet de facilement pré-traiter le body pour n'avoir plus qu'un objet dans la réponse du service.
 * @param {*} response 
 * @returns 
 */
function parseBody(response) {
  if (response.status >= 200 && response.status <= 299) {
    return response;
  } else {
    return this.parseError(response.data.messages);
  }
}

/**
 * Un intercepteur de réponse cette fois-ci
 * Globalement, on va juste branché les fonctions de parseBody/Error
 */
apiClient.interceptors.response.use(
  (response) => {
    try {
      return parseBody(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    console.warn('Error status', error.response.status);
    if (error.response) {
      return parseError(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }
);

export default apiClient;