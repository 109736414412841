<template>
  <div>
    <v-data-table
      :height="getTableHeight"
      fixed-header
      :headers="headers"
      :items="getRecords"
      :item-class="getOddBackground"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :page.sync="page"
      item-key="kIDProject"
      no-data-text="Aucun ordre de montage disponible"
      show-expand
      class="elevation-1"
      disable-filtering
      disable-sort
      @click:row="onRowClicked"
      @page-count="onPageCountChange"
    >
      <template v-slot:item.sProjectOrder="{ item }">
        {{ item.sProjectOrder }} / {{ item.sProjectLine }}
      </template>
      <template v-slot:item.dProjectDate="{ item }">
        <CustomDate :date="item.dProjectDate" />
      </template>
      <template v-slot:item.dProjectPrevisionnalChassisArrivalDate="{ item }">
        <b><CustomDate :date="item.dProjectPrevisionnalChassisArrivalDate" /></b>
      </template>
      <template v-slot:item.dProjectExpectedAssemblyDate="{ item }">
        <b><CustomDate :date="item.dProjectExpectedAssemblyDate" /></b>
      </template>
      <template v-slot:item.kIDContractor="{ item }">
        <CustomChip color="primary"
          :text="typeof item.kIDContractor == 'object' ? item.kIDContractor.sAnybodyTitle : item.kIDContractor" 
        />
      </template>
      <template v-slot:item.kIDInvoicedClient="{ item }">
        {{ typeof item.kIDInvoicedClient == "object" ? item.kIDInvoicedClient.sAnybodyTitle : item.kIDInvoicedClient }}
      </template>
      <template v-slot:item.kIDBrand="{ item }">
        {{ typeof item.kIDBrand == "object" ? item.kIDBrand.sBrandTitle : item.kIDBrand }}
      </template>
      <template v-slot:item.kIDWFStatus="{ item }">
        <Fa :icon="item.sWFGlyphSymbol" :color="item.sWFGlyphColor" size="fa-2x" />
      </template>
      <template v-slot:item.sProjectCustomField1="{ item }">
        <v-btn v-if="haveCustomField(item)" color="cyan" class="white--text" fab x-small @click="onCustomFieldBtnClick(item)"><v-icon>mdi-information-variant</v-icon></v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          <div class="text-center">
            <Chip color="#1D2A31" title="Référence entête commande" :text="item.sProjectHeaderOrder" />
            <Chip color="#1D2A31" title="Numéro de commande client" :text="item.sProjectNumberOrder" />
            <Chip color="#1D2A31" title="Contremarque" :text="item.sProjectCounterMark" />
            <br/>
            <Chip color="#3f51b5" title="Suivi de prestation" :text="item.sProjectMonitoring" />
            <Chip color="#3f51b5" title="Etat de la ligne" :text="item.sProjectLineStatus" />
            <Chip color="#3f51b5" title="Type d'affaire" :text="item.sProjectType" />
            <Chip color="#3f51b5" title="Type de commande" :text="item.iProjectOrderType" />
          </div>
          <v-container class="mx-0" style="max-width: unset;">
            <v-row>
              <v-col xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                <CollapsedCardVue style="min-height: 160px;" :collapse="false">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Châssis
                        </div>

                        <CustomSubtitle 
                          title="Numéro de châssis" 
                          :data="item.sProjectChassis"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Date de récéption du châssis" 
                          :data="item.dProjectChassisDate ? new Date(item.dProjectChassisDate).toLocaleDateString() : null"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCardVue>
              </v-col>
              
              <v-col xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                <CollapsedCardVue style="min-height: 160px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Livraison
                        </div>
                      
                        <CustomSubtitle 
                          title="Client livré" 
                          :data="item.sProjectNameDelivery"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Date initiale" 
                          :data="item.sProjectInitialDelay"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle class="mt-2">Adresse</v-list-item-subtitle>
                        <p>{{ item.sProjectAddress1 }}</p>
                        <p>{{ item.sProjectAddress2 }}</p>
                        <p>{{ item.sProjectZipCode }} {{ item.sProjectCity }}</p>
                        <p>{{ item.sProjectCountry }}</p>
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Date confirmée de production" 
                          :data="item.sProjectConfirmedDelay"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Date de rendu" 
                          :data="item.dProjectDateRendered ? new Date(item.dProjectDateRendered).toLocaleDateString() : null"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCardVue>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                <CollapsedCardVue style="min-height: 160px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Données techniques de montage
                        </div>
                
                        <CustomSubtitle
                          title="Article" 
                          :data="typeof item.kIDItem == 'object' ? item.kIDItem.sItemTitle : item.kIDItem"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Quantité" 
                          :data="item.iProjectQty"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Modèle" 
                          :data="typeof item.kIDModel == 'object' ? item.kIDModel.sModelTitle : item.kIDModel"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur du châssis" 
                          :data="getReadabledFloat(item.fProjectChassisWidth)"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Numéro de châssis" 
                          :data="item.sProjectChassis"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Cabine" 
                          :data="item.sProjectCabin"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Tonnage" 
                          :data="item.sProjectTonnage"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type de roue" 
                          :data="item.sProjectWheelType"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type de benne" 
                          :data="item.sProjectDumpType"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Longueur de benne" 
                          :data="getReadabledFloat(item.fProjectDumpLength)"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur de benne" 
                          :data="getReadabledFloat(item.fProjectDumpWidth)"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Porte arrière" 
                          :data="item.sProjectBackDoor"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Hauteur de porte" 
                          :data="getReadabledFloat(item.fProjectDoorHeight)"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Hauteur de ridelle" 
                          :data="getReadabledFloat(item.fProjectWallHeight)"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Matière de ridelle" 
                          :data="item.sProjectWallMaterial"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Matière de porte" 
                          :data="item.sProjectDoorMaterial"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type de rallonge" 
                          :data="item.sProjectExtensionType"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Finition" 
                          :data="item.sProjectFinishing"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Couleur commercial" 
                          :data="item.sProjectCommercialColor"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Couleur production" 
                          :data="item.sProjectProductionColor"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur de berce" 
                          :data="getReadabledFloat(item.fProjectCradleWidth)"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Matière de coffre" 
                          :data="item.sProjectBootMaterial"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Dimension du coffre" 
                          :data="item.sProjectBootDimensions"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Attelage" 
                          :data="item.sProjectHitch"
                        />

                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Nombre d’options du dossier" 
                          :data="item.sProjectOptionNumber"
                        />

                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Empreinte de la benne" 
                          :data="item.sProjectTipperHash"
                        />

                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Empreinte du coffre" 
                          :data="item.sProjectLockerHash"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCardVue>
              </v-col>
              
              <v-col xl="3" lg="3" md="3" sm="3" xs="3" cols="3">
                <CollapsedCardVue style="min-height: 160px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Données supplémentaires
                        </div>

                        <CustomSubtitle cssClass="" title="Donnée n°1" :data="item.sProjectCustomField1" />
                        <CustomSubtitle cssClass="mt-2" title="Donnée n°2" :data="item.sProjectCustomField2" />

                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>
                        <CustomSubtitle cssClass="mt-2" title="Donnée n°3" :data="item.sProjectCustomField3" />
                        <CustomSubtitle cssClass="mt-2" title="Donnée n°4" :data="item.sProjectCustomField4" />
                        <CustomSubtitle cssClass="mt-2" title="Donnée n°5" :data="item.sProjectCustomField5" />
                        <CustomSubtitle cssClass="mt-2" title="Donnée n°6" :data="item.sProjectCustomField6" />
                        <CustomSubtitle cssClass="mt-2" title="Donnée n°7" :data="item.sProjectCustomField7" />
                        <CustomSubtitle cssClass="mt-2" title="Donnée n°8" :data="item.sProjectCustomField8" />
                        <CustomSubtitle cssClass="mt-2" title="Donnée n°9" :data="item.sProjectCustomField9" />
                        <CustomSubtitle cssClass="mt-2" title="Donnée n°10" :data="item.sProjectCustomField10" />                        
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCardVue>
              </v-col>
            </v-row>
          </v-container>
        </td>
    </template>
    </v-data-table>
    <CustomFieldPopin :show="customFieldPopin" title="Données supplémentaires" @close="closeCustomFieldPopin">
      <template v-slot:content>
        <v-list-item>
          <v-list-item-content>
            <CustomSubtitle title="Donnée n°1" :data="customFieldPopinData.sProjectCustomField1" />
            <CustomSubtitle cssClass="mt-2" title="Donnée n°2" :data="customFieldPopinData.sProjectCustomField2" />
            <CustomSubtitle cssClass="mt-2" title="Donnée n°3" :data="customFieldPopinData.sProjectCustomField3" />
            <CustomSubtitle cssClass="mt-2" title="Donnée n°4" :data="customFieldPopinData.sProjectCustomField4" />
            <CustomSubtitle cssClass="mt-2" title="Donnée n°5" :data="customFieldPopinData.sProjectCustomField5" />
            <CustomSubtitle cssClass="mt-2" title="Donnée n°6" :data="customFieldPopinData.sProjectCustomField6" />
            <CustomSubtitle cssClass="mt-2" title="Donnée n°7" :data="customFieldPopinData.sProjectCustomField7" />
            <CustomSubtitle cssClass="mt-2" title="Donnée n°8" :data="customFieldPopinData.sProjectCustomField8" />
            <CustomSubtitle cssClass="mt-2" title="Donnée n°9" :data="customFieldPopinData.sProjectCustomField9" />
            <CustomSubtitle cssClass="mt-2" title="Donnée n°10" :data="customFieldPopinData.sProjectCustomField10" />                        
          </v-list-item-content>
        </v-list-item>
      </template>
    </CustomFieldPopin>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

import CustomDate from "@/components/DataGrid/CustomDate";
import CustomChip from "@/components/DataGrid/CustomChip";
import Chip from "@/components/Chip";
import Fa from "@/components/Fa";
import CollapsedCardVue from '@/components/DataGrid/ExpandedItem/CollapsedCard';
import CustomSubtitle from '@/components/DataGrid/ExpandedItem/CustomSubtitle';
import CustomFieldPopin from '@/components/DataGrid/CustomFieldPopin';

import DataGridMixin from '@/components/DataGrid/DataGridMixin';

export default {
  name: 'AssemblyOrders',

  mixins: [DataGridMixin],

  components: { CustomDate, CustomChip, Chip, Fa, CollapsedCardVue, CustomSubtitle, CustomFieldPopin },

  data: function() {
    return {
      headers: [
        { text: 'N° commande/ligne', value: 'sProjectOrder', },
        { text: 'Commande le', value: 'dProjectDate' },
        { text: 'Arrivée prévue le', value: 'dProjectPrevisionnalChassisArrivalDate' },
        { text: 'Montage prévu le', value: 'dProjectExpectedAssemblyDate' },
        { text: "Donneur d'ordre", value: 'kIDContractor' },
        { text: "Client facturé", value: 'kIDInvoicedClient' },
        { text: 'Marque', value: 'kIDBrand' },
        { text: 'Statut', value: 'kIDWFStatus' },
        { text: '', value: 'sProjectCustomField1' }
      ],
      customFieldPopin: false,
      customFieldPopinData: {}
    }
  },
  
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      records: 'assemblyOrders/getRecords'
    })
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
    EventBus.$on('headerPaginationChanged', this.onHeaderValueChanged);
  },

  methods: {
    haveCustomField: function(item) {
      if (item.sProjectCustomField1) { return true; }
      if (item.sProjectCustomField2) { return true; }
      if (item.sProjectCustomField3) { return true; }
      if (item.sProjectCustomField4) { return true; }
      if (item.sProjectCustomField5) { return true; }
      if (item.sProjectCustomField6) { return true; }
      if (item.sProjectCustomField7) { return true; }
      if (item.sProjectCustomField8) { return true; }
      if (item.sProjectCustomField9) { return true; }
      if (item.sProjectCustomField10) { return true; }
      return false;      
    },
    getProjectMonitoringColor: function(status) {
      return status == "OUI" ? "green" : "red";
    },

    getOddBackground: function(item) {
      return this.getRecords.findIndex(el => el.kIDProject == item.kIDProject) % 2 ? "nth-child-2n" : "";
    },

    onCustomFieldBtnClick: function(item) {
      this.rowBtnClicked = true;
      
      this.customFieldPopin = true;
      this.customFieldPopinData = item;
    },

    closeCustomFieldPopin: function() {
      this.customFieldPopin = false;
      this.customFieldPopinData = {};
    }
  }
}
</script>
<style>
  .v-application .text-overline {
    line-height: 1rem !important;
  }

  .v-data-table-header {
    background-color: var(--v-red-base);
  }
  .v-data-table-header > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--v-red-base) !important;
  }
  .v-data-table-header > tr > th {
    color: white !important;
  }

  .v-data-table.v-data-table--fixed-header thead th {
    background-color: var(--v-red-base) !important;
  }

  /** background une ligne sur deux */
  .nth-child-2n {
    background-color: var(--v-secondary-lighten2) !important;
  }
  
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.nth-child-2n) {
    background-color: white !important;
  }  
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button.white--text {
    color: white !important;
  }
</style>