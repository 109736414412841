<template>
  <div>
    <v-data-table
      :height="getTableHeight"
      fixed-header
      :headers="headers"
      :items="getRecordsByStatus"
      :item-class="getOddBackground"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :page.sync="page"
      item-key="kIDSupply"
      no-data-text="Aucun coffre disponible"
      show-expand
      class="elevation-1"
      disable-filtering
      disable-sort
      @click:row="onRowClicked"
      @page-count="onPageCountChange"
    >
      <template v-slot:item.sProjectLine="{ item }">
        {{ item.sProjectOrder  }} / {{ item.sProjectLine }}
      </template>
      <template v-slot:item.sSupplySerialNumber="{ item }">
        <CustomChip color="#1D2A31"
          :text="item.sSupplySerialNumber" 
        />
      </template>
      <template v-slot:item.kIDWFStatus="{ item }" class="px-0">
        <Fa :icon="item.sWFGlyphSymbol" :color="item.sWFGlyphColor" size="fa-2x" />
      </template>
      <template v-slot:item.kIDSupplyStatus="{ item }">
        <div v-if="item.kIDSupplyStatus == 0"><v-btn fab icon @click="onkIDSupplyStatusBtnClick(item)"><v-icon large color="green">mdi-check-circle</v-icon></v-btn></div>
        <div v-else-if="item.kIDSupplyStatus == 2"><v-btn fab icon @click="onkIDSupplyStatusBtnClick(item)"><v-icon large color="orange">mdi-alert</v-icon></v-btn></div>
        <div v-else-if="item.kIDSupplyStatus == 1"><v-btn fab icon @click="onkIDSupplyStatusBtnClick(item)"><v-icon large color="red">mdi-alert-decagram</v-icon></v-btn></div>
      </template>
      <template v-slot:item.kIDLayout="{ item }">
        <div v-if="item.kIDLayout == null || item.kIDLayout == '-1'"><v-btn fab icon @click="onkIDLayoutBtnClick(item)"><v-icon large color="info">mdi-map-marker-question</v-icon></v-btn></div>
        <div v-else><v-btn fab icon @click="onkIDLayoutBtnClick(item)"><v-icon large color="green">mdi-map-marker-check</v-icon></v-btn></div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          <v-container class="mx-0" style="max-width: unset;">
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="6" xs="6" cols="6">
                <CollapsedCardVue style="min-height: 160px;" :collapse="false">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Coffre
                        </div>

                        <CustomSubtitle
                          title="Manquants bloquants" 
                          :data="item.sSupplyMandatoryMissing"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Manquants non bloquants" 
                          :data="item.sSupplyUnMandatoryMissing"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Finition" 
                          :data="item.sSupplyFinishing"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Couleur commerciale" 
                          :data="item.sSupplyCommercialColor"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCardVue>
              </v-col>
              
              <v-col xl="6" lg="6" md="6" sm="6" xs="6" cols="6">
                <CollapsedCardVue style="min-height: 160px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Châssis correspondant
                        </div>
                      
                        <CustomSubtitle 
                          title="Largeur Châssis" 
                          :data="getReadabledFloat(item.fSupplySupplyWidth)"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Cabine" 
                          :data="item.sSupplyCabin"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Empattement" 
                          :data="item.sSupplyWheelBase"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type de roue" 
                          :data="item.sSupplyWheelType"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Tonnage" 
                          :data="item.sSupplyTonnage"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCardVue>
              </v-col>
            </v-row>
          </v-container>
        </td>
    </template>
    </v-data-table>
    <CustomFieldPopup :title="getCustomFieldPopupTitle()" :show="customFieldPopup" @close="closeCustomFieldPopup">
      <template v-slot:content>
        
        <v-row class="ma-0">
          <v-col xl="3" lg="3" md="3" sm="12" xs="12" cols="3">
            <div class="mx-4 my-4">
              <p>{{ customFieldPopupData.description }}</p>
            </div>
          </v-col>
          <v-col xl="1" lg="1" md="1" sm="12" xs="12" cols="1">
            <div>
              <LstImage style="height: 60px; width: 60px; border: 1px solid black" class="mb-2 center-x" v-for="i in customFieldPopupData.photos" :key="i.keys" width="100%" height="100%" :imgKey="i.keys" @click="popUpSelectedImage = i.keys"></LstImage>
            </div>
          </v-col>
          <v-col style="height: 80vh;" class="center-x" xl="8" lg="8" md="8" sm="12" xs="12" cols="8">
            <PinchZoom backgroundColor="white">
              <LstImage width="100%" height="500px" minHeight="500px" :imgKey="popUpSelectedImage"></LstImage>
            </PinchZoom>
          </v-col>
        </v-row>
      </template>
    </CustomFieldPopup>
    <LayoutPopup :show="layoutPopupShowed" title="Choisir un emplacement" :item="selectedSupply" @close="closeLayoutPopup" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

import CustomChip from "@/components/DataGrid/CustomChip";
import CollapsedCardVue from '@/components/DataGrid/ExpandedItem/CollapsedCard';
import CustomSubtitle from '@/components/DataGrid/ExpandedItem/CustomSubtitle';
import CustomFieldPopup from '@/components/DataGrid/CustomFieldPopup.vue';
import LstImage from '@/components/LstImage.vue';
import PinchZoom from 'vue-pinch-zoom';
import Fa from "@/components/Fa";

import DataGridMixin from '@/components/DataGrid/DataGridMixin';

import LayoutPopup from './components/LayoutPopup.vue';

export default {
  name: 'LockersIn',

  mixins: [DataGridMixin],

  components: { CustomChip, CollapsedCardVue, CustomSubtitle, Fa, CustomFieldPopup, LstImage, PinchZoom, LayoutPopup  },

  data: function() {
    return {
      headers: [
        { text: 'N° commande/ligne', value: 'sProjectLine', },
        { text: 'N° de série', value: 'sSupplySerialNumber' },
        //{ text: "N° de pile", value: 'sSupplyStackNumber' },
        { text: "Matière Coffre", value: 'sSupplyBootMaterial' },
        { text: 'Dimensions', value: 'sSupplyBootDimensions' },
        { text: 'Couleur', value: 'sSupplyProductionColor' },
        //{ text: 'Sceau', value: 'sSupplyLockerHash' },
        { text: 'Statut', value: 'kIDWFStatus' },
        { text: 'Réception conforme', value: 'kIDSupplyStatus' },
        { text: 'Emplacement', value: 'kIDLayout' }
      ],
      customFieldPopup: false,
      customFieldPopupData: { photos: [] },
      popUpSelectedImage: null,
      selectedSupply: null,
      layoutPopupShowed: false,
    }
  },
  
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      records: 'locker/getRecords'
    }),
    
    getRecordsByStatus() {
      return this.getRecords.filter((item) => {
        return item.kIDWFStatus == "1150610"
      })
    }
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
    EventBus.$on('headerPaginationChanged', this.onHeaderValueChanged);
  },

  methods: {
    getOddBackground: function(item) {
      return this.getRecordsByStatus.findIndex(el => el.kIDSupply == item.kIDSupply) % 2 ? "nth-child-2n" : "";
    },

    getCustomFieldPopupTitle: function() {
      if (this.customFieldPopupData && this.customFieldPopupData.sSupplyNumber) {
        return 'Avarie(s) sur ' 
          + (typeof this.customFieldPopupData.kIDBrand == "object" ? this.customFieldPopupData.kIDBrand.sBrandTitle : this.customFieldPopupData.kIDBrand) 
          + " - " + (typeof this.customFieldPopupData.kIDModel == "object" ? this.customFieldPopupData.kIDModel.sModelTitle : this.customFieldPopupData.kIDModel)
          + " - " + this.customFieldPopupData.sSupplyNumber;
      }
      else {
        return "";
      }
    },
    
    onkIDSupplyStatusBtnClick: function(item) {
      this.rowBtnClicked = true;

      if(item.kIDSupplyStatus != 0) {
        this.customFieldPopup = true;

        const data = item;
        data.photos = [];

        if (item.vSupplyPhoto1) {
          data.photos.push({
            keys: item.vSupplyPhoto1
          })
        }

        if (item.vSupplyPhoto2) {
          data.photos.push({
            keys: item.vSupplyPhoto2
          })
        }

        if (item.vSupplyPhoto3) {
          data.photos.push({
            keys: item.vSupplyPhoto3
          })
        }

        if (item.vSupplyPhoto4) {
          data.photos.push({
            keys: item.vSupplyPhoto4
          })
        }

        data.description = item.sDamageComment;

        this.customFieldPopupData = data;

        this.popUpSelectedImage = this.customFieldPopupData?.photos[0]?.keys;
      }
    },

    closeCustomFieldPopup: function() {
      this.customFieldPopup = false;
      this.customFieldPopupData = { photos: [] };
      this.popUpSelectedImage = null;
    },

    onkIDLayoutBtnClick: function(item) {
      this.rowBtnClicked = true;
      this.selectedSupply = item;
      this.layoutPopupShowed = true;
    },

    closeLayoutPopup: function() {
      this.selectedSupply = { kIDSupply: null, kIDLayout: '-1' };
      this.layoutPopupShowed = false;
    }
  }
}
</script>
<style>
  .v-application .text-overline {
    line-height: 1rem !important;
  }

  .v-data-table-header {
    background-color: var(--v-red-base);
  }
  .v-data-table-header > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--v-red-base) !important;
  }
  .v-data-table-header > tr > th {
    color: white !important;
  }

  .v-data-table.v-data-table--fixed-header thead th {
    background-color: var(--v-red-base) !important;
  }

  /** background une ligne sur deux */
  .nth-child-2n {
    background-color: var(--v-secondary-lighten2) !important;
  }
  
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.nth-child-2n) {
    background-color: white !important;
  }  
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button.white--text {
    color: white !important;
  }
</style>