<template>
  <v-app-bar
      style="flex: none;"
      elevation="1"
      fixed
    >
      <v-btn text @click="clickLogo">
        <v-img
          class="mx-4 my-4"
          src="@/assets/logo.png"
          max-width="100"
        >
        </v-img>
      </v-btn>

      <v-toolbar-title>{{ currentRouteName }}</v-toolbar-title>

      <SearchableHeader class="ml-4" />
      <PaginationHeader class="ml-4" />
      <v-spacer></v-spacer>
      <v-icon
        v-if="onLine"
        large
        color="green darken-2"
      >
        mdi-rss
      </v-icon>
      <v-icon
        v-if="!onLine"
        large
        color="red darken-2"
      >
        mdi-rss-off
      </v-icon>
      <div class="mr-2 pl-4 d-inline-flex center-x">
        <v-chip
          class="ma-2"
          :class="alertPulse"
          close
          small
          color="white"
          :style="'background-color:' + alertColor + '!important'"
          outlined
          close-icon="mdi-cached"
          @click="refreshData"
        >
        {{ lastRefresh ? lastRefresh.toLocaleString() : '-'}}
        </v-chip>
      </div>
      <div class="px-4">
        <v-toolbar-title style="text-align: end;">{{ user.sUserFirstName }} {{ user.sUserLastName }}</v-toolbar-title>
        <v-toolbar-title class="subtitle-2" style="font-size: 12px !important; text-align: end;">{{ getUsersAssemblyCenter }}</v-toolbar-title>
      </div>

      <v-btn class="ml-2" color="primary" icon @click="$store.dispatch('user/logout')">
        <v-icon>mdi-power</v-icon>
      </v-btn>

      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="versionpopup"
      >
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >Informations sur l'application</v-toolbar>
          <v-card-text>
            <div class="text-h5 pa-12">Application : {{applicationVersion}}</div>
            <div class="text-h5 pa-12">Serveur : {{serverVersion}}</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="versionpopup = false"
            >Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

import SearchableHeader from "./SearchableHeader";
import PaginationHeader from "./PaginationHeader";

import { EventBus } from '@/event-bus';

import http from "@/http-common-simple";

export default {
  name: "Header",

  components: { SearchableHeader, PaginationHeader },

  
  computed: {
      ...mapGetters({
          user: 'user/getUser',
          getUsersAssemblyCenter: 'user/getUsersAssemblyCenter',
          getTabSettings: 'tabSettings/getRecords',
          statsRecords: 'stats/getRecords'
      }),

      alertColor() {
        console.log(this.tick);
        
        const firstBound = new Date(this.lastRefresh.getTime());
        firstBound.setHours(firstBound.getHours()+this.getTabSettings.sGeneralSettingConnectionAlertLevelMin);
        
        if (new Date() < firstBound) {
          return '#4caf50';
        }

        const scdBound = new Date(this.lastRefresh.getTime());
        scdBound.setHours(scdBound.getHours()+this.getTabSettings.sGeneralSettingConnectionAlertLevelMax);

        if (new Date() < scdBound) {
          return '#fb8c00';
        }

        return '#ee3a4b';
      },

      alertPulse() {
        console.log(this.tick);
        
        const firstBound = new Date(this.lastRefresh.getTime());
        firstBound.setHours(firstBound.getHours()+this.getTabSettings.sGeneralSettingConnectionAlertLevelMin);
        
        if (new Date() < firstBound) {
          return '';
        }

        const scdBound = new Date(this.lastRefresh.getTime());
        scdBound.setHours(scdBound.getHours()+this.getTabSettings.sGeneralSettingConnectionAlertLevelMax);

        if (new Date() < scdBound) {
          return '';
        }

        return 'pulse';
      },
      
      currentRouteName() {
          return this.$route.name;
      },

      applicationVersion() {
        return process.env.VERSION
      },
      lastRefresh() {
        const dateString = this.statsRecords && this.statsRecords[0] ? this.statsRecords[0].sLastCalculationTime : new Date().toString();
        
        return new Date(dateString.split(' ')[0].split('/')[2], dateString.split(' ')[0].split('/')[1]-1, dateString.split(' ')[0].split('/')[0], dateString.split(' ')[1].split(':')[0], dateString.split(' ')[1].split(':')[1], dateString.split(' ')[1].split(':')[2]);
      }
  },
  props: {

  },
  data: function () {
    return {
      versionpopup: false,
      serverVersion: null,
      onLine: navigator.onLine,
      counter: 0,
      tick: new Date().getTime()
    };
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true
        setTimeout(() => {
          this.showBackOnline = false
        }, 1000)
      }
    }
  },
  methods: {
    refreshData: function() {
      EventBus.$emit('refreshData');
    },
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'
    },
    clickLogo: function() {
      this.counter = this.counter + 1;
      
      if (this.counter > 1) {
        setTimeout(() => {
          this.counter = 0;
          
          this.versionpopup = true;
          http.post('getDBVersion')
            .then((response) => {
              this.serverVersion = response.jsonData;
              return true;
            })
          .catch((e) => {
            console.error(e);
            throw e;
          });
        }, 500);

        return;
      }
      else {
        setTimeout(() => {
          this.counter = 0;
        }, 501);
      }
    }
  },
  mounted: function() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)

    setInterval(() => {
      this.tick = new Date().getTime();
    }, 10000)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  }
};
</script>
<style>
  .pulse {
    background: #ee3a4b;
    box-shadow: 0 0 0 0 #ee3a4b;
    animation: pulse-red 2s infinite;
  }

  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #ee3a4b70;
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px #ee3a4b00;
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #ee3a4b00;
    }
  }
</style>