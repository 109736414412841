<template>
  <div>
    <v-data-table
      :height="getTableHeight"
      fixed-header
      :headers="headers"
      :items="getRecords"
      :item-class="getOddBackground"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :page.sync="page"
      item-key="kIDSupply"
      no-data-text="Aucune benne disponible"
      show-expand
      class="elevation-1"
      disable-filtering
      disable-sort
      @click:row="onRowClicked"
      @page-count="onPageCountChange"
      v-model="selected"
      show-select
      single-select
    >
      <template v-slot:item.sProjectLine="{ item }">
        {{ item.sProjectOrder  }} / {{ item.sProjectLine }}
      </template>
      <template v-slot:item.sSupplySerialNumber="{ item }">
        <CustomChip color="#1D2A31"
          :text="item.sSupplySerialNumber" 
        />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          <v-container class="mx-0" style="max-width: unset;">
            <v-row>
              <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                <CollapsedCardVue style="min-height: 160px;" :collapse="false">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Benne
                        </div>

                        <CustomSubtitle 
                          title="Longueur" 
                          :data="getReadabledFloat(item.fSupplyDumpLength)"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur" 
                          :data="getReadabledFloat(item.fSupplyDumpWidth)"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Manquants bloquants" 
                          :data="item.sSupplyMandatoryMissing"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Manquants non bloquants" 
                          :data="item.sSupplyUnMandatoryMissing"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur Faux Châssis" 
                          :data="getReadabledFloat(item.fSupplyFalseChassisWidth)"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Plateau Faux Châssis" 
                          :data="item.sSupplyPlateauFalseChassis"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Finition" 
                          :data="item.sSupplyFinishing"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Couleur commerciale" 
                          :data="item.sSupplyCommercialColor"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCardVue>
              </v-col>
              
              <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                <CollapsedCardVue style="min-height: 160px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Châssis correspondant
                        </div>
                      
                        <CustomSubtitle 
                          title="Largeur Châssis" 
                          :data="getReadabledFloat(item.fSupplyChassisWidth)"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Cabine" 
                          :data="item.sSupplyCabin"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Empattement" 
                          :data="item.sSupplyWheelBase"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type de roue" 
                          :data="item.sSupplyWheelType"
                        />
                        
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Tonnage" 
                          :data="item.sSupplyTonnage"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCardVue>
              </v-col>
              <v-col xl="4" lg="4" md="4" sm="4" xs="4" cols="4">
                <CollapsedCardVue style="min-height: 160px;">
                  <template v-slot:content>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="text-overline mb-2">
                          Porte, ridelle et attelage
                        </div>
                
                        <CustomSubtitle
                          title="Porte Arrière" 
                          :data="item.sSupplyBackDoor"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Hauteur Porte" 
                          :data="getReadabledFloat(item.fSupplyDoorHeight)"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:collapsed>
                    <v-list-item>
                      <v-list-item-content>                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Hauteur Ridelle" 
                          :data="getReadabledFloat(item.fSupplyWallHeight)"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Matière Ridelle" 
                          :data="item.sSupplyWallMaterial"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Matière Porte" 
                          :data="item.sSupplyDoorMaterial"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Type PE" 
                          :data="item.sSupplyPeType"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Matière PE" 
                          :data="item.sSupplyPeMaterial"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Largeur Berce" 
                          :data="getReadabledFloat(item.fSupplyCradleWidth)"
                        />
                
                        <CustomSubtitle 
                          cssClass="mt-2"
                          title="Attelage" 
                          :data="item.sSupplyHitch"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </CollapsedCardVue>
              </v-col>
            </v-row>
          </v-container>
        </td>
    </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'

import CustomChip from "@/components/DataGrid/CustomChip";
import CollapsedCardVue from '@/components/DataGrid/ExpandedItem/CollapsedCard';
import CustomSubtitle from '@/components/DataGrid/ExpandedItem/CustomSubtitle';

import DataGridMixin from '@/components/DataGrid/DataGridMixin';

export default {
  name: 'Tippers',

  mixins: [DataGridMixin],

  components: { CustomChip, CollapsedCardVue, CustomSubtitle },

  props: {
    hash: {
      default: null
    },
    open: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      headers: [
        { text: 'N° commande/ligne', value: 'sProjectLine', },
        { text: 'N° de série', value: 'sSupplySerialNumber' },
        { text: "N° de pile", value: 'sSupplyStackNumber' },
        { text: "Type de benne", value: 'sSupplyDumpType' },
        { text: 'Couleur', value: 'sSupplyProductionColor' },
        //{ text: 'Sceau', value: 'sSupplyTipperHash' }
      ],
      selected: []
    }
  },

  watch: {
    selected: function(newValue) {
      this.$emit("changed", newValue);
    }
  },
  
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      records: 'tipper/getRecords'
    }),
    getRecords() {
      return this.records.filter(el => {
        return el.sSupplyTipperHash == this.hash && el.kIDWFStatus == 1150510;
      })
    },
    getTableHeight() {
      return window ? (window.innerHeight - 56 - 64 - 45) + 'px' : 800 + 'px';
    },
  },
  
  mounted: function() {
    EventBus.$on('headerSearchChanged', this.onSearchValueChanged);
    EventBus.$on('headerPaginationChanged', this.onHeaderValueChanged);
  },

  methods: {
    getOddBackground: function(item) {
      return this.getRecords.findIndex(el => el.kIDSupply == item.kIDSupply) % 2 ? "nth-child-2n" : "";
    }
  }
}
</script>
<style>
  .v-application .text-overline {
    line-height: 1rem !important;
  }

  .v-data-table-header {
    background-color: var(--v-red-base);
  }
  .v-data-table-header > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--v-red-base) !important;
  }
  .v-data-table-header > tr > th {
    color: white !important;
  }

  .v-data-table.v-data-table--fixed-header thead th {
    background-color: var(--v-red-base) !important;
  }

  /** background une ligne sur deux */
  .nth-child-2n {
    background-color: var(--v-secondary-lighten2) !important;
  }
  
  tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.nth-child-2n) {
    background-color: white !important;
  }  
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button {
    color: inherit !important;
  }
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) td > button.white--text {
    color: white !important;
  }
</style>