import http from "@/http-common-json";

class GlobalFormContentServices {
  getList(kIDUser, sParameter, xmlFiltersSorts, page, start, limit) {
    const url = 'getJList';
    
    const formData = new URLSearchParams();

    formData.append('sName', `wft_T0115GlobalFormContent(${kIDUser}, ${sParameter[0]}, ${sParameter[1]}, '')`);
    formData.append('xmlFiltersSorts', xmlFiltersSorts);
    formData.append('page', page);
    formData.append('start', start);
    formData.append('limit', limit);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData && jsonData.Records) {
        return jsonData.Records;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }

  save(kIDUser, kIDApplication, kIDCompany, primaryKey, isBckff, xmlData) {
    const url = 'setJData';
    
    const formData = new URLSearchParams();

    formData.append('kIDUser', kIDUser);
    formData.append('kIDApplication', kIDApplication);
    formData.append('kIDCompany', kIDCompany);
    formData.append('primaryKey', primaryKey);
    formData.append('isBckff', isBckff);
    formData.append('xmlData', xmlData);

    return http.post(url, formData)
    .then((response) => {
      const { jsonData } = response;
      
      if(jsonData) {
        const data = {}; 
        
        if(jsonData.T0115GlobalFormContent) {
          data.T0115GlobalFormContent = jsonData.T0115GlobalFormContent
        }

        if (jsonData.T0115GlobalFormFieldContent) {
          data.T0115GlobalFormFieldContent = jsonData.T0115GlobalFormFieldContent
        }

        return data;
      }

      return false;
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });
  }
}

export default new GlobalFormContentServices();
